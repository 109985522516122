import { Avatar, Flex, Icon, Tag, Text } from "@chakra-ui/react";
import {
    EyeIcon,
    UserIcon,
    UserGroupIcon,
    SparklesIcon,
} from "@heroicons/react/24/outline";
import { NthParticipantDropdown } from "./NthParticipantDropdown";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

interface Props {
    headerType: HeaderType;
    resourceType: ResourceType;
    currentlyViewing?: any;
}

export type CurrentlyViewing = {
    displayName: string;
    avatarName: string;
    avatarUrl: string;
    order?: string;
    selectedParticipantIndex?: number;
    updateSelectedParticipant?: (participantId: string) => void;
};

export type HeaderType =
    | "solo"
    | "group"
    | "readonly solo"
    | "readonly group"
    | "readonly nth numbered"
    | "readonly nth dropdown"
    | "readonly nth fullscreen"
    | "readonly nth chatbot" // used for new chatbot
    | "product tour"
    | "ai chatbot" // currently used for DEMO-GenAI immersion, will be retired once new chatbot is ready
    | "chatbot"; // used for new chatbot

export type ResourceType =
    | "etherpad"
    | "gsheet"
    | "excalidraw"
    | "image"
    | "chatbot";

export const ResourceHeader = (props: Props) => {
    const { headerType, resourceType, currentlyViewing } = props;
    const isSelf = currentlyViewing?.displayName === "You";
    const { stepNumber } = useParams<{ stepNumber: string }>();

    const [showTagText, setShowTagText] = useState(true);
    const tagRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleResize = () => {
            const headerContainerElement = headerRef.current;
            if (!headerContainerElement) return;

            const headerWidth = headerContainerElement.clientWidth;
            setShowTagText(headerWidth > 280);
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [headerRef, stepNumber]);

    let bgColor;
    switch (headerType) {
        case "solo":
            bgColor = "info.-5";
            break;
        case "ai chatbot":
            bgColor = "purple.-5";
            break;
        case "chatbot":
        case "readonly nth chatbot":
            bgColor = "linear-gradient(92deg, #6D54E8 21.56%, #1A6CE5 78.44%)";
            break;
        default:
            bgColor = "gray.50";
            break;
    }
    let textColor;
    switch (headerType) {
        case "solo":
            textColor = "dark.800";
            break;
        case "ai chatbot":
            textColor = "purple.1";
            break;
        case "chatbot":
            textColor = "white";
            break;
        default:
            textColor = "dark.900";
            break;
    }
    const orderText = currentlyViewing?.order || null;

    let showViewOnlyTag = false;
    let headerText = "";
    let workspaceType = "";

    switch (resourceType) {
        case "etherpad":
            workspaceType = "workspace";
            break;
        case "gsheet":
            workspaceType = "spreadsheet";
            break;
        case "excalidraw":
            workspaceType = "whiteboard";
            break;
        case "chatbot":
            workspaceType = "AI chatbot";
            break;
    }
    switch (headerType) {
        case "chatbot":
        case "solo":
            headerText = `Solo ${workspaceType}`;
            showViewOnlyTag = false;
            break;
        case "group":
            headerText = `Group ${workspaceType}`;
            showViewOnlyTag = false;
            break;
        case "readonly solo":
            headerText = currentlyViewing?.displayName;
            showViewOnlyTag = true;
            break;
        case "readonly group":
            headerText = `Group ${workspaceType}`;
            showViewOnlyTag = true;
            break;
        case "readonly nth numbered":
            headerText = currentlyViewing?.displayName;
            showViewOnlyTag = false;
            break;
        case "readonly nth dropdown":
            headerText = currentlyViewing?.displayName;
            showViewOnlyTag = !isSelf;
            break;
        case "readonly nth fullscreen":
            headerText = currentlyViewing?.displayName;
            showViewOnlyTag = resourceType !== "excalidraw" && !isSelf;
            break;
        case "readonly nth chatbot":
            headerText = currentlyViewing?.displayName;
            showViewOnlyTag = true;
            break;
        case "product tour":
            headerText = "Workspace";
            showViewOnlyTag = false;
            break;
        case "ai chatbot":
            headerText = `Solo ${workspaceType}`;
            showViewOnlyTag = false;
            break;
    }
    let headerIcon = null;
    switch (headerType) {
        case "chatbot":
        case "ai chatbot":
            headerIcon = (
                <Icon
                    as={SparklesIcon}
                    h="20px"
                    w="20px"
                    strokeWidth={2}
                    color={textColor}
                />
            );
            break;
        case "group":
        case "readonly group":
            headerIcon = (
                <Icon
                    as={UserGroupIcon}
                    h="20px"
                    w="20px"
                    strokeWidth={2}
                    color={textColor}
                />
            );
            break;
        case "solo":
            headerIcon = (
                <Icon
                    as={UserIcon}
                    h="18px"
                    w="18px"
                    strokeWidth={2}
                    color={textColor}
                />
            );
            break;
        case "readonly solo":
        case "readonly nth numbered":
        case "readonly nth dropdown":
        case "readonly nth fullscreen":
        case "readonly nth chatbot":
            headerIcon = (
                <Avatar
                    src={currentlyViewing?.avatarUrl}
                    name={currentlyViewing?.avatarName}
                    size="xs"
                    showBorder={true}
                    borderRadius="25%"
                    borderColor="white"
                />
            );
            break;
        default:
            headerIcon = null;
            break;
    }

    let leftSide = null;
    if (headerType === "readonly nth dropdown") {
        leftSide = (
            <NthParticipantDropdown currentlyViewing={currentlyViewing} />
        );
    } else if (
        headerType === "readonly nth fullscreen" ||
        headerType === "readonly nth chatbot"
    ) {
        leftSide = (
            <Flex alignItems="center">
                <Text
                    fontSize="sm"
                    fontWeight="bold"
                    color={
                        headerType === "readonly nth chatbot"
                            ? "white"
                            : "dark.800"
                    }
                    mr="20px"
                >
                    Now Sharing:
                </Text>
                <NthParticipantDropdown currentlyViewing={currentlyViewing} />
                <Text
                    fontSize="sm"
                    color={
                        headerType === "readonly nth chatbot"
                            ? "rgba(255, 255, 255, 0.7)"
                            : "gray.400"
                    }
                    ml="12px"
                >
                    Use drop-down to switch presenters
                </Text>
            </Flex>
        );
    } else {
        leftSide = (
            <Flex alignItems="center" gap="12px">
                {headerIcon}
                <Text fontSize="sm" fontWeight="semibold" color={textColor}>
                    {headerText}
                </Text>
            </Flex>
        );
    }

    let rightSide = null;
    if (showViewOnlyTag) {
        const isChatbotStep = resourceType === "chatbot";
        rightSide = (
            <Tag
                ref={tagRef}
                h="28px"
                bg={isChatbotStep ? "transparent" : "gray.200"}
                gap="8px"
                p="0px 10px"
            >
                <Icon
                    as={EyeIcon}
                    h="14px"
                    w="14px"
                    strokeWidth={2}
                    color={isChatbotStep ? "white" : "gray.800"}
                />
                <Text
                    fontSize="xs"
                    fontWeight="semibold"
                    color={isChatbotStep ? "white" : "gray.800"}
                    display={showTagText ? "block" : "none"}
                >
                    View only
                </Text>
            </Tag>
        );
    } else if (orderText) {
        rightSide = (
            <Text fontSize="md" fontWeight="bold" color="gray.300">
                {orderText}
            </Text>
        );
    } else {
        rightSide = null;
    }

    return (
        <Flex
            ref={headerRef}
            h="48px"
            w="100%"
            padding="12px"
            {...(headerType === "chatbot" ||
            headerType === "readonly nth chatbot"
                ? { bg: bgColor }
                : { bgColor: bgColor })}
            justifyContent="space-between"
            alignItems="center"
        >
            {leftSide}
            {rightSide}
        </Flex>
    );
};
