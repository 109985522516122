import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { getAppVersion } from "../env";
import { useAccount } from "../hooks/useAccount";

export const AppVersion: React.FC = () => {
    const version = getAppVersion();
    const account = useAccount();
    const isSuperAdmin = account.data?.isSparkwiseAdmin;
    const [hidden, setHidden] = React.useState(false);
    if (!version || !isSuperAdmin || hidden) {
        return null;
    }
    return (
        <Flex
            onClick={() => setHidden(true)}
            cursor={"pointer"}
            p={2}
            role={"group"}
            position={"fixed"}
            left={5}
            bottom={5}
        >
            <Text fontSize={"xs"}>{version}</Text>
        </Flex>
    );
};
