import { Box, Text } from "@chakra-ui/react";

interface Props {
    videoUrl?: string;
    header?: string;
    description: string;
}

export const ProductTourTooltip = (props: Props) => {
    const { header, description, videoUrl } = props;

    return (
        <Box p="12px">
            {videoUrl && (
                <Box mb="8px" borderRadius="4px" overflow="hidden">
                    <video src={videoUrl} autoPlay loop />
                </Box>
            )}
            {header && (
                <Text
                    color="white"
                    fontFamily="Inter"
                    fontWeight={600}
                    lineHeight="22px"
                >
                    {header}
                </Text>
            )}
            <Text
                color="white"
                fontFamily="Inter"
                fontWeight={400}
                lineHeight="22px"
            >
                {description}
            </Text>
        </Box>
    );
};
