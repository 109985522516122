import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { meetingSession } from '../../immersionState';

export const AudioTrack = () => {
    const meeting = useRecoilValue(meetingSession);
    const audioVideo = meeting?.audioVideo;

    useEffect(() => {
        if (!audioVideo) return;

        const audioElement = document.createElement('audio');
        audioElement.autoplay = true;
        audioElement.id = `audio-totalMix`;

        const audioBaseElement = document.getElementById('chime-audio-elements');
        if (audioBaseElement) {
            audioBaseElement.querySelectorAll(`audio[id="audio-totalMix"]`).forEach((x) => { x.remove(); });

            console.log('AUDIO BASE ELEMENT')
            audioBaseElement.appendChild(audioElement);
        }

        audioVideo.bindAudioElement(audioElement);

        const checkAudioTracks = () => {
            if (audioElement.srcObject) {
                const mediaStream = audioElement.srcObject as MediaStream;
                const audioTracks = mediaStream.getAudioTracks();
                console.log(`Number of audio tracks: ${audioTracks.length}`);
                if (audioTracks.length > 0) {
                    console.log('Audio track details:', audioTracks[0]);
                } else {
                    console.log('No audio tracks found');
                }
            } else {
                console.log('No srcObject found on audio element');
            }
        };

        // Check for audio tracks after binding
        setTimeout(checkAudioTracks, 3000); // Delay to ensure the binding is complete


        return () => {
            audioVideo.unbindAudioElement();
            if (audioElement.parentNode) {
                audioElement.parentNode.removeChild(audioElement);
            }
        };
    }, [audioVideo]);

    return null;
};
