import { LocalTrackPublication, RemoteTrackPublication } from "twilio-video";
import useTrack from "../hooks/useTrack";
import { VideoTrack } from "./VideoTrack";
import { AudioTrack } from "./AudioTrack";

export const Publication = ({
    publication,
    local,
    setAudioOn,
}: {
    publication: LocalTrackPublication | RemoteTrackPublication;
    local: boolean;
    setAudioOn: (value: boolean) => void;
}) => {
    const track = useTrack(publication);

    if (track?.kind === "video") {
        return <VideoTrack track={track} local={local}></VideoTrack>;
    }
    if (track?.kind === "audio") {
        return <AudioTrack track={track} setAudioOn={setAudioOn}></AudioTrack>;
    }
    return null;
};
