import Error500 from "design/assets/Error500.svg";
import { Center, Image, Stack, Text } from "@chakra-ui/react";

interface Props {
    text?: string;
    advisement?: string;
}
export const Error = (props: Props) => {
    return (
        <Center
            w="100vw"
            h="100vh"
            position="fixed"
            top="0"
            left="0"
            bg="white"
        >
            <Stack w="550px">
                <Image h="249px" src={Error500} />
                <Text
                    fontFamily="Proxima Nova"
                    fontSize="40px"
                    fontWeight="semibold"
                    lineHeight="60px"
                    color="purple.0"
                    textAlign="center"
                >
                    Oops... Something went wrong
                </Text>
                <Text
                    fontFamily="Proxima Nova"
                    fontSize="24px"
                    lineHeight="36px"
                    color="dark.700"
                    textAlign="center"
                >
                    {props.advisement ||
                        "We are working on fixing the problem. Please try again later."}
                </Text>
                <Text
                    bg="gray.100"
                    borderRadius="10px"
                    maxHeight="100px"
                    overflow="auto"
                    fontFamily="Proxima Nova"
                    fontSize="14px"
                    lineHeight="36px"
                    color="dark.700"
                    textAlign="center"
                >
                    {props.text}
                </Text>
            </Stack>
        </Center>
    );
};
