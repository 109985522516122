import { createClient } from "@liveblocks/client";
import { createRoomContext } from "@liveblocks/react";
import { Presence } from "shared/LiveblocksTypes";
import { getCookie } from "./utils";


const client = createClient({
    authEndpoint: async (room) => {
        const data = await fetch(
            process.env.REACT_APP_API_ROOT! + "/app/liveblocks/" + room,
            {
                credentials: "include",
                headers: {
                    Authorization: `Bearer ${getCookie(document.cookie, "auth0Token")}`,
                },
            },
        );
        return await data.json();
    },
});

export const {
    RoomProvider,
    useOthers,
    useUpdateMyPresence,
    useSelf,
    useRoom,
} = createRoomContext<Presence>(client);
