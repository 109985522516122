import { Center, Image, Stack, Text } from "@chakra-ui/react";
import Stars from "design/assets/Stars.png";

export const RescheduleConfirmation = () => {
    return (
        <Center h="100vh" w="100vw">
            <Stack w="500px" alignItems="center">
                <Image h="120px" w="120px" src={Stars}></Image>
                <Text fontSize="60px" fontWeight="semibold" fontFamily="Griff">
                    Thank you!
                </Text>
                <Text
                    textAlign="center"
                    fontWeight="400"
                    fontSize="18px"
                    color="gray.500"
                >
                   Your request was submitted successfully! Our team will reach out to you via email. 
                </Text>
            </Stack>
        </Center>
    );
};
