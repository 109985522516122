import { Flex } from "@chakra-ui/react";
import { ResourceHeader, HeaderType, ResourceType } from "./ResourceHeader";

interface Props {
    url: string;
    name: string;
    headerType: HeaderType;
    resourceType: ResourceType;
    currentlyViewing?: any;
}

export const IFrameResource = (props: Props) => {
    const { url, name, headerType, resourceType, currentlyViewing } = props;
    const systemId = url?.split("room=")?.[1] ?? "";

    return (
        <Flex
            zIndex="1"
            flex="1"
            direction="column"
            borderWidth="1px"
            borderColor={headerType === "solo" ? "info.0" : "gray.100"}
            borderRadius="12px"
            overflow="hidden"
        >
            <ResourceHeader
                headerType={headerType}
                resourceType={resourceType}
                {...(currentlyViewing && { currentlyViewing })}
            />
            <Flex h="100%" mb={resourceType === "gsheet" ? "-39px" : "0px"}>
                <iframe
                    title={name?.length ? name : `${resourceType} work space`}
                    key={systemId}
                    src={url}
                    height="100%"
                    width="100%"
                />
            </Flex>
        </Flex>
    );
};
