import { VideoTileState } from "amazon-chime-sdk-js";
import React, { useState, useEffect, memo, useRef } from "react";
import { Box } from "@chakra-ui/react";
import { useRecoilValue } from 'recoil';
import { meetingSession } from '../../immersionState';

export const VideoTrack = memo(
    ({ publication, local }: { publication: VideoTileState; local: boolean }) => {
        const [videoOn, setVideoOn] = useState(true);
        const meeting = useRecoilValue(meetingSession);
        const audioVideo = meeting?.audioVideo;
        const ref = useRef<HTMLDivElement>(null);
        const tileId = publication.tileId;

        useEffect(() => {
            if (!audioVideo || !ref.current) {
              return;
            }
            const videoElement = document.createElement('video');
            videoElement.style.width = '100%';
            videoElement.style.height = '100%';
            videoElement.style.objectFit = 'cover';
            videoElement.autoplay = true;
            videoElement.playsInline = true;
            ref.current.appendChild(videoElement);
            meeting.audioVideo.bindVideoElement(publication.tileId || 0, videoElement);
            return () => {
              const tile = audioVideo.getVideoTile(tileId || 0);
              if (tile) {
                audioVideo.unbindVideoElement(tileId || 0);
              }
              ref.current?.removeChild(videoElement);
            };
          }, [audioVideo, tileId]);

        return (
            <Box
                // Chime sdk mirrors the local video without a way to configure or overwrite it on the videoElement,
                // so we need to flip it back here
                // https://github.com/aws/amazon-chime-sdk-js/blob/b4e0280f2183c0256c5a1d42a70b4b6c7fcf93e8/src/videotile/DefaultVideoTile.ts#L27
                transform={local ? "scaleX(-1) rotateY(180deg)" : ""}
                zIndex={5}
                position="absolute"
                visibility={videoOn ? undefined : "hidden"}
                className="publication"
                ref={ref}
                width="100%"
                height="100%"
                overflow="hidden"
            ></Box>
        );
    },
);
