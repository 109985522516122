import { Avatar, AvatarBadge, Image, Stack, Tooltip } from "@chakra-ui/react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOthers, useSelf, useUpdateMyPresence } from "../liveblocks.config";
import { useParticipants } from "./hooks/useImmersion";
import { useRecoilState, useRecoilValue } from "recoil";
import { meetingSession, videoRoom } from "./immersionState";
import UserFallback from "design/assets/UserFallback.png";
import { PARTICIPANT_BACKGROUNDS } from "../Constants";

export const Presence = () => {
    const participants = useParticipants();
    const stepNumber = parseInt(
        useParams<{ stepNumber: string }>().stepNumber || "0",
    );
    const updateMyPresence = useUpdateMyPresence();
    const others = useOthers();
    const selfObject = useSelf();
    const othersMap = Object.fromEntries(others.map((o) => [o.id, o]));
    if (selfObject) {
        othersMap[selfObject.id!] = selfObject;
    }
    const [room] = useRecoilState(videoRoom);
    const chimeSession = useRecoilValue(meetingSession);
    const connectedToTwilio = !!room?.localParticipant || !!chimeSession;

    useEffect(() => {
        updateMyPresence({ stepNumber, connectedToTwilio });
    }, [stepNumber, connectedToTwilio, updateMyPresence]);
    return (
        <Stack direction="row" spacing={2} alignItems="center">
            {participants
                .filter((p) => othersMap[p.user.emails[0].email])
                .map((participant) => {
                    return (
                        <Tooltip
                            hasArrow
                            placement="bottom-end"
                            label={`${participant.user.firstName} ${
                                participant.user.lastName
                            } is currently viewing step ${
                                othersMap[participant.user.emails[0].email]
                                    .presence.stepNumber
                            }`}
                            key={participant.user.id}
                        >
                            <Avatar
                                key={participant.user.id}
                                src={participant.user.avatarUrl || ""}
                                name={
                                    participant.user.firstName +
                                    " " +
                                    participant.user.lastName
                                }
                                size="xs"
                                borderColor="black"
                                borderWidth="1px"
                                bg={PARTICIPANT_BACKGROUNDS[participant.index]}
                                showBorder={true}
                            >
                                <AvatarBadge
                                    boxSize=".75em"
                                    bg="positive.1"
                                ></AvatarBadge>
                            </Avatar>
                        </Tooltip>
                    );
                })}
            {participants
                .filter((p) => !othersMap[p.user.emails[0].email])
                .map((participant) => {
                    return (
                        <Tooltip
                            hasArrow
                            placement="bottom-end"
                            label={`${participant.user.firstName} ${participant.user.lastName} is not currently viewing this immersion.`}
                            key={participant.id}
                        >
                            <Avatar
                                key={participant.user.id}
                                icon={<Image src={UserFallback} />}
                                bg="white"
                                size="xs"
                            ></Avatar>
                        </Tooltip>
                    );
                })}
        </Stack>
    );
};
