import { useRef } from "react";
import { useRecoilValue } from "recoil";
import {
    Avatar,
    AvatarGroup,
    Flex,
    Box,
    Image,
    Tooltip,
} from "@chakra-ui/react";
import Flag from "design/assets/flag.svg";
import { ReactComponent as Group } from "design/assets/Group.svg";
import { ReactComponent as Solo } from "design/assets/Solo.svg";
import {
    useSessionContent,
    useStepContent,
    useIsProductTour,
} from "../hooks/useSessionContent";
import {
    useGroupId,
    useIsSoloSession,
    useSelfData,
} from "../hooks/useImmersion";
import { useNavigate, useParams } from "react-router-dom";
import { useTimer } from "../hooks/useTimer";
import {
    PARTICIPANT_BACKGROUNDS,
    PRODUCT_TOUR_TOOLTIPS,
} from "../../Constants";
import ProgressTrackerVideo from "../../assets/ProgressTrackerVideo.mp4";
import { TimelineAvatars } from "./TimelineAvatars";
import { GatherPrompt } from "../smartPrompts/GatherPrompt";
import {
    productTourStep as productTourStepState,
    ProductTourStep,
} from "../immersionState";
import { ProductTourTooltip } from "../ProductTourTooltip";

interface Props {
    joinVideoModalOpen?: boolean;
    leaveVideoModalOpen?: boolean;
}

export const Timeline = (props: Props) => {
    const { id, stepNumber } = useParams();
    const navigate = useNavigate();
    const sessionContent = useSessionContent();
    const stepContent = useStepContent();
    const timer = useTimer();
    let visited = Number(localStorage.getItem(`Visited-${id}`));
    const groupId = useGroupId();
    const self = useSelfData();
    const gatherPromptAnchorRef = useRef<HTMLDivElement>(null);
    const isProductTour = useIsProductTour();
    const productTourStep = useRecoilValue(productTourStepState);
    const isSoloSession = useIsSoloSession();
    const { joinVideoModalOpen, leaveVideoModalOpen } = props;

    return (
        <Flex
            alignItems="center"
            justifyContent="space-between"
            flexGrow="1"
            gap="5px"
            paddingX="30px"
        >
            <GatherPrompt
                anchor={gatherPromptAnchorRef}
                starPlacement="bottom-left"
                header="Gather together!"
                text={[
                    "People are on different steps.",
                    "Get everyone onto the same step!",
                ]}
                timer={12}
                offsetTop={-132}
                offsetLeft={6}
            />
            {sessionContent?.map((step, index) => {
                // skip cover slide
                if (index === 0) return null;

                let color = "gray.50";
                let timerBox = null;
                let stepUrl = groupId
                    ? `/immersion/${id}/group/${groupId}/${index}`
                    : `/immersion/${id}/intro/${index}`;
                let onClick: undefined | (() => void) = () => navigate(stepUrl);

                const isStepCompleted = index < Number(stepNumber);
                const isStepActive = index === Number(stepNumber);
                const isStepVisited = index <= visited;
                // Set step color based on step status:
                // - Completed: Any step before the current step. Green background.
                // - Active: Current step. Gray background with dark gray progress bar.
                // - Visited: Any step seen, but ahead of the current step. Gray background.
                // - Unvisited & Incomplete: Any step not yet seen. Light gray background.
                if (isStepCompleted) {
                    color = "positive.0";
                } else if (isStepActive) {
                    color = "gray.200";

                    const timerPercent = Math.min(
                        (timer * 100) / (stepContent.duration || 1),
                        100,
                    );
                    timerBox = (
                        <Box
                            position="absolute"
                            bg="gray.800"
                            h="8px"
                            w={`${timerPercent}%`}
                        />
                    );
                } else if (isStepVisited) {
                    color = "gray.200";
                } else {
                    onClick = undefined;
                    color = "gray.50";
                }

                const isJoinVideoStep = sessionContent[index - 1]?.joinVideoCta;
                const isLeaveVideoStep =
                    sessionContent[index - 1]?.leaveVideoCta;

                // The milestone is considered completed as long as we are at/beyond the step,
                // and a modal selection has been made
                const joinVideoMilestoneCompleted =
                    (isStepActive || isStepCompleted) && !joinVideoModalOpen;
                const leaveVideoMilestoneCompleted =
                    (isStepActive || isStepCompleted) && !leaveVideoModalOpen;

                const joinIconColor = joinVideoMilestoneCompleted
                    ? "positive.3"
                    : "gray.200";
                const leaveIconColor = leaveVideoMilestoneCompleted
                    ? "positive.3"
                    : "gray.200";

                // Only show join/leave video icons for sessions with >1 participant
                const stepHasMilestoneMarker =
                    !isSoloSession && (isJoinVideoStep || isLeaveVideoStep);

                return (
                    <Flex
                        key={step.recordId}
                        flexGrow={step.duration || 60}
                        bg={"none"}
                        borderRadius="20px"
                        h="8px"
                        overflow="visible"
                        alignItems="center"
                        position="relative"
                        gap={stepHasMilestoneMarker ? "5px" : "0px"}
                    >
                        {stepHasMilestoneMarker && (
                            <Flex
                                key={`${step.recordId}-${isJoinVideoStep ? Group : Solo}-icon`}
                                flexGrow={60}
                                bg={"none"}
                                maxW="20px"
                                minW="14px"
                                overflow="visible"
                                alignItems="center"
                                justify={"center"}
                                position="relative"
                            >
                                <Flex
                                    position="absolute"
                                    w="20px"
                                    pb="20%"
                                    justify="center"
                                    color={
                                        isJoinVideoStep
                                            ? joinIconColor
                                            : leaveIconColor
                                    }
                                >
                                    {isJoinVideoStep ? <Group /> : <Solo />}
                                </Flex>
                            </Flex>
                        )}

                        <Flex
                            flexGrow={step.duration || 60}
                            bg={color}
                            borderRadius="20px"
                            h="8px"
                            overflow="visible"
                            alignItems="center"
                            position="relative"
                            _hover={{
                                cursor: onClick ? "pointer" : "",
                            }}
                            onClick={onClick}
                        >
                            <Tooltip
                                key={index}
                                hasArrow
                                placement="bottom"
                                label={`Step ${index}: ${step.menuTitle}`}
                            >
                                <Flex
                                    position="absolute"
                                    h="100%"
                                    w="100%"
                                    borderRadius="20px"
                                    overflow="hidden"
                                >
                                    {timerBox}
                                </Flex>
                            </Tooltip>
                            {groupId ? (
                                <TimelineAvatars
                                    index={index}
                                    gatherPromptAnchorRef={
                                        gatherPromptAnchorRef
                                    }
                                />
                            ) : (
                                <Box marginX="auto" position="relative">
                                    <Tooltip
                                        label={self?.firstName || "You"}
                                        hasArrow
                                        placement="top"
                                    >
                                        <AvatarGroup
                                            marginX="auto"
                                            size="2xs"
                                            spacing="-8px"
                                            bottom="14px"
                                            position="absolute"
                                            transform="translate(-50%, 0)"
                                            // add an arrow to the bottom of your avatar
                                            {...(index ===
                                                Number(stepNumber) && {
                                                _after: {
                                                    content: '""',
                                                    position: "absolute",
                                                    bottom: "3px",
                                                    left: "50%",
                                                    width: "8px",
                                                    height: "8px",
                                                    transform:
                                                        "translate(-50%, 50%) rotate(45deg)",
                                                    borderRadius: "1px",
                                                    backgroundColor: "gray.800",
                                                    zIndex: -1,
                                                },
                                            })}
                                        >
                                            {index === Number(stepNumber) && (
                                                <Tooltip
                                                    width="300px"
                                                    borderRadius="6px"
                                                    bg="gray.800"
                                                    label={
                                                        <ProductTourTooltip
                                                            videoUrl={
                                                                ProgressTrackerVideo
                                                            }
                                                            header="Progress tracker"
                                                            description={
                                                                PRODUCT_TOUR_TOOLTIPS[
                                                                    ProductTourStep
                                                                        .ProgressTracker
                                                                ]
                                                            }
                                                        />
                                                    }
                                                    hasArrow
                                                    placement="top-start"
                                                    offset={[-24, 24]}
                                                    isOpen={
                                                        isProductTour &&
                                                        productTourStep ===
                                                            ProductTourStep.ProgressTracker
                                                    }
                                                >
                                                    <Avatar
                                                        size="2xs"
                                                        width="20px"
                                                        height="20px"
                                                        key={
                                                            self?.sessionParticipantUuid
                                                        }
                                                        name={
                                                            self?.firstName +
                                                            " " +
                                                            self?.lastName
                                                        }
                                                        src={
                                                            self?.avatarUrl ||
                                                            ""
                                                        }
                                                        bg={
                                                            PARTICIPANT_BACKGROUNDS[0]
                                                        }
                                                        borderColor="gray.800"
                                                        borderWidth="2px"
                                                    />
                                                </Tooltip>
                                            )}
                                        </AvatarGroup>
                                    </Tooltip>
                                </Box>
                            )}
                        </Flex>
                    </Flex>
                );
            })}
            <Image w="20px" src={Flag} alt="End of module milestone icon" />
        </Flex>
    );
};
