import { Flex, Image, Text, keyframes } from "@chakra-ui/react";
import SmartPromptSparkle from "design/assets/SmartPromptSparkle.svg";
import { useEffect } from "react";

export type PromptProps = {
    anchor: React.RefObject<HTMLDivElement>;
    starPlacement: "top-left" | "top-right" | "bottom-left" | "bottom-right";
    header: string;
    text: string[];
    timer: number; // in seconds
    offsetTop?: number;
    offsetRight?: number;
    offsetBottom?: number;
    offsetLeft?: number;
};

type Props = PromptProps & {
    close: () => void;
};

const shrink = keyframes`
to {
    transform: scaleX(0);
}
`;

const fadeIn = keyframes`
to {
    opacity: 1;
}
`;

const slideUpAndFadeIn = keyframes`
from {
    transform: translateY(5px);
    opacity: 0;
}
to {
    transform: translateY(0px);
    opacity: 1;
}
`;

export const SmartPromptTemplate = (props: Props) => {
    const {
        anchor,
        close,
        starPlacement,
        header,
        text,
        timer,
        offsetTop,
        offsetRight,
        offsetBottom,
        offsetLeft,
    } = props;
    const anchorBounds = anchor.current?.getBoundingClientRect();

    const starStyle = {
        alignSelf: starPlacement.includes("top") ? "flex-start" : "flex-end",
        ...(starPlacement.includes("top") ? { mt: "-10px" } : { mb: "-10px" }),
        src: SmartPromptSparkle,
        width: "28px",
        height: "28px",
        animation: `${slideUpAndFadeIn} 1s ease-in-out forwards`,
    };

    useEffect(() => {
        setTimeout(() => {
            close();
        }, timer * 1000);
    }, []);

    return (
        <Flex
            zIndex={1000}
            position="absolute"
            w="fit-content"
            h="fit-content"
            minW="286px"
            top={(anchorBounds?.top || 0) + (offsetTop || 0)}
            right={(anchorBounds?.right || 0) + (offsetRight || 0)}
            bottom={(anchorBounds?.bottom || 0) + (offsetBottom || 0)}
            left={(anchorBounds?.left || 0) + (offsetLeft || 0)}
            gap="4px"
        >
            {starPlacement.includes("left") && <Image {...starStyle} />}
            <Flex
                direction="column"
                background="linear-gradient(92deg, rgba(126, 79, 222, 0.10) 3.6%, rgba(26, 108, 229, 0.10) 93.77%), rgba(255, 255, 255, 0.80)"
                borderWidth="2px"
                borderColor="white"
                borderRadius="8px"
                boxShadow="0px 16px 20px -12px rgba(126, 79, 222, 0.10)"
                backdropFilter="blur(4px)"
                overflow="hidden"
                opacity={0}
                animation={`${fadeIn} 0.5s ease-in-out forwards 1s`}
            >
                <Flex direction="column" p="12px 12px 0px 12px">
                    <Text
                        mb="8px"
                        color="purple.4"
                        fontFamily="Inter"
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="19px"
                    >
                        {header}
                    </Text>
                    <Flex mb="12px" direction="column">
                        {text.map((line, index) => (
                            <Text
                                key={index}
                                color="purple.4"
                                fontFamily="Inter"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="22px"
                            >
                                {line}
                            </Text>
                        ))}
                    </Flex>
                    <Text
                        as="button"
                        onClick={close}
                        mb="8px"
                        alignSelf="flex-end"
                        color="blue.500"
                        fontFamily="Inter"
                        fontSize="14px"
                        fontWeight="600"
                        lineHeight="17px"
                    >
                        Got it
                    </Text>
                </Flex>
                {timer && (
                    <Flex
                        h="4px"
                        w="100%"
                        background="linear-gradient(90deg, #9A87FA 4.99%, #4889EA 97.6%);"
                        transformOrigin="left"
                        animation={`${shrink} ${timer}s linear forwards`}
                    />
                )}
            </Flex>
            {starPlacement.includes("right") && <Image {...starStyle} />}
        </Flex>
    );
};
