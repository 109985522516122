import { useStepContent } from "../hooks/useSessionContent";
import { ImageResource } from "./resources/ImageResource";

export const FullScreenImage = () => {
    const { resources } = useStepContent();
    const resource1 = resources?.resource1?.[0];
    const imageUrl = resource1?.url ?? "";
    const imageAlt = resource1?.name ?? "";

    return (
        <ImageResource border url={imageUrl} altText={imageAlt} fullscreen />
    );
};
