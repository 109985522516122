import {
    Box,
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Portal,
    Text,
    ToastId,
    Tooltip,
    UseToastOptions,
    useToast,
} from "@chakra-ui/react";
import VideoButtonOn from "design/assets/VideoButtonOn.svg";
import VideoButtonOff from "design/assets/VideoButtonOff.svg";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
    currentAudioInputDeviceId,
    currentAudioOutputDeviceId,
    currentVideoInputDeviceId,
    micEnabled as micEnabledAtom,
    micTooltip,
} from "../../immersionState";
import { LocalAudioTrack, Room } from "twilio-video";
import * as Sentry from "@sentry/react";
import { debounce } from "../../../utils";
import { IMMERSION_SIZES } from "../../Immersion";
import MicLevelButton from "./MicLevelButton";
import usePublications from "../hooks/usePublications";
import useTrack from "../hooks/useTrack";

var aIToastId: ToastId, aOToastId: ToastId, vIToastId: ToastId;

export const AudioVideoButtons = ({
    room,
    videoWidth,
}: {
    room: Room;
    videoWidth: number;
}) => {
    const publications = usePublications(room.localParticipant);
    const localAudioPublication = publications.find(
        (publication) => publication.kind === "audio",
    );
    const localAudioTrack = useTrack(localAudioPublication);
    const [micEnabled, setMicEnabled] = useRecoilState(micEnabledAtom);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [tooltip] = useRecoilState(micTooltip);
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const [currentAIDeviceId, setCurrentAudioInputDeviceId] = useRecoilState(
        currentAudioInputDeviceId,
    );
    const [currentAODeviceId, setCurrentAudioOutputDeviceId] = useRecoilState(
        currentAudioOutputDeviceId,
    );
    const [currentVIDeviceId, setCurrentVideoInputDeviceId] = useRecoilState(
        currentVideoInputDeviceId,
    );
    const [audioTrackUpdated, setAudioTrackUpdated] = useState(0);

    const toast = useToast();

    const getCurrentActiveDeviceName = (
        groupId: any,
        deviceType: MediaDeviceKind,
        devices: MediaDeviceInfo[],
    ) => {
        var result = devices.filter(function (e1) {
            if (e1.kind === deviceType) {
                return deviceType === "videoinput"
                    ? e1.deviceId === groupId
                    : e1.groupId === groupId;
            }
        });
        const isDefault = result.length > 1;
        const newDefaultDevice =
            result.length > 1
                ? result[1]
                : result.length > 0
                  ? result[0]
                  : undefined;
        if (newDefaultDevice) {
            //@ts-ignore
            newDefaultDevice["isDefault"] = isDefault;
            if (
                newDefaultDevice.deviceId !==
                (deviceType === "audioinput"
                    ? currentAIDeviceId
                    : deviceType === "audiooutput"
                      ? currentAODeviceId
                      : deviceType === "videoinput"
                        ? currentVIDeviceId
                        : "")
            ) {
                return newDefaultDevice;
            }
        }
        return false;
    };

    const getCurrentAudioActiveGroupId = (deviceList: MediaDeviceInfo[]) => {
        //@ts-ignore
        const sinkId =
            document
                .getElementById("twilio-audio-elements")
                // @ts-ignore
                ?.getElementsByTagName("audio")[0]?.sinkId ?? "";
        if (["", "default"].includes(sinkId)) {
            // Then the audio output is the default audio output device.
            var result = deviceList.filter(function (e1) {
                return e1.deviceId === "default" && e1.kind === "audiooutput";
            });
            return result.length > 1
                ? result[1].groupId
                : result.length > 0
                  ? result[0].groupId
                  : undefined;
        } else {
            return sinkId;
        }
    };

    const getToastObject = (
        deviceInfo: any,
        deviceType: string,
    ): UseToastOptions => {
        return {
            position: "top",
            duration: 5000,
            containerStyle: {
                maxWidth: "100%",
            },
            render: () => (
                <Box
                    width="max-content"
                    background="rgba(60, 62, 68, .7)"
                    top={`calc(${IMMERSION_SIZES.HEADER_HEIGHT} + 4px)`}
                    position="relative"
                    padding="12px 8px"
                    borderRadius="4px"
                    color="white"
                >
                    Your {`${deviceInfo.isDefault ? "default" : ""}`}{" "}
                    {deviceType} changed to <b>{deviceInfo.label}</b>.
                </Box>
            ),
        };
    };

    const onChangeDevice = debounce(async () => {
        // Check the new device name
        navigator.mediaDevices.enumerateDevices().then((deviceList) => {
            Sentry.captureMessage(
                "Devices changed: " + deviceList.map((d) => d.label).join(", "),
            );
            // get the name of the current audio device
            var audioGroupId, videoDeviceId;
            room.localParticipant.audioTracks.forEach((track) => {
                //@ts-ignore
                audioGroupId = track.track?._currentDefaultDeviceInfo?.groupId;
            });
            // get the current active audio output deviceId
            const currentAudioOutputGroupId =
                getCurrentAudioActiveGroupId(deviceList);
            room.localParticipant.videoTracks.forEach((track) => {
                //@ts-ignore
                videoDeviceId = track.track?._constraints?.deviceId;
            });
            const activeAudioInput = getCurrentActiveDeviceName(
                audioGroupId,
                "audioinput",
                deviceList,
            );
            const activeAudioOutput = getCurrentActiveDeviceName(
                currentAudioOutputGroupId,
                "audiooutput",
                deviceList,
            );
            const activeVideoInput = getCurrentActiveDeviceName(
                videoDeviceId,
                "videoinput",
                deviceList,
            );

            if (activeAudioInput) {
                if (aIToastId) {
                    toast.close(aIToastId);
                }
                aIToastId = toast(
                    getToastObject(activeAudioInput, "microphone"),
                );
                setCurrentAudioInputDeviceId(activeAudioInput.deviceId);
                setAudioTrackUpdated(audioTrackUpdated + 1);
                Sentry.captureMessage(
                    "Audio Input Changed to: " + activeAudioInput.label,
                );
            }

            if (activeAudioOutput) {
                if (aOToastId) {
                    toast.close(aOToastId);
                }
                setCurrentAudioOutputDeviceId(activeAudioOutput.deviceId);
                aOToastId = toast(getToastObject(activeAudioOutput, "speaker"));
            }

            if (activeVideoInput) {
                if (vIToastId) {
                    toast.close(vIToastId);
                }
                vIToastId = toast(getToastObject(activeVideoInput, "speaker"));
                setCurrentVideoInputDeviceId(activeVideoInput.deviceId);
            }
            setDevices(deviceList);
        });
    }, 100);

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then((deviceList) => {
            setDevices(deviceList);
        });
    }, []);

    useEffect(() => {
        navigator.mediaDevices.addEventListener("devicechange", onChangeDevice);
        return () => {
            navigator.mediaDevices.removeEventListener(
                "devicechange",
                onChangeDevice,
            );
        };
    }, [currentAIDeviceId, currentAODeviceId, currentVIDeviceId]);

    const toggleMic = () => {
        room.localParticipant.audioTracks.forEach((track) => {
            if (micEnabled) {
                track.track.disable();
            } else {
                track.track.enable();
            }
        });
        setMicEnabled(!micEnabled);
    };

    const toggleVideo = () => {
        room.localParticipant.videoTracks.forEach((track) => {
            if (videoEnabled) {
                track.track.disable();
            } else {
                track.track.enable();
            }
        });
        setVideoEnabled(!videoEnabled);
    };

    const menuStyles = {
        menuOptionGroup: {
            color: "dark.400",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "600",
            lineHeight: "15px",
        },
        menuItem: {
            color: "gray.700",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "17px",
            padding: "8px 14px",
            _checked: {
                fontWeight: "600",
            },
            sx: {
                "&[aria-checked=true] svg": {
                    color: "blue.500",
                },
            },
        },
    };

    return (
        <Tooltip label={tooltip} isOpen={!!tooltip} placement="left">
            <Flex
                border="1px solid"
                borderRadius="6px"
                boxShadow="6px 6px 14px rgba(0, 0, 0, 0.04)"
                borderColor="gray.100"
                w="100%"
                maxW={videoWidth}
                alignItems="center"
                justifyContent="center"
                p="2px"
                mb="4px"
                bgColor="white"
            >
                <Flex
                    position="relative"
                    direction="column"
                    w="100%"
                    h="100%"
                    p="2px"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="4px"
                    _hover={{ backgroundColor: "gray.50" }}
                    onClick={(e) => {
                        if (
                            // If the click came from the chevron menu button (data-id: audio-menu-button), don't toggle mic
                            (e.target as HTMLElement).getAttribute(
                                "data-id",
                            ) === "audio-menu-button" ||
                            // Don't toggle mic if the click came from any of the menu elements
                            (e.target as HTMLElement).matches(
                                "[data-id='audio-menu'] *",
                            )
                        ) {
                            return;
                        }

                        toggleMic();
                    }}
                    cursor="pointer"
                >
                    <Menu>
                        <MenuButton
                            data-id="audio-menu-button"
                            position="absolute"
                            top="2px"
                            right="2px"
                            padding="4px"
                            borderRadius="4px"
                            color="#808080"
                            _hover={{
                                backgroundColor: "gray.200",
                                color: "gray.800",
                            }}
                        >
                            <ChevronDownIcon height="14px" />
                        </MenuButton>
                        {/* MenuList must be in a Portal so it anchors in the correct position (since MeuButton is absolute positioned) */}
                        <Portal>
                            <MenuList
                                zIndex={20}
                                right={0}
                                data-id="audio-menu"
                            >
                                <MenuOptionGroup
                                    title="Select Microphone"
                                    value={`${currentAIDeviceId}`}
                                    {...menuStyles.menuOptionGroup}
                                >
                                    {devices
                                        .filter(
                                            (device) =>
                                                device.kind === "audioinput",
                                        )
                                        .map((device) => (
                                            <MenuItemOption
                                                key={device.deviceId}
                                                value={device.deviceId}
                                                onClick={() => {
                                                    try {
                                                        Array.from(
                                                            room.localParticipant.audioTracks.values(),
                                                        )[0].track.restart({
                                                            deviceId: {
                                                                exact: device.deviceId,
                                                            },
                                                        });
                                                        setCurrentAudioInputDeviceId(
                                                            device.deviceId,
                                                        );
                                                        setAudioTrackUpdated(
                                                            audioTrackUpdated +
                                                                1,
                                                        );
                                                    } catch (e) {
                                                        Sentry.captureMessage(
                                                            "Failed to change audio track",
                                                        );
                                                        Sentry.captureException(
                                                            e,
                                                        );
                                                        throw e;
                                                    }
                                                }}
                                                {...menuStyles.menuItem}
                                            >
                                                {device.label.split("(")[0]}
                                            </MenuItemOption>
                                        ))}
                                </MenuOptionGroup>
                                <MenuOptionGroup
                                    title="Speaker"
                                    type="radio"
                                    value="default"
                                    {...menuStyles.menuOptionGroup}
                                >
                                    <Tooltip
                                        key="system_default_speaker"
                                        label="To change your speaker, go to your computer's sound settings."
                                        hasArrow
                                        placement="top"
                                    >
                                        <MenuItemOption
                                            isDisabled
                                            {...menuStyles.menuItem}
                                        >
                                            System Default Speaker Device
                                        </MenuItemOption>
                                    </Tooltip>
                                </MenuOptionGroup>
                            </MenuList>
                        </Portal>
                    </Menu>

                    <Flex
                        shrink={0}
                        borderRadius="4px"
                        overflowX="hidden"
                        aria-label="Audio Connect Options"
                    >
                        <MicLevelButton
                            localAudioTrack={
                                localAudioTrack as
                                    | LocalAudioTrack
                                    | undefined
                                    | null
                            }
                            micEnabled={micEnabled}
                            trackUpdated={audioTrackUpdated}
                        />
                    </Flex>

                    <Text
                        fontFamily="Inter"
                        fontSize="10px"
                        fontWeight="400"
                        lineHeight="12px"
                        textAlign="center"
                        mt="4px"
                    >
                        {micEnabled ? "Mute" : "Unmute"}
                    </Text>
                </Flex>

                <Flex
                    position="relative"
                    direction="column"
                    w="100%"
                    h="100%"
                    p="2px"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="4px"
                    _hover={{ backgroundColor: "gray.50" }}
                    onClick={(e) => {
                        // If the click came from the chevron menu button (data-id: video-menu-button), don't toggle video
                        if (
                            (e.target as HTMLElement).getAttribute(
                                "data-id",
                            ) === "video-menu-button" ||
                            // Don't toggle video if the click came from any of the menu elements
                            (e.target as HTMLElement).matches(
                                "[data-id='video-menu'] *",
                            )
                        ) {
                            return;
                        }

                        toggleVideo();
                    }}
                    cursor="pointer"
                >
                    <Menu>
                        <MenuButton
                            data-id="video-menu-button"
                            position="absolute"
                            top="2px"
                            right="2px"
                            padding="4px"
                            borderRadius="4px"
                            color="#808080"
                            _hover={{
                                backgroundColor: "gray.200",
                                color: "gray.800",
                            }}
                        >
                            <ChevronDownIcon height="14px" />
                        </MenuButton>
                        {/* MenuList must be in a Portal so it anchors in the correct position (since MeuButton is absolute positioned) */}
                        <Portal>
                            <MenuList
                                zIndex={20}
                                right="0"
                                data-id="video-menu"
                            >
                                <MenuOptionGroup
                                    title="Select Camera"
                                    value={`${currentVIDeviceId}`}
                                    {...menuStyles.menuOptionGroup}
                                >
                                    {devices
                                        .filter(
                                            (device) =>
                                                device.kind === "videoinput",
                                        )
                                        .map((device) => (
                                            <MenuItemOption
                                                key={device.deviceId}
                                                value={device.deviceId}
                                                onClick={() => {
                                                    try {
                                                        Array.from(
                                                            room.localParticipant.videoTracks.values(),
                                                        )[0].track.restart({
                                                            height: 480,
                                                            frameRate: 24,
                                                            width: 558,
                                                            deviceId: {
                                                                exact: device.deviceId,
                                                            },
                                                        });
                                                        setCurrentVideoInputDeviceId(
                                                            device.deviceId,
                                                        );
                                                    } catch (e) {
                                                        Sentry.captureMessage(
                                                            "Failed to change video track",
                                                        );
                                                        Sentry.captureException(
                                                            e,
                                                        );
                                                        throw e;
                                                    }
                                                }}
                                                {...menuStyles.menuItem}
                                            >
                                                {device.label.split("(")[0]}
                                            </MenuItemOption>
                                        ))}
                                </MenuOptionGroup>
                            </MenuList>
                        </Portal>
                    </Menu>

                    <Flex
                        shrink={0}
                        borderRadius="4px"
                        overflowX="hidden"
                        aria-label="Video Connect Options"
                    >
                        <Image
                            h="20px"
                            src={videoEnabled ? VideoButtonOn : VideoButtonOff}
                        />
                    </Flex>
                    <Text
                        fontFamily="Inter"
                        fontSize="10px"
                        fontWeight="400"
                        lineHeight="12px"
                        textAlign="center"
                        mt="4px"
                    >
                        {videoEnabled ? "Stop Video" : "Start Video"}
                    </Text>
                </Flex>
            </Flex>
        </Tooltip>
    );
};
