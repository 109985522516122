export type AppEnvironment = "production" | "staging" | "development" | "test";

export const VALID_ENVIRONMENTS: AppEnvironment[] = [
    "production",
    "staging",
    "development",
    "test",
];

export const VALID_ENVIRONMENTS_SHORT = ["prod", "stg", "dev", "test"];

export const getAppCommitHash = (): string | null => {
    const commit = process.env.REACT_APP_COMMIT;
    if (commit && commit !== "") {
        return commit.slice(0, 6);
    }
    return null;
};

export const getAppVersion = (): string | null => {
    const stage = getAppStageShort();
    const hash = getAppCommitHash();
    if (stage && hash) {
        return stage + "-" + hash;
    }
    return null;
};

export const getAppStage = (): AppEnvironment | null => {
    switch (process.env.REACT_APP_STAGE) {
        case "production":
            return "production";
        case "staging":
            return "staging";
        case "local":
            return "development";
        case "test":
            return "test";
        default:
            return null;
    }
};

export const getAppStageShort = () => {
    const stage = getAppStage();
    if (stage) {
        return VALID_ENVIRONMENTS_SHORT[VALID_ENVIRONMENTS.indexOf(stage)];
    }
    return null;
};

export const getSparkwiseAppUrl = (): string => {
    const env = getAppStage();
    switch (env) {
        case "production":
        default:
            return "https://app.sparkwise.co";
        case "staging":
            return "https://app.sparkwise-staging.co";
        case "development":
        case "test":
            return "http://localhost:3002";
    }
};

export const getSparkwiseAdminUrl = (): string => {
    const env = getAppStage();
    switch (env) {
        case "production":
        default:
            return "https://admin.sparkwise.co";
        case "staging":
            return "https://admin.sparkwise-staging.co";
        case "development":
        case "test":
            return "http://localhost:3000";
    }
};
