import { Text } from "@chakra-ui/react";
import { useStepContent } from "../hooks/useSessionContent";
import { Link } from "react-router-dom";
import { RichtextCardResource } from "./resources/RichtextCardResource";
import { useSelfData } from "../hooks/useImmersion";
import { useAccount } from "../../hooks/useAccount";
import { RescheduleOptions } from "@prisma/client";

export const PromptIntroductions = () => {
    const { variables } = useStepContent();
    const self = useSelfData();
    const account = useAccount();

    // NOTE: The variables are swapped here intentionally because of the order they were added in Airtable (we first allowed the 2nd bullet to be customized, then later allowed the 1st)
    const customFirstItem = variables?.variable2 ?? null;
    const customSecondItem = variables?.variable1 ?? null;

    const item1 = `👋 **Say hi:** ${
        customFirstItem ??
        "If meeting for the first time, do quick introductions (your name, role, and location)."
    }`;
    const item2 = `💭 **Briefly discuss:** ${
        customSecondItem ?? "What is relatable about the topic of this session?"
    }`;
    const item3 = `➡️ **Go to the next step:** Do not wait long for more people.`;

    const title = "Rapid Sharing";
    const subtitle = "Time to gather and start talking!";
    let note;
    if (
        account.data?.organization.rescheduleType ===
        RescheduleOptions.NO_RESCHEDULE
    ) {
        note = (
            <Text mt="30px" color="gray.500" fontSize="15px" textAlign="center">
                If no one else joins for 10 minutes, review the content solo.
            </Text>
        );
    } else {
        note = (
            <Text mt="30px" color="gray.500" fontSize="15px" textAlign="center">
                If no one else joins for 10 minutes, review the content solo (or{" "}
                <Link
                    // underline text
                    to={`/rescheduleoptions/${self.sessionParticipantUuid}?ref=rapidsharing`}
                >
                    <Text display="inline" textDecoration={"underline"}>
                        request rescheduling
                    </Text>
                </Link>
                ).
            </Text>
        );
    }
    return (
        <RichtextCardResource
            title={title}
            subtitle={subtitle}
            content={[item1, item2, item3]}
            note={note}
        />
    );
};
