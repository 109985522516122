import { Stack } from "@chakra-ui/react";
import { Room as TwilioRoom, Logger } from "twilio-video";
import React, { useEffect, useState } from "react";
import useParticipants from "./hooks/useParticipants";
import { Participant } from "./components/Participant";
import {
    useSelfData,
    useParticipants as useSessionParticipants,
} from "../hooks/useImmersion";
import { useAccount } from "../../hooks/useAccount";
import { useIsStepVideo } from "../hooks/useSessionContent";
import { AudioVideoButtons } from "./components/AudioVideoButtons";
import { IMMERSION_SIZES } from "../Immersion";
import { PARTICIPANT_BACKGROUNDS } from "../../Constants";
import { trpc } from "../../hooks/useTRPC";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";

export const VideoRoom = ({ room }: { room: TwilioRoom }) => {
    const account = useAccount();
    const { groupId } = useParams();
    const sessionParticipants = useSessionParticipants();
    const self = useSelfData();
    const ref = React.createRef<HTMLDivElement>();
    const participants = useParticipants(room);
    const [videoWidth, setVideoWidth] = useState(0);
    const isVideoContent = useIsStepVideo();
    const { HEADER_HEIGHT, FOOTER_HEIGHT, STEP_MARGIN_TOP } = IMMERSION_SIZES;
    const VIDEO_SIDEBAR_BOTTOM_MARGIN = "8px";
    const maxH = `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT} - ${STEP_MARGIN_TOP} - ${VIDEO_SIDEBAR_BOTTOM_MARGIN})`;

    if (process.env.REACT_APP_TWILIO_LOGS) {
        const logger = Logger.getLogger("twilio-video");
        logger.setLevel("debug");
    }

    useEffect(() => {
        const updateWidth = () => {
            const videoHeightMultiplier =
                sessionParticipants.length >= 5 ? 5.75 : 4.75;
            let videoHeight =
                (ref.current?.clientHeight || 0) / videoHeightMultiplier;

            videoHeight = Math.min(Math.max(videoHeight, 75), 400);

            let newVideoWidth = (videoHeight * 3.5) / 3 + 2;
            newVideoWidth = Math.min(newVideoWidth, window.innerWidth * 0.15);

            setVideoWidth(newVideoWidth);
        };
        updateWidth();
        window.addEventListener("resize", updateWidth);

        // if video step, trigger resize for video content too
        if (isVideoContent) {
            window.dispatchEvent(new Event("resize"));
        }

        return () => window.removeEventListener("resize", updateWidth);
    }, [sessionParticipants, ref, isVideoContent]);

    const localSessionParticipant = sessionParticipants.find(
        (participant) => participant.user.id === room.localParticipant.identity,
    );

    if (!account.data) {
        return <div>Error...</div>;
    }

    return (
        <Stack
            w={`${videoWidth}px`}
            flexDirection="column"
            ref={ref}
            overflow={"hidden"}
            gap="8px"
            mt={`calc(${STEP_MARGIN_TOP} - 8px)`}
            mb={VIDEO_SIDEBAR_BOTTOM_MARGIN}
            alignItems="center"
            maxH={maxH}
            overflowY={"auto"}
        >
            <div id="twilio-audio-elements" />
            <AudioVideoButtons room={room} videoWidth={videoWidth} />
            <Participant
                background={
                    PARTICIPANT_BACKGROUNDS[localSessionParticipant?.index || 0]
                }
                local={true}
                key="local"
                participant={room.localParticipant}
                firstName={account.data.firstName || ""}
                lastName={account.data.lastName || ""}
                width={videoWidth}
            ></Participant>
            {sessionParticipants
                .filter(
                    (participant) =>
                        participant.user.id !== room.localParticipant.identity,
                )
                .sort((sp1, sp2) => {
                    return participants.some(
                        (participant) =>
                            // TODO: Only use user ID after sleek hamster launch
                            participant.identity === sp1.user.id ||
                            participant.identity === sp1.id,
                    )
                        ? -1
                        : 1;
                })
                .map((sessionParticipant, index) => {
                    return (
                        <Participant
                            background={
                                PARTICIPANT_BACKGROUNDS[
                                    sessionParticipant.index
                                ]
                            }
                            local={false}
                            key={sessionParticipant.id}
                            // TODO: Only use user ID after sleek hamster launch
                            participant={participants.find(
                                (participant) =>
                                    participant.identity ===
                                        sessionParticipant.user.id ||
                                    participant.identity ===
                                        sessionParticipant.id,
                            )}
                            firstName={`${sessionParticipant.user.firstName}`}
                            lastName={`${sessionParticipant.user.lastName}`}
                            width={videoWidth}
                        ></Participant>
                    );
                })}
        </Stack>
    );
};
