import MicLevelButton from "../../components/MicLevelButton";
import { isAudioEnabledState } from "../../../immersionState";
import { Box } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import React from "react";

export const AudioControlButton: React.FC<{
    audioTrack?: MediaStreamTrack | null;
}> = ({ audioTrack }) => {
    const [isAudioEnabled, setIsAudioEnabled] =
        useRecoilState(isAudioEnabledState);
    return (
        <Box
            p={2}
            borderRadius={8}
            bg={"white"}
            _hover={{
                cursor: "pointer",
                backgroundColor: "gray.200",
                color: "gray.800",
            }}
            onClick={() => setIsAudioEnabled(!isAudioEnabled)}
        >
            <MicLevelButton
                localAudioTrack={audioTrack}
                micEnabled={isAudioEnabled}
            />
        </Box>
    );
};
