import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { Immersion } from "./immersion/Immersion";
import { trpc } from "./hooks/useTRPC";
import { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { Recap } from "./home/recap/Recap";
import { Chatwoot } from "./Chatwoot";
import { Loading } from "./Loading";
import { NotFound } from "./NotFound";
import { Reschedule } from "./reschedule/Reschedule";
import { RescheduleConfirmation } from "./reschedule/RescheduleConfirmation";
import * as Sentry from "@sentry/react";
import { BrowserErrorModal } from "./errors/BrowserErrorModal";
import Home from "./home/Home";
import { MatchingScreen } from "./immersion/matching/MatchingScreen";
import { Error } from "./Error";
import { RescheduleOptions } from "./reschedule/RescheduleOptions";
import { CancellationConfirmation } from "./reschedule/CancellationConfirmation";
import { CompletedSessions } from "./home/CompletedSessions";
import { useAccount } from "./hooks/useAccount";
import { Box, Center } from "@chakra-ui/react";
import { ChimeContextProvider } from "./immersion/video_chime/context/ChimeContext";

const Router = () => {
    const account = useAccount();

    if (account.data?.isActive === false) {
        return (
            <Center height="100vh">
                <Box p={5}>
                    Your account or the organization you belong to has been
                    deactivated. Please contact support for assistance.
                </Box>
            </Center>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="login" element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<Home />} />
            <Route path="reviews" element={<CompletedSessions />} />
            <Route
                path="reviews/:id/success"
                element={<Recap params={{ success: "success" }} />}
            />
            <Route path="reviews/:id" element={<Recap params={{}} />} />
            <Route
                path="immersion/:id/intro/:stepNumber"
                element={
                    <ChimeContextProvider>
                        <Immersion />
                    </ChimeContextProvider>
                }
            />
            <Route
                path="immersion/:id/match"
                element={
                    <ChimeContextProvider>
                        <MatchingScreen />
                    </ChimeContextProvider>
                }
            />
            <Route
                path="immersion/:id/group/:groupId/:stepNumber"
                element={
                    <ChimeContextProvider>
                        <Immersion />
                    </ChimeContextProvider>
                }
            />
            <Route
                path="rescheduleoptions/:id"
                element={<RescheduleOptions />}
            />
            <Route
                path="reschedule/confirm"
                element={<RescheduleConfirmation />}
            />
            <Route path="reschedule/:id" element={<Reschedule />} />
            <Route
                path="cancellation/confirm"
                element={<CancellationConfirmation />}
            />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export const App = () => {
    const {
        error,
        isLoading,
        isAuthenticated,
        user,
        loginWithRedirect,
        getAccessTokenSilently,
    } = useAuth0();
    const posthog = usePostHog();

    const [accessToken, setAccessToken] = useState("");
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    const trpcClient = trpc.createClient({
        links: [
            httpBatchLink({
                url: process.env.REACT_APP_API_ROOT! + "/trpc/app",
                fetch(url, options) {
                    return fetch(url, {
                        ...options,
                        credentials: "include",
                        headers: {
                            ...options?.headers,
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                },
                headers() {
                    return {
                        Authorization: `Bearer ${accessToken}`,
                    };
                },
            }),
        ],
    });

    useEffect(() => {
        if (user?.email) {
            Sentry.setUser({ email: user?.email });
        }
    }, [user]);

    useEffect(() => {
        if (user?.email) {
            posthog?.identify(user.email, {
                email: user.email,
            });
            const domain = user.email.split("@")[1];
            posthog?.group("domain", domain);
        }
    }, [posthog, user?.email]);

    useEffect(() => {
        const errorHandler = (event: ErrorEvent) => {
            const target = event.target as HTMLElement;
            if (!target) return;
            if (target.tagName === "IMG") {
                Sentry.captureException(
                    `Failed to load image: ${(target as HTMLImageElement).src}`,
                    {
                        level: "warning",
                    },
                );
            }
        };
        document.body.addEventListener("error", errorHandler, true);
        return () => document.body.removeEventListener("error", errorHandler);
    }, []);

    if (error) {
        Sentry.captureException(error);
        if (
            error.message.includes(
                "Expiration Time (exp) claim error in the ID token",
            )
        ) {
            return (
                <Error
                    text={`${error.name}: ${error.message}`}
                    advisement="Computer clock is likely set incorrectly."
                ></Error>
            );
        } else {
            <Error text={`${error.name}: ${error.message}`}></Error>;
        }
    } else if (isLoading) {
        return <Loading></Loading>;
    } else if (!accessToken) {
        getAccessTokenSilently({
            authorizationParams: {
                audience: "server",
                timeoutInSeconds: 1,
            },
        })
            .then((token: string) => {
                // We no longer use session storage for the token, so remove previously set tokens
                sessionStorage.removeItem("auth0Token");
                setAccessToken(token);
            })
            .catch(() =>
                loginWithRedirect({
                    appState: { returnTo: window.location.pathname },
                }),
            );
    } else if (!isAuthenticated) {
        loginWithRedirect({ appState: { returnTo: window.location.pathname } });
    } else {
        return (
            <trpc.Provider client={trpcClient} queryClient={queryClient}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <Chatwoot />
                        <BrowserErrorModal />
                        <Router />
                    </BrowserRouter>
                </QueryClientProvider>
            </trpc.Provider>
        );
    }
    return <Loading></Loading>;
};
