import { VideoTrack as TwilioVideoTrack } from "twilio-video";
import React, { useState, useEffect, memo } from "react";
import { Box } from "@chakra-ui/react";

export const VideoTrack = memo(
    ({ track, local }: { track: TwilioVideoTrack; local: boolean }) => {
        const [videoOn, setVideoOn] = useState(track.isEnabled);
        const ref = React.createRef<HTMLDivElement>();
        useEffect(() => {
            ref.current?.replaceChildren(track.attach());
        }, [track, videoOn, ref]);

        useEffect(() => {
            const turnVideoOn = () => setVideoOn(true);
            const turnVideoOff = () => setVideoOn(false);
            track.on("enabled", turnVideoOn);
            track.on("disabled", turnVideoOff);
            return () => {
                track.off("enabled", turnVideoOn);
                track.off("disabled", turnVideoOff);
            };
        }, [track, setVideoOn]);

        return (
            <Box
                transform={local ? "scaleX(-1)" : ""}
                zIndex={5}
                position="absolute"
                visibility={videoOn ? undefined : "hidden"}
                className="track"
                ref={ref}
            ></Box>
        );
    },
);
