import { Divider, Image, Stack } from "@chakra-ui/react";
import Sparkwise from "design/assets/Sparkwise.svg";

import PowerBySparkwise from "design/assets/PowerByLogo.svg";
import { useAccount } from "./hooks/useAccount";

export const Logo = () => {
    const account = useAccount();

    return account.data && account.data.organization.cobrandingLogoUrl ? (
        <Stack direction={"row"} spacing={4} h={10} alignItems={"center"}>
            <Image
                height="30px"
                src={account.data?.organization.cobrandingLogoUrl || undefined}
                alt={`${account.data?.organization.name} logo`}
            />
            <Divider orientation="vertical" h="30px" />
            <Image src={PowerBySparkwise} alt="Powered by Sparkwise" />
        </Stack>
    ) : (
        <Image src={Sparkwise} alt="Sparkwise logo" />
    );
};
