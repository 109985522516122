import {
    Button,
    Flex,
    Heading,
    Image,
    Link,
    ListItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import NoCameraAccess from "design/assets/NoCameraAccess.svg";

export const CameraAccessModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent minW={"600px"}>
                <ModalHeader mb={"16px"}>
                    <Flex
                        w={"100%"}
                        direction={"row"}
                        justifyContent={"center"}
                        mb={"24px"}
                    >
                        <Image src={NoCameraAccess} />
                    </Flex>
                    <Heading
                        fontWeight="bold"
                        fontSize="22px"
                        textAlign="center"
                    >
                        Unable to access your camera
                    </Heading>
                </ModalHeader>
                <ModalBody>
                    <UnorderedList
                        fontSize={"14px"}
                        color={"dark.700"}
                        mb={"28px"}
                        lineHeight={"22px"}
                    >
                        <ListItem>
                            Close other apps that might be using your camera
                        </ListItem>
                        <ListItem>
                            If using an external camera, disconnect and
                            reconnect it
                        </ListItem>
                        <ListItem>Close and reopen your browser</ListItem>
                    </UnorderedList>
                    <Text color={"dark.400"} fontSize={"14px"} mb={"28px"}>
                        If you need more help,{" "}
                        <Link
                            color="blue.500"
                            textDecoration="underline"
                            fontWeight="600"
                            onClick={() =>
                                // @ts-ignore
                                window.$chatwoot.toggle()
                            }
                        >
                            request live support
                        </Link>
                        .
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Flex
                        w={"100%"}
                        justifyContent={"flex-end"}
                        direction={"row"}
                    >
                        <Button
                            onClick={onClose}
                            variant="outline"
                            colorScheme="gray"
                            background="white"
                        >
                            Dismiss
                        </Button>
                        <Button
                            onClick={() => {
                                window.location.reload();
                            }}
                            colorScheme="dark"
                            background="black"
                            ml={"20px"}
                        >
                            Try again
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
