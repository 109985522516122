import { useOthers, useSelf } from "../../liveblocks.config";
import { Participant, useParticipants } from "./useImmersion";

export const useStepToParticipants = () => {
    const participants = useParticipants();
    const others = useOthers();
    const myself = useSelf();

    const stepToParticipants: { [step: number]: Participant[] } = {};
    for (const other of others) {
        const participant = participants.find(
            (p) => p.user.emails[0].email === other.id,
        );
        if (other.id && participant) {
            if (stepToParticipants[other.presence.stepNumber]) {
                stepToParticipants[other.presence.stepNumber].push(participant);
            } else {
                stepToParticipants[other.presence.stepNumber] = [participant];
            }
        }
    }
    if (myself) {
        const participant = participants.find(
            (p) => p.user.emails[0].email === myself.id,
        );
        if (myself.id && participant) {
            if (stepToParticipants[myself.presence.stepNumber]) {
                stepToParticipants[myself.presence.stepNumber].push(
                    participant,
                );
            } else {
                stepToParticipants[myself.presence.stepNumber] = [participant];
            }
        }
    }
    return stepToParticipants;
};
