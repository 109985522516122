import { useOwnResources, useStepContent } from "../hooks/useSessionContent";
import { useSelfData } from "../hooks/useImmersion";
import { Flex } from "@chakra-ui/react";
import { IFrameResource } from "./resources/IFrameResource";
import { VideoResource } from "./resources/VideoResource";

export const SplitVideoEtherpad = () => {
    const { resources } = useStepContent();
    const ownResources = useOwnResources();
    const self = useSelfData();
    const video = resources?.resource1?.[0];
    const etherpad = ownResources?.resource2;
    const { altText: videoCaptions = "", systemId: videoSystemId = "" } =
        video ?? {};
    const {
        url: etherpadBaseUrl = "",
        name: etherpadName = "",
        usageInSession: etherpadUsageInSession = "",
        systemId: etherpadSystemId = "",
    } = etherpad ?? {};
    const participantName = self?.firstName ?? "";

    const etherpadUrl = `${etherpadBaseUrl}/${etherpadSystemId}?showControls=true&showChat=false&showLineNumbers=true&useMonospaceFont=false&userName=${participantName}&theme=sparkwise`;
    return (
        <Flex w="100%" gap="16px" justifyContent="center">
            <VideoResource systemId={videoSystemId} captions={videoCaptions} />
            <Flex flexBasis="40%">
                <IFrameResource
                    url={etherpadUrl}
                    name={etherpadName}
                    headerType={
                        etherpadUsageInSession === "individualized"
                            ? "solo"
                            : "group"
                    }
                    resourceType="etherpad"
                />
            </Flex>
        </Flex>
    );
};
