export const statusTextVariants = {
    waiting: {
        start: "Waiting for fellow participants...",
        long: "A perfect match takes time to find...\nPlease do not reload this page.",
    },
    groupedNew: {
        start: "Forming your group...",
        matched: "You have been matched!",
        setup: "Setting up for launch...",
        complete: "Your group is ready to start!",
    },
    groupedExisting: {
        start: "Matching you to a group...",
        matched: "You have been matched!",
        complete: "You are ready to join your group!",
    },
    rescheduled: {
        complete: "You have not been matched to a group.",
    },
};

export const mindsetQuotes = [
    "Get ready to tackle challenges.",
    "Make it awesome by getting your whole group involved.",
    "Take the opportunity to build connections.",
    "Don't worry about getting it perfect. This is a space to practice.",
    "Want to spark aha moments? Ask 'why' questions.",
    "Help others grow by sharing your own insights.",
    "Learning is better together. Happy collaborating!",
    "Take this time to invest in your own growth.",
];
