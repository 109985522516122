import { DefaultActiveSpeakerPolicy } from "amazon-chime-sdk-js";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { meetingSession } from "../../immersionState";

export const useActiveSpeaker = (): string | null => {
    const meeting = useRecoilValue(meetingSession);
    const [activeSpeaker, setActiveSpeaker] = useState<string | null>(null);
    useEffect(() => {
        if (!meeting) {
            return;
        }
        meeting?.audioVideo.subscribeToActiveSpeakerDetector(
            new DefaultActiveSpeakerPolicy(),
            (activeSpeakers) => {
                if (activeSpeakers.length > 0) {
                    setActiveSpeaker(activeSpeakers[0]);
                } else {
                    setActiveSpeaker(null);
                }
            },
            undefined,
            100,
        );
        return () => {
            meeting?.audioVideo.unsubscribeFromActiveSpeakerDetector(() => {
                setActiveSpeaker(null);
            });
        };
    }, [meeting]);

    return activeSpeaker;
};
