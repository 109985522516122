import { useEffect, useState } from "react";
import {
    LocalTrackPublication,
    Participant,
    RemoteTrackPublication,
} from "twilio-video";

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

// Get all currently active tracks related to a participant.
export default function usePublications(participant?: Participant) {
    const [publications, setPublications] = useState<TrackPublication[]>([]);

    useEffect(() => {
        if (!participant) {
            return;
        }
        setPublications(
            Array.from(participant.tracks.values()) as TrackPublication[],
        );

        const publicationAdded = (publication: TrackPublication) =>
            setPublications((publications) => [...publications, publication]);
        const publicationRemoved = (publication: TrackPublication) =>
            setPublications((publications) =>
                publications.filter((p) => p !== publication),
            );

        participant.on("trackPublished", publicationAdded);
        participant.on("trackUnpublished", publicationRemoved);
        return () => {
            participant.off("trackPublished", publicationAdded);
            participant.off("trackUnpublished", publicationRemoved);
        };
    }, [participant]);

    return publications;
}
