import { Params, useParams } from "react-router-dom";
import { theme } from "./index";

export function debounce(func: Function, timeout = 300) {
    let timer: number;
    return (...args: any) => {
        clearTimeout(timer);
        timer = window.setTimeout(() => {
            func.apply(args);
        }, timeout);
    };
}

export function throttle(func: Function, timeout = 300) {
    let isThrottled = false;
    return (...args: any) => {
        if (!isThrottled) {
            func.apply(args);
            isThrottled = true;
            setTimeout(() => {
                isThrottled = false;
            }, timeout);
        }
    };
}

export interface ParamsProps {
    [key: string]: any;
    params: Params;
}

export const withParams = (Component: any) => {
    return (props: ParamsProps) => (
        <Component {...props} params={useParams()} />
    );
};

export const mapColorNameToHex = (colorName: string) => {
    const [color, shade] = colorName.split(".");
    return theme.colors[color][shade];
};

// This function should be moved to shared package, but shared is currently broken this
// function is duplicated here and in packages/dashboard/src/utils.tsx
export const getCookie = (cookies:string, name: string): string|null => {
	return cookies
		.split(';')
		.map(c => c.trim())
		.filter(cookie => {
			return cookie.substring(0, name.length + 1) === `${name}=`;
		})
		.map(cookie => {
			return decodeURIComponent(cookie.substring(name.length + 1));
		})[0] || null;
}