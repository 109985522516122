import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Progress,
    Spinner,
    Text,
    Tooltip,
    useDisclosure,
} from "@chakra-ui/react";
import {
    ChevronDownIcon,
    InformationCircleIcon,
    MicrophoneIcon,
    SpeakerWaveIcon,
} from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/react";
import audio from "design/assets/sampleSound1.mp3";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
    connect,
    createLocalTracks,
    LocalAudioTrack,
    LocalVideoTrack,
} from "twilio-video";
import AudioErrorIcon from "../../assets/audio_error.svg";
import { useAccount, useAreVideosRecorded } from "../../hooks/useAccount";
import { trpc } from "../../hooks/useTRPC";
import { useHasVisitedGroupSession } from "../hooks/useImmersion";
import {
    currentAudioInputDeviceId,
    currentAudioOutputDeviceId,
    currentVideoInputDeviceId,
    videoRoom,
} from "../immersionState";
import { ConnectByPhoneModal } from "../video_common/ConnectByPhoneModal";
import {
    ConnectionErrorModal,
    ErrorModalData,
} from "../video_common/ConnectionErrorModal";
import { VideoTrack } from "./components/VideoTrack";
import {
    disconnectionErrorHelpMessage,
    handleConnectionError,
    mediaErrorHelpMessage,
} from "./ErrorLib";
import { pollAudioLevel } from "./utils";

const TOOLTIP_TEXT =
    "Your company has authorized Sparkwise to record this session. This helps us continuously improve our content and platform. Rest assured, the recording is for internal use only.";

const ZETA_SCHOOLS_ID = "f79debb1-2ea8-41f0-9990-c40ae4d7e355";

const menuStyles = {
    menuOptionGroup: {
        color: "dark.400",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "15px",
    },
    menuItem: {
        color: "gray.700",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "17px",
        padding: "8px 14px",
        _checked: {
            fontWeight: "600",
        },
        sx: {
            "&[aria-checked=true] svg": {
                color: "blue.500",
            },
        },
    },
};

export const JoinVideoModal = memo(
    ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
        const {
            isOpen: errorOpen,
            onOpen: onErrorOpen,
            onClose: onErrorClose,
        } = useDisclosure();
        const {
            isOpen: isPhoneModalOpen,
            onOpen: onPhoneModalOpen,
            onClose: onPhoneModalClose,
        } = useDisclosure();
        const {
            isOpen: isAudioMenuOpen,
            onOpen: onAudioMenuOpen,
            onClose: onAudioMenuClose,
        } = useDisclosure();
        const setupRoom = trpc.immersion.setupRoom.useMutation();
        const isRecorded = useAreVideosRecorded();
        const { id, groupId } = useParams();
        const account = useAccount();

        const { data } = trpc.account.getTwilioToken.useQuery(
            {
                groupId: groupId || "",
            },
            {
                enabled: !!groupId,
            },
        );

        const setVideoRoom = useSetRecoilState(videoRoom);
        const [joinVideoRoomLoading, setJoinVideoRoomLoading] = useState(false);

        const [audioInputDevice, setAudioInputDevice] = useRecoilState(
            currentAudioInputDeviceId,
        );
        const [audioOutputDevice, setAudioOutputDevice] = useRecoilState(
            currentAudioOutputDeviceId,
        );
        const [videoDevice, setVideoDevice] = useRecoilState(
            currentVideoInputDeviceId,
        );

        const [audioLevel, setAudioLevel] = useState<number>(0);
        const [tooltipView, settooltipView] = useState(false);
        const [isTrackReady, setTrackReady] = useState(false);
        let audioDetectionTimeoutId = useRef(0);

        const buttonRef = React.createRef<HTMLButtonElement>();
        const [devices, setDevices] = useState<MediaDeviceInfo[]>();

        const [error, setError] = useState<ErrorModalData | null>(null);
        const [videoTrack, setVideoTrack] = useState<LocalVideoTrack | null>();
        const [audioTrack, setAudioTrack] = useState<LocalAudioTrack | null>();
        const hasVisitedSession = useHasVisitedGroupSession();
        const [firstVisit, setFirstVisit] = useState(true);

        const [testSoundPlaying, setTestSoundPlaying] = useState(false);
        const testSound = useMemo(() => new Audio(audio), []);

        useEffect(() => {
            const playListener = () => {
                setTestSoundPlaying(true);
            };
            const pauseListener = () => {
                setTestSoundPlaying(false);
            };
            testSound.addEventListener("play", playListener);
            testSound.addEventListener("pause", pauseListener);

            return () => {
                testSound.pause();
                testSound.remove();
                testSound.removeEventListener("play", playListener);
                testSound.removeEventListener("pause", pauseListener);
            };
        }, [testSound]);

        useEffect(() => {
            if (isOpen) {
                setError(null);
                videoTrack?.stop();
                audioTrack?.stop();
                Sentry.captureMessage("Video Device Requested: " + videoDevice);
                Sentry.captureMessage(
                    "Audio Device Requested: " + audioInputDevice,
                );
                // Track if its the user's first ever visit to the Join Video Modal.
                setFirstVisit(!localStorage.getItem("HasVisited"));
                localStorage.setItem("HasVisited", "true");

                // First, try to access user media.
                createLocalTracks({
                    audio: { deviceId: audioInputDevice || undefined },
                    video: { deviceId: videoDevice || undefined },
                })
                    .then((tracks) => {
                        tracks.forEach((track) => {
                            switch (track.kind) {
                                case "video":
                                    setVideoTrack(track);
                                    break;
                                case "audio":
                                    setAudioTrack(track);
                                    break;
                            }
                        });
                        navigator.mediaDevices
                            .enumerateDevices()
                            .then((devices) => {
                                setDevices(devices);
                                if (!audioInputDevice) {
                                    setAudioInputDevice(
                                        devices.find(
                                            (device) =>
                                                device.kind === "audioinput",
                                        )?.deviceId || null,
                                    );
                                }
                                if (!audioOutputDevice) {
                                    setAudioOutputDevice(
                                        devices.find(
                                            (device) =>
                                                device.kind === "audiooutput",
                                        )?.deviceId || null,
                                    );
                                }
                                if (!videoDevice) {
                                    setVideoDevice(
                                        devices.find(
                                            (device) =>
                                                device.kind === "videoinput",
                                        )?.deviceId || null,
                                    );
                                }
                            })
                            .catch((e) => {
                                Sentry.captureException(e);
                                setError({
                                    title: "Unable to access camera and microphone",
                                    message: mediaErrorHelpMessage(e),
                                    permissionsError:
                                        e.name === "NotAllowedError",
                                });
                                onClose();
                                onErrorOpen();
                            });
                    })
                    .catch((e) => {
                        Sentry.captureException(e);
                        setError({
                            title: "Unable to access camera and microphone",
                            message: mediaErrorHelpMessage(e),
                            permissionsError: e.name === "NotAllowedError",
                        });
                        onClose();
                        onErrorOpen();
                    });
            }

            // We violate the rule that all deps should be listed in an effect.
            // We need to do this (ish) to prevent this effect from re-running too
            // frequently, which will cause the component not to behave correctly.
            // eslint-disable-next-line
        }, [isOpen, audioInputDevice, videoDevice]);

        useEffect(() => {
            if (!isOpen) {
                videoTrack?.stop();
                audioTrack?.stop();
            }
        }, [isOpen, videoTrack, audioTrack]);

        // useEffect for audio level
        useEffect(() => {
            if (audioTrack) {
                audioTrack.on("stopped", () => {
                    setTrackReady(false);
                });
                audioTrack.on("started", () => {
                    pollAudioLevel(audioTrack, setAudioLevel);
                    setTrackReady(true);
                });
                if (audioTrack.isStarted === true) {
                    pollAudioLevel(audioTrack, setAudioLevel);
                    setTrackReady(true);
                }
            } else {
                setTrackReady(false);
            }
        }, [audioTrack]);

        // useEffect for hide and show tooltip
        useEffect(() => {
            if (isTrackReady) {
                // If mic level is 0, set a timeout to show content after 1 seconds
                if (audioLevel <= 0) {
                    audioDetectionTimeoutId.current = window.setTimeout(() => {
                        settooltipView(true);
                    }, 1500);
                } else {
                    // If mic level is not 0, clear the timeout and hide content
                    clearTimeout(audioDetectionTimeoutId.current);
                    settooltipView(false);
                }
            } else {
                // If mic level is not 0, clear the timeout and hide content
                clearTimeout(audioDetectionTimeoutId.current);
                settooltipView(false);
            }
            return () => {
                clearTimeout(audioDetectionTimeoutId.current);
            };
        }, [audioLevel, audioTrack, isTrackReady]);

        const joinVideoRoom = async (
            token: string,
            noiseCancellation: boolean,
        ) => {
            Sentry.captureMessage(
                `Connecting via ${videoDevice} & ${audioInputDevice}`,
            );

            const audioWithCancellation = {
                noiseSuppression: false,
                echoCancellation: true,
                deviceId: audioInputDevice || undefined,
                noiseCancellationOptions: {
                    sdkAssetsPath: "/krisp-audio-plugin@1.0.0",
                    vendor: "krisp",
                },
            };
            const audioWithoutCancellation = {
                noiseSuppression: true,
                echoCancellation: true,
                deviceId: audioInputDevice || undefined,
            };
            try {
                const roomId = `${process.env.NODE_ENV}-${groupId}`;
                const room = await connect(token, {
                    name: roomId,
                    audio: noiseCancellation
                        ? audioWithCancellation
                        : audioWithoutCancellation,
                    video: {
                        height: 480,
                        frameRate: 24,
                        width: 558,
                        deviceId: videoDevice || undefined,
                    },
                    bandwidthProfile: {
                        video: {
                            mode: "grid",
                        },
                    },
                    maxAudioBitrate: 16000,
                    preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
                    networkQuality: { local: 2, remote: 2 },
                    notifyWarnings: ["recording-media-lost"],
                });

                setupRoom.mutate({
                    sessionId: id || "",
                    roomId,
                    recordingEnabled: isRecorded || false,
                });

                setVideoRoom(room);
                room.on("reconnecting", (error) =>
                    Sentry.captureException(error),
                );
                room.on("reconnected", () =>
                    Sentry.captureMessage("Reconnected."),
                );
                room.once("disconnected", (room, error) => {
                    setVideoRoom(null);
                    if (error) {
                        Sentry.captureException(error);
                        setError({
                            title: "You were disconnected from the video chat",
                            message: disconnectionErrorHelpMessage(error),
                            permissionsError: false,
                        });
                        onClose();
                        onErrorOpen();
                    }
                });
            } catch (e: any) {
                Sentry.captureException(e);
                setError({
                    title: "Error connecting to the video chat",
                    message: handleConnectionError(e),
                    permissionsError: false,
                });
                onClose();
                onErrorOpen();
            }
            onClose();
        };

        return (
            <>
                <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    closeOnOverlayClick={false}
                >
                    <ModalOverlay></ModalOverlay>
                    <ModalContent maxWidth="600px" padding="0px">
                        <ModalBody padding="0px">
                            <Flex
                                flexDirection="column"
                                alignItems="center"
                                padding="32px 40px 24px 40px"
                            >
                                <Text
                                    fontFamily="Inter"
                                    fontSize="20px"
                                    fontWeight="600"
                                    lineHeight="26px"
                                    marginBottom="8px"
                                >
                                    Set up your camera and microphone
                                </Text>
                                <Text
                                    fontFamily="Inter"
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="22px"
                                    marginBottom="10px"
                                >
                                    Connect to video to meet your group
                                </Text>
                                <Box position="relative" marginTop="15px">
                                    <Box
                                        transform={"scaleX(-1)"}
                                        borderRadius="10px"
                                        overflow="hidden"
                                        width="279px"
                                        height="210px"
                                        background="gray.50"
                                        marginBottom="20px"
                                    >
                                        {videoTrack ? (
                                            <VideoTrack
                                                track={videoTrack}
                                                local={false}
                                            />
                                        ) : (
                                            <Center height="100%">
                                                {firstVisit &&
                                                !hasVisitedSession ? (
                                                    <Text
                                                        color="black"
                                                        transform={"scaleX(-1)"}
                                                    >
                                                        Click "<b>Allow</b>"
                                                        when prompted
                                                    </Text>
                                                ) : (
                                                    <Spinner
                                                        size="lg"
                                                        speed=".65s"
                                                        color="gray.700"
                                                    />
                                                )}
                                            </Center>
                                        )}
                                    </Box>
                                </Box>
                                {!!videoTrack && !!devices?.length && (
                                    <Flex
                                        flexDirection="row"
                                        width="100%"
                                        gap="8px"
                                        justifyContent="space-between"
                                    >
                                        <Flex
                                            flexDirection="column"
                                            gap="5px"
                                            width="49%"
                                        >
                                            <Flex gap="2px">
                                                <Text
                                                    fontSize="sm"
                                                    fontWeight="semibold"
                                                >
                                                    Audio
                                                </Text>
                                                <Tooltip
                                                    isOpen={
                                                        !isAudioMenuOpen &&
                                                        tooltipView
                                                    }
                                                    hasArrow
                                                    placement="top"
                                                    label="No signal detected from selected microphone. Change your audio input device or refresh this page."
                                                    fontFamily="Inter"
                                                    fontSize="12px"
                                                    fontWeight="400"
                                                    lineHeight="17px"
                                                    textAlign="left"
                                                    border="1px solid #FAD2BE"
                                                    bg="#FEF8F5"
                                                    color="dark.800"
                                                    padding="8px"
                                                    w="206px"
                                                >
                                                    <Image
                                                        src={AudioErrorIcon}
                                                        display={
                                                            tooltipView
                                                                ? "block"
                                                                : "none"
                                                        }
                                                        w="16px"
                                                        h="16px"
                                                    />
                                                </Tooltip>
                                            </Flex>
                                            <Menu
                                                isOpen={isAudioMenuOpen}
                                                onClose={onAudioMenuClose}
                                                onOpen={onAudioMenuOpen}
                                            >
                                                <MenuButton
                                                    borderWidth="1px"
                                                    borderColor="gray.100"
                                                    borderRadius="6px"
                                                >
                                                    <Flex
                                                        height="34px"
                                                        padding="6px 10px 6px 8px"
                                                        justifyContent="space-between"
                                                        gap="12px"
                                                    >
                                                        <Flex
                                                            alignItems="center"
                                                            gap="8px"
                                                            overflow="hidden"
                                                        >
                                                            {testSoundPlaying && (
                                                                <SpeakerWaveIcon height="16px" />
                                                            )}
                                                            <Text
                                                                whiteSpace="nowrap"
                                                                overflow="hidden"
                                                                textOverflow="ellipsis"
                                                                textAlign="left"
                                                                fontSize="14px"
                                                                lineHeight="22px"
                                                                color="gray.700"
                                                            >
                                                                {testSoundPlaying
                                                                    ? "Playing..."
                                                                    : devices?.filter(
                                                                          (
                                                                              device,
                                                                          ) =>
                                                                              device.deviceId ===
                                                                              audioInputDevice,
                                                                      )?.[0]
                                                                          ?.label}
                                                            </Text>
                                                        </Flex>
                                                        <Box alignSelf="center">
                                                            <ChevronDownIcon
                                                                height="16px"
                                                                color="gray.700"
                                                            />
                                                        </Box>
                                                    </Flex>
                                                </MenuButton>
                                                <MenuList
                                                    minWidth="240px"
                                                    zIndex="2402"
                                                    maxH="410px"
                                                    overflowY="auto"
                                                >
                                                    <MenuOptionGroup
                                                        defaultValue={
                                                            audioInputDevice ??
                                                            devices?.find(
                                                                (device) =>
                                                                    device.kind ===
                                                                    "audioinput",
                                                            )?.deviceId ??
                                                            "default"
                                                        }
                                                        title="Select Microphone"
                                                        type="radio"
                                                        {...menuStyles.menuOptionGroup}
                                                    >
                                                        {devices
                                                            ?.filter(
                                                                (device) =>
                                                                    device.kind ===
                                                                    "audioinput",
                                                            )
                                                            .map((device) => (
                                                                <MenuItemOption
                                                                    key={`${device.deviceId}`}
                                                                    value={
                                                                        device.deviceId
                                                                    }
                                                                    onClick={() => {
                                                                        setAudioInputDevice(
                                                                            device.deviceId,
                                                                        );
                                                                    }}
                                                                    {...menuStyles.menuItem}
                                                                >
                                                                    {
                                                                        device.label
                                                                    }
                                                                </MenuItemOption>
                                                            ))}
                                                    </MenuOptionGroup>
                                                    <MenuDivider />
                                                    <MenuOptionGroup
                                                        title="Microphone Level"
                                                        {...menuStyles.menuOptionGroup}
                                                    >
                                                        <MenuItem
                                                            icon={
                                                                <MicrophoneIcon height="16px" />
                                                            }
                                                            _hover={{
                                                                background:
                                                                    "white",
                                                                cursor: "default",
                                                            }}
                                                            isFocusable={false}
                                                            {...menuStyles.menuItem}
                                                            pr="16px"
                                                        >
                                                            <Progress
                                                                opacity=".75"
                                                                rounded="10px"
                                                                alignSelf={
                                                                    "center"
                                                                }
                                                                colorScheme={
                                                                    "positive"
                                                                }
                                                                value={
                                                                    audioLevel
                                                                }
                                                                max={10}
                                                                size="sm"
                                                                display={
                                                                    "inline-block"
                                                                }
                                                                width="100%"
                                                            />
                                                        </MenuItem>
                                                    </MenuOptionGroup>
                                                    <MenuDivider />
                                                    <MenuOptionGroup
                                                        title="Speaker"
                                                        type="radio"
                                                        value="default"
                                                        {...menuStyles.menuOptionGroup}
                                                    >
                                                        <Tooltip
                                                            key="system_default_speaker"
                                                            label="To change your speaker, go to your computer's sound settings."
                                                            hasArrow
                                                            placement="top"
                                                        >
                                                            <MenuItemOption
                                                                isDisabled
                                                                {...menuStyles.menuItem}
                                                            >
                                                                System Default
                                                                Speaker Device
                                                            </MenuItemOption>
                                                        </Tooltip>
                                                    </MenuOptionGroup>
                                                    <MenuDivider />
                                                    <MenuItem
                                                        icon={
                                                            <SpeakerWaveIcon height="16px" />
                                                        }
                                                        onClick={() => {
                                                            testSound.play();
                                                        }}
                                                        {...menuStyles.menuItem}
                                                    >
                                                        {testSoundPlaying
                                                            ? "Playing..."
                                                            : "Play a test sound"}
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Flex>

                                        <Flex
                                            flexDirection="column"
                                            gap="5px"
                                            width="49%"
                                        >
                                            <Text
                                                fontSize="sm"
                                                fontWeight="semibold"
                                            >
                                                Video
                                            </Text>
                                            <Menu>
                                                <MenuButton
                                                    borderWidth="1px"
                                                    borderColor="gray.100"
                                                    borderRadius="6px"
                                                >
                                                    <Flex
                                                        height="34px"
                                                        padding="6px 10px 6px 8px"
                                                        justifyContent="space-between"
                                                        gap="12px"
                                                    >
                                                        <Text
                                                            height="34px"
                                                            whiteSpace="nowrap"
                                                            overflow={"hidden"}
                                                            textOverflow="ellipsis"
                                                            textAlign="left"
                                                            fontSize="14px"
                                                            lineHeight="22px"
                                                            color="gray.700"
                                                        >
                                                            {
                                                                devices?.filter(
                                                                    (device) =>
                                                                        device.deviceId ===
                                                                        videoDevice,
                                                                )?.[0]?.label
                                                            }
                                                        </Text>
                                                        <Box alignSelf="center">
                                                            <ChevronDownIcon
                                                                height="16px"
                                                                color="gray.700"
                                                            />
                                                        </Box>
                                                    </Flex>
                                                </MenuButton>
                                                <MenuList
                                                    minWidth="240px"
                                                    maxH="410px"
                                                    overflowY="auto"
                                                >
                                                    <MenuOptionGroup
                                                        defaultValue={
                                                            videoDevice ??
                                                            devices?.find(
                                                                (device) =>
                                                                    device.kind ===
                                                                    "videoinput",
                                                            )?.deviceId ??
                                                            "default"
                                                        }
                                                        title="Select Camera"
                                                        type="radio"
                                                        {...menuStyles.menuOptionGroup}
                                                    >
                                                        {devices
                                                            ?.filter(
                                                                (device) =>
                                                                    device.kind ===
                                                                    "videoinput",
                                                            )
                                                            .map((device) => (
                                                                <MenuItemOption
                                                                    key={
                                                                        device.deviceId
                                                                    }
                                                                    value={
                                                                        device.deviceId
                                                                    }
                                                                    onClick={() => {
                                                                        setVideoDevice(
                                                                            device.deviceId,
                                                                        );
                                                                    }}
                                                                    {...menuStyles.menuItem}
                                                                >
                                                                    {
                                                                        device.label
                                                                    }
                                                                </MenuItemOption>
                                                            ))}
                                                    </MenuOptionGroup>
                                                </MenuList>
                                            </Menu>
                                        </Flex>
                                    </Flex>
                                )}
                                <Flex
                                    flexDirection="column"
                                    marginTop="40px"
                                    gap="12px"
                                    width="300px"
                                >
                                    <Button
                                        ref={buttonRef}
                                        colorScheme="dark"
                                        background="gray.900"
                                        borderRadius="8px"
                                        isDisabled={
                                            !audioTrack ||
                                            !videoTrack ||
                                            !account.data
                                        }
                                        isLoading={joinVideoRoomLoading}
                                        fontSize="14px"
                                        fontWeight="600"
                                        onClick={async () => {
                                            setJoinVideoRoomLoading(true);
                                            await joinVideoRoom(
                                                data || "",
                                                account.data?.organization
                                                    .id !== ZETA_SCHOOLS_ID,
                                            );
                                            setJoinVideoRoomLoading(false);
                                            videoTrack?.stop();
                                            audioTrack?.stop();
                                        }}
                                    >
                                        Join video
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            Sentry.captureMessage(
                                                "in-person-1",
                                            );
                                            localStorage.setItem(
                                                `NoVideo-${id}`,
                                                "true",
                                            );
                                            onClose();
                                        }}
                                        variant="outline"
                                        borderRadius="8px"
                                        fontSize="14px"
                                        fontWeight="500"
                                        colorScheme="gray"
                                        background="white"
                                    >
                                        We are joining in person (not over
                                        video)
                                    </Button>
                                </Flex>
                            </Flex>
                            <Divider />
                            <Flex
                                direction="column"
                                alignItems="center"
                                padding={`20px 40px ${isRecorded ? "12px" : "20px"} 40px`}
                                gap="12px"
                            >
                                <Flex direction="column" alignItems="center">
                                    <Text
                                        fontFamily="Inter"
                                        fontSize="16px"
                                        fontWeight="400"
                                        lineHeight="22px"
                                        color="dark.600"
                                    >
                                        Having trouble connecting?
                                    </Text>
                                    <Text
                                        fontFamily="Inter"
                                        fontSize="16px"
                                        fontWeight="400"
                                        lineHeight="22px"
                                        color="dark.600"
                                    >
                                        <Link
                                            color="blue.500"
                                            textDecoration="underline"
                                            fontWeight="600"
                                            onClick={() => {
                                                onClose();
                                                onPhoneModalOpen();
                                            }}
                                        >
                                            Dial in
                                        </Link>
                                        {" from a phone, or "}
                                        <Link
                                            color="blue.500"
                                            textDecoration="underline"
                                            fontWeight="600"
                                            onClick={() =>
                                                // @ts-ignore
                                                window.$chatwoot.toggle()
                                            }
                                        >
                                            request live support.
                                        </Link>
                                    </Text>
                                </Flex>
                                {isRecorded && (
                                    <Flex alignItems="flex-start">
                                        <Text
                                            fontFamily="Inter"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="22px"
                                            color="dark.400"
                                        >
                                            This session will be recorded.
                                        </Text>
                                        <Tooltip label={TOOLTIP_TEXT}>
                                            <InformationCircleIcon
                                                stroke="#C2C5CC"
                                                height="16px"
                                            />
                                        </Tooltip>
                                    </Flex>
                                )}
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <ConnectByPhoneModal
                    isOpen={isPhoneModalOpen}
                    onClose={onPhoneModalClose}
                    isChime={false}
                    onSuccess={() => {}}
                />
                <ConnectionErrorModal
                    isOpen={errorOpen}
                    onClose={onErrorClose}
                    error={error}
                />
            </>
        );
    },
);
