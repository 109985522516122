import {
    Box,
    Flex,
    FlexProps,
    HStack,
    Image,
    Link,
    Text,
    VStack,
} from "@chakra-ui/react";
import {
    ArrowDownTrayIcon,
    ArrowLeftIcon,
    ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import React, { PropsWithChildren, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppRouterOutput } from "server/src/trpc";
import { Loading } from "../../Loading";
import FallbackRecapImage from "../../assets/default_recap_square.png";
import { useTRPC } from "../../hooks/useTRPC";
import { getCookie, ParamsProps } from "../../utils";
import Facepile from "../Facepile";
import { Header, Tabs } from "../Header";
import { SuccessfulCompletionModal } from "./SuccessfulCompletionModal";

type Resource = AppRouterOutput["sessions"]["recap"]["resources"][0];

export const Recap = (props: ParamsProps) => {
    const [searchParams] = useSearchParams();
    const resourceId = searchParams.get("resource_id");
    const { id: sessionId } = useParams();
    const navigate = useNavigate();
    const trpc = useTRPC();
    const appRootURL = process.env.REACT_APP_URL!;

    const { data: immersion, isLoading } = trpc.sessions.recap.useQuery({
        sessionId,
    });

    const handleDownload = async (resource: {
        resourceUri: string | null;
        name: string;
    }) => {
        fetch(
            process.env.REACT_APP_API_ROOT! +
                `/app/resource/${resource.resourceUri!}`,
            {
                credentials: "include",
                headers: {
                    Authorization: `Bearer ${getCookie(
                        document.cookie,
                        "auth0Token",
                    )}`,
                },
            },
        )
            .then(async (response) => {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const baseUrl = "blob:" + appRootURL;
                if (!url.startsWith(baseUrl)) {
                    throw new Error(`Invalid URL: ${url}`);
                }
                window.open(url, "_blank") || window.location.assign(url);
            })
            .catch((error) => {
                console.error("Error downloading resource", error);
            });
    };
    const hasDownloadableResources = !!immersion?.resources.length;

    const showModal = "success" in props.params;

    useEffect(() => {
        if (!hasDownloadableResources || !immersion) {
            return;
        }
        const resource = immersion.resources.find((_) => _.id === resourceId);
        if (!resource) {
            return;
        }
        handleDownload({
            resourceUri: resource.resourceUri,
            name: resource.name,
        });
    }, [hasDownloadableResources, immersion, resourceId]);

    if (isLoading || !immersion) return <Loading></Loading>;

    return (
        <Flex flexDir="column" padding="20px">
            {showModal && <SuccessfulCompletionModal />}

            <Header page={Tabs.None} />
            <Box maxWidth={"6xl"} margin="auto">
                <VStack alignItems="flex-start" mx={[0, null, "12"]} gap="3">
                    <HStack
                        color="gray.600"
                        padding="8px 12px"
                        borderRadius="8px"
                        _hover={{
                            color: "gray.900",
                            bg: "gray.background",
                            cursor: "pointer",
                        }}
                        onClick={() => navigate(`/reviews`)}
                    >
                        <ArrowLeftIcon width="12" display="inline" />
                        <Text fontSize="sm">All Past Sessions</Text>
                    </HStack>
                    <Text fontSize="2xl" fontWeight="semibold" height="unset">
                        {`${immersion?.topic.name}:
                                                    ${immersion?.tactic}`}
                    </Text>
                    <Flex
                        width="full"
                        gap="2"
                        justifyContent={[null, null, "space-between"]}
                        direction={"row"}
                    >
                        <HStack
                            minWidth="300px"
                            maxWidth="350px"
                            justifyContent="space-between"
                        >
                            <Flex gap="10px">
                                <Text
                                    fontSize="14px"
                                    color="#0F0F0F"
                                    fontWeight="500"
                                >
                                    Completed
                                </Text>
                                <Text
                                    fontWeight="400"
                                    fontSize="14px"
                                    color="#0F0F0F"
                                >
                                    {DateTime.fromISO(
                                        immersion.time ||
                                            new Date().toISOString(),
                                    ).toLocaleString(DateTime.DATE_SHORT)}
                                </Text>
                            </Flex>
                            <Flex alignItems="center">
                                <Text
                                    fontSize="14px"
                                    color="#0F0F0F"
                                    mr="2"
                                    fontWeight="500"
                                >
                                    Team
                                </Text>
                                <Facepile
                                    users={
                                        immersion?.participants.map(
                                            (x: any) => x.user,
                                        ) ?? []
                                    }
                                />
                            </Flex>
                        </HStack>
                    </Flex>
                    <Flex
                        direction={{ base: "column" }}
                        gap="40px"
                        paddingX="30px"
                        marginTop="15px"
                        width="100%"
                    >
                        <Flex flexDir="column" flexGrow="4">
                            <Text
                                fontSize="18px"
                                fontWeight="600"
                                alignSelf="flex-start"
                                marginBottom="12px"
                            >
                                Recap
                            </Text>
                            <Flex
                                direction={{
                                    base: "column",
                                    lg: "row",
                                }}
                                gap="4"
                                p="4"
                                borderRadius="12px"
                                borderColor="gray.100"
                                borderWidth="thin"
                                shadow="sm"
                            >
                                <Flex
                                    width={"360px"}
                                    minWidth={"300px"}
                                    justifyContent="center"
                                    alignItems="center"
                                    borderColor="gray.50"
                                    borderRadius="8px"
                                    borderWidth="1px"
                                    borderStyle="solid"
                                    alignSelf="flex-start"
                                >
                                    <Image
                                        alignSelf="center"
                                        width={"360px"}
                                        src={
                                            immersion?.coverImageUrl ??
                                            FallbackRecapImage
                                        }
                                        alt={""}
                                    />
                                </Flex>
                                <VStack alignItems="start" id="recap-markdown">
                                    <ReactMarkdown className="exclude-from-reset richtext">{`${immersion?.memento}`}</ReactMarkdown>
                                </VStack>
                            </Flex>
                        </Flex>
                        {hasDownloadableResources && (
                            <Flex
                                flexGrow="2"
                                flexDir="column"
                                gap="12px"
                                maxWidth="400px"
                                width={{ lg: "600px" }}
                            >
                                <Text
                                    fontSize="18px"
                                    fontWeight="600"
                                    alignSelf="flex-start"
                                >
                                    Additional resources
                                </Text>
                                {immersion.resources.map((resource) => {
                                    if (
                                        resource.baseResource.resourceType ===
                                            "EXTERNAL_LINK" &&
                                        resource.resourceUri
                                    ) {
                                        return (
                                            <ExternalLinkResource
                                                key={resource.id}
                                                resource={resource}
                                                uri={resource.resourceUri}
                                            />
                                        );
                                    }
                                    return (
                                        <DownloadableResource
                                            key={resource.id}
                                            resource={resource}
                                            onClick={() =>
                                                handleDownload(resource)
                                            }
                                        />
                                    );
                                })}
                            </Flex>
                        )}
                    </Flex>
                </VStack>
            </Box>
        </Flex>
    );
};

const ResourceItem: React.FC<PropsWithChildren & FlexProps> = (props) => (
    <Flex
        padding="8px 12px"
        borderRadius="8px"
        borderColor="gray.50"
        borderWidth="1px"
        borderStyle="solid"
        backgroundColor="gray.25"
        flexDir="row"
        justifyContent={"space-between"}
        cursor={"pointer"}
        {...props}
    >
        {props.children}
    </Flex>
);

const DownloadableResource: React.FC<{
    resource: Resource;
    onClick?: () => void;
}> = ({ resource, onClick }) => (
    <ResourceItem onClick={onClick}>
        <Text fontSize="14px" fontWeight="600" color="blue.500">
            {resource.name}
        </Text>
        <ArrowDownTrayIcon height="15px"></ArrowDownTrayIcon>
    </ResourceItem>
);

const ExternalLinkResource: React.FC<{
    resource: Resource;
    uri: string;
}> = ({ resource, uri }) => (
    <Link href={uri} target="_blank">
        <ResourceItem>
            <Text fontSize="14px" fontWeight="600" color="blue.500">
                {resource.name}
            </Text>
            <ArrowTopRightOnSquareIcon height="15px"></ArrowTopRightOnSquareIcon>
        </ResourceItem>
    </Link>
);
