import { useEffect } from "react";
import { useStepContent } from "../hooks/useSessionContent";
import { ImageResource } from "./resources/ImageResource";
import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    useDisclosure,
} from "@chakra-ui/react";

export const ViewSampleAnswer = () => {
    const { resources } = useStepContent();
    const resource1 = resources?.resource1?.[0];
    const imageUrl = resource1?.url ?? "";
    const imageAlt = resource1?.name ?? "";
    const { isOpen, onOpen, onClose } = useDisclosure();
    let fadeEdges = isOpen ? true : false;

    useEffect(() => {
        onOpen();
    }, []);

    return (
        <>
            <ImageResource
                border
                fade={fadeEdges}
                url={imageUrl}
                altText={imageAlt}
                fullscreen
            />

            <Flex
                h="100%"
                w="100%"
                position="absolute"
                visibility={isOpen ? "visible" : "hidden"}
            >
                <Box
                    w="100%"
                    bg="whiteAlpha.500"
                    backdropFilter="blur(10px)"
                    position="relative"
                />

                <Center position="absolute" w="100%" h="100%">
                    <Flex direction="column" alignItems="center">
                        <Heading
                            size="lg"
                            p={4}
                            lineHeight="1.3"
                            textAlign="center"
                            color="black"
                            fontFamily="Proxima Nova"
                        >
                            Reveal sample answers after you have finished the
                            previous step.
                        </Heading>
                        <Button colorScheme="blue" mt={4} onClick={onClose}>
                            Reveal answers
                        </Button>
                    </Flex>
                </Center>
            </Flex>
        </>
    );
};
