import {
    Button,
    Flex,
    HStack,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    SimpleGrid,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useTRPC } from "../hooks/useTRPC";

import { DateTime } from "luxon";
import { useState } from "react";
import Facepile from "./Facepile";
import { extractImmersionTitle } from "../home/utils";
import { Link } from "react-router-dom";
import FallbackRecapImage from "../assets/default_recap_square.png";
import { Header, Tabs } from "./Header";
import { useAppReady } from "../hooks/useAppReady";

export const CompletedSessions = () => {
    const trpc = useTRPC();
    const { data: completedSessions } =
        trpc.sessions.completedSessions.useQuery();

    const [searchTerm, setSearchTerm] = useState("");
    const [hoveredCard, setHoveredCard] = useState<string | null>(null);
    const guard = useAppReady();
    const sessionsMatchingSearchTerm = completedSessions?.filter(
        (session: any) => {
            return [
                session.immersion.tactic,
                session.immersion.topic.name,
                ...session.timeslotGroups[0].participants.flatMap(
                    (x: any) =>
                        `${x.user?.firstName} ${x.user.lastName} ${x.user.emails?.[0]?.email}`,
                ),
            ].some(
                (x) =>
                    searchTerm.length === 0 ||
                    x
                        ?.toLowerCase()
                        .toString()
                        ?.includes(searchTerm?.toLowerCase().toString()),
            );
        },
    );

    return (
        guard || (
            <Flex flexDir="column">
                <Header page={Tabs.ReviewsTab}></Header>
                <Flex
                    flexDir="column"
                    maxW="6xl"
                    margin="auto"
                    p="10"
                    width="100%"
                >
                    <HStack
                        width="full"
                        justifyContent="space-between"
                        display="flex"
                        flexWrap="wrap"
                        mb="2"
                    >
                        <Text fontSize="2xl" fontWeight="bold">
                            Past Sessions
                        </Text>
                        <InputGroup width="250px">
                            <Input
                                type="search"
                                placeholder="Search past sessions"
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                            />
                            <InputRightElement
                                children={
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.2656 14.1738L11.3398 10.248C12.1895 9.22266 12.6582 7.9043 12.6582 6.46875C12.6582 3.12891 9.9043 0.375 6.56445 0.375C3.19531 0.375 0.5 3.12891 0.5 6.46875C0.5 9.83789 3.22461 12.5625 6.56445 12.5625C7.9707 12.5625 9.28906 12.0938 10.3438 11.2441L14.2695 15.1699C14.416 15.3164 14.5918 15.375 14.7969 15.375C14.9727 15.375 15.1484 15.3164 15.2656 15.1699C15.5586 14.9062 15.5586 14.4668 15.2656 14.1738ZM1.90625 6.46875C1.90625 3.89062 3.98633 1.78125 6.59375 1.78125C9.17188 1.78125 11.2812 3.89062 11.2812 6.46875C11.2812 9.07617 9.17188 11.1562 6.59375 11.1562C3.98633 11.1562 1.90625 9.07617 1.90625 6.46875Z"
                                            fill="#2763BB"
                                        />
                                    </svg>
                                }
                            />
                        </InputGroup>
                    </HStack>
                    <SimpleGrid
                        columns={[1, null, 3, null, null]}
                        spacing="20px"
                    >
                        {sessionsMatchingSearchTerm?.map((session: any) =>
                            !session.time ? null : (
                                <Link
                                    to={`/reviews/${session.id}`}
                                    key={session.id}
                                >
                                    <VStack
                                        key={session?.id}
                                        borderColor="gray.100"
                                        borderRadius="12px"
                                        borderWidth="1px"
                                        dropShadow="dark-lg"
                                        justifyContent="space-between"
                                        width="323px"
                                        height="366px"
                                        p="8px"
                                        onMouseEnter={() =>
                                            setHoveredCard(session.id)
                                        }
                                        onMouseLeave={() =>
                                            setHoveredCard(null)
                                        }
                                        _hover={{
                                            cursor: "pointer",
                                            shadow: "lg",
                                        }}
                                    >
                                        <Flex
                                            width="full"
                                            height="232px"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Image
                                                alignSelf="center"
                                                height="100%"
                                                width="100%"
                                                objectFit="cover"
                                                borderColor="gray.50"
                                                borderRadius="8px"
                                                borderWidth="1px"
                                                borderStyle="solid"
                                                opacity={
                                                    hoveredCard === session.id
                                                        ? 0.6
                                                        : 1
                                                }
                                                src={
                                                    session?.immersion
                                                        .coverImageUrl ??
                                                    FallbackRecapImage
                                                }
                                                alt={""}
                                            />
                                            {hoveredCard === session.id && (
                                                <Flex
                                                    position="absolute"
                                                    width="307px"
                                                    height="232px"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Button
                                                        bg="blue.500"
                                                        color="white"
                                                        fontSize="md"
                                                        p="15px 20px"
                                                        _hover={{
                                                            bg: "blue.600",
                                                        }}
                                                    >
                                                        Read Recap
                                                    </Button>
                                                </Flex>
                                            )}
                                        </Flex>

                                        <Flex
                                            direction="column"
                                            p="2"
                                            justifyContent="space-between"
                                            height="full"
                                            width="full"
                                            gap="3"
                                        >
                                            <Text
                                                fontSize="md"
                                                fontWeight="semibold"
                                                height="unset"
                                            >
                                                {extractImmersionTitle(
                                                    session.immersion,
                                                )}
                                            </Text>

                                            <Flex
                                                direction="row"
                                                width="full"
                                                gap="4"
                                                alignItems="center"
                                            >
                                                <Text
                                                    fontSize="xs"
                                                    color="dark.400"
                                                    mr="2"
                                                >
                                                    <strong>Completed</strong>
                                                    &nbsp;
                                                    {DateTime.fromISO(
                                                        session.time ||
                                                            new Date().toISOString(),
                                                    ).toLocaleString(
                                                        DateTime.DATE_SHORT,
                                                    )}
                                                </Text>
                                                <Flex alignItems="center">
                                                    <Text
                                                        fontSize="xs"
                                                        color="dark.400"
                                                        mr="2"
                                                        fontWeight="bold"
                                                    >
                                                        Team
                                                    </Text>
                                                    <Facepile
                                                        users={session.timeslotGroups[0].participants.map(
                                                            (x: any) => x.user,
                                                        )}
                                                    />
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </VStack>
                                </Link>
                            ),
                        )}
                    </SimpleGrid>
                </Flex>
            </Flex>
        )
    );
};
