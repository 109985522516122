import { Flex, Image, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import VideoButtonOn from "design/assets/VideoButtonOn.svg";
import MicButtonOn from "design/assets/MicButtonOn.svg";

export const TourAudioVideoButtons = () => {
    return (
        <Flex
            w="100%"
            border="1px solid"
            borderRadius="6px"
            boxShadow="6px 6px 14px rgba(0, 0, 0, 0.04)"
            borderColor="gray.100"
            alignItems="center"
            justifyContent="center"
            p="4px"
            mb="4px"
            bgColor="white"
        >
            <Flex
                position="relative"
                direction="column"
                w="100%"
                h="100%"
                p="2px"
                alignItems="center"
                justifyContent="center"
            >
                <Flex position="absolute" top="2px" right="2px" padding="4px">
                    <ChevronDownIcon height="14px" />
                </Flex>

                <Image h="20px" src={MicButtonOn} alt="Example icon for mute/unmute microphone"/>
                <Text
                    fontFamily="Inter"
                    fontSize="10px"
                    fontWeight="400"
                    lineHeight="12px"
                    textAlign="center"
                    mt="4px"
                >
                    Mute
                </Text>
            </Flex>

            <Flex
                position="relative"
                direction="column"
                w="100%"
                h="100%"
                p="2px"
                justifyContent="center"
                alignItems="center"
            >
                <Flex position="absolute" top="2px" right="2px" padding="4px">
                    <ChevronDownIcon height="14px" />
                </Flex>

                <Image h="20px" src={VideoButtonOn} alt="Example icon for stop/show video" />
                <Text
                    fontFamily="Inter"
                    fontSize="10px"
                    fontWeight="400"
                    lineHeight="12px"
                    textAlign="center"
                    mt="4px"
                >
                    Stop Video
                </Text>
            </Flex>
        </Flex>
    );
};
