import { useOwnResources, useStepContent } from "../hooks/useSessionContent";
import { useSelfData } from "../hooks/useImmersion";
import { Flex } from "@chakra-ui/react";
import { IFrameResource } from "./resources/IFrameResource";
import { RichtextResource } from "./resources/RichtextResource";

export const SplitRichtextEtherpad = () => {
    const { resources } = useStepContent();
    const ownResources = useOwnResources();
    const self = useSelfData();
    const richtext = resources?.resource1?.[0];
    const etherpad = ownResources?.resource2;
    const altText = richtext?.altText ?? "";
    const {
        url: etherpadBaseUrl = "",
        name: etherpadName = "",
        usageInSession: etherpadUsageInSession = "",
        systemId: etherpadSystemId = "",
    } = etherpad ?? {};
    const participantName = self?.firstName ?? "";

    const etherpadUrl = `${etherpadBaseUrl}/${etherpadSystemId}?showControls=true&showChat=false&showLineNumbers=true&useMonospaceFont=false&userName=${participantName}&theme=sparkwise`;
    return (
        <Flex w="100%" gap="16px" justifyContent="center">
            <RichtextResource altText={altText} />
            <IFrameResource
                url={etherpadUrl}
                name={etherpadName}
                headerType={
                    etherpadUsageInSession === "individualized"
                        ? "solo"
                        : "group"
                }
                resourceType="etherpad"
            />
        </Flex>
    );
};
