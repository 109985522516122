import {
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    Text,
    ModalOverlay,
    Flex,
    Button,
} from "@chakra-ui/react";
import React from "react";

export const InPersonConfirmationModal: React.FC<{
    open: boolean;
    onCancel: () => void;
    onContinue: () => void;
}> = (params) => {
    return (
        <Modal isOpen={params.open} onClose={params.onCancel}>
            <ModalOverlay>
                <ModalContent maxWidth="650px">
                    <ModalBody>
                        <Heading fontSize={"22px"} mb={"20px"}>
                            You're entering in-person mode
                        </Heading>
                        <Text mb={"40px"}>
                            This option is for groups that have everyone in the
                            same room. If your group is remote, press cancel and
                            join video instead.
                        </Text>
                        <Flex gap={6} justifyContent={"flex-end"}>
                            <Button variant="ghost" onClick={params.onCancel}>
                                Cancel
                            </Button>
                            <Button
                                onClick={params.onContinue}
                                colorScheme="dark"
                                background="gray.900"
                                borderRadius="8px"
                                fontSize="14px"
                                fontWeight="600"
                            >
                                Continue
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </ModalOverlay>
        </Modal>
    );
};
