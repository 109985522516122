import { useAuth0 } from "@auth0/auth0-react";
import SparkwiseLogo from "design/assets/Sparkwise.svg";
import { Button, Flex, Image, Link, Stack, Text } from "@chakra-ui/react";

export const AccountNotFound = () => {
    const { logout, user } = useAuth0();
    const email = user?.email;

    return (
        <Flex direction="column" m="0 8%">
            <Flex height="100px">
                <Stack direction="row" spacing="9px" alignItems="center">
                    <Image src={SparkwiseLogo} />
                    <Text
                        fontFamily="Griff"
                        fontSize="32px"
                        fontWeight="semibold"
                        pb="5px"
                    >
                        sparkwise
                    </Text>
                </Stack>
            </Flex>
            <Flex flex="1" direction="column" align="center">
                <Flex flex="1 1 20%" />
                <Flex direction="column" maxW="586px" p="60px" pb="68px">
                    <Text
                        fontFamily="Proxima Nova"
                        fontSize="32px"
                        fontWeight="semibold"
                        lineHeight="40px"
                        color="dark.800"
                        mb="20px"
                    >
                        Sorry, {email}
                    </Text>

                    <Flex>
                        <Text
                            fontFamily="Proxima Nova"
                            fontSize="16px"
                            lineHeight="19px"
                            color="dark.700"
                        >
                            We could not find an account matching this email
                            <Text
                                as="span"
                                fontFamily="Proxima Nova"
                                fontSize="16px"
                                fontWeight="semibold"
                                lineHeight="19px"
                                color="dark.700"
                            >
                                {` (${email}) `}
                            </Text>
                            Check that the email is correct, and reach out to the session organizer to make sure your account has been created
                        </Text>
                    </Flex>

                    <Button
                        mt="56px"
                        mb="24px"
                        p="12px 16px"
                        bgColor="blue.500"
                        _hover={{ bgColor: "blue.600" }}
                        onClick={() => {
                            logout({
                                logoutParams: {
                                    returnTo: window.location.origin,
                                },
                            });
                        }}
                    >
                        <Text
                            fontFamily="Proxima Nova"
                            fontSize="16px"
                            lineHeight="150%"
                            color="white"
                        >
                            Try a Different Email
                        </Text>
                    </Button>

                    <Flex>
                        <Text
                            fontFamily="Proxima Nova"
                            fontSize="14px"
                            lineHeight="150%"
                            color="gray.12"
                            textAlign="center"
                        >
                            Otherwise, you can reach us by email at
                            <Text
                                as="span"
                                fontFamily="Proxima Nova"
                                fontSize="14px"
                                lineHeight="150%"
                                color="blue.500"
                                _hover={{
                                    cursor: "pointer",
                                }}
                            >
                                {" "}
                                <Link href="mailto:support@sparkwise.co">
                                    support@sparkwise.co
                                </Link>
                            </Text>
                            {` or `}
                            <Text
                                as="span"
                                fontFamily="Proxima Nova"
                                fontSize="14px"
                                lineHeight="150%"
                                color="blue.500"
                                _hover={{
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    //@ts-ignore
                                    window.$chatwoot.toggle();
                                }}
                            >
                                live-chat with us
                            </Text>
                        </Text>
                    </Flex>
                </Flex>
                <Flex flex="1 1 80%" />
            </Flex>
        </Flex>
    );
};
