import { useStepContent } from "../hooks/useSessionContent";
import { VideoResource } from "./resources/VideoResource";

export const FullScreenVideo = () => {
    const { resources, variables } = useStepContent();
    const resource1 = resources?.resource1?.[0];
    const captions = resource1?.altText || "";
    const systemId = resource1?.systemId || "";
    const optionalHeaderText = variables?.variable1 || null;

    return (
        <VideoResource
            systemId={systemId}
            captions={captions}
            {...(optionalHeaderText && { optionalHeaderText })}
            fullscreen
        />
    );
};
