import { AspectRatio, Box, Flex, Image, Text } from "@chakra-ui/react";
import BlankAvatarWhite from "design/assets/BlankAvatarWhite.svg";

export const TourParticipant = ({
    firstName,
    background,
    width,
}: {
    firstName: string;
    background: string;
    width: number;
}) => {
    return (
        <AspectRatio
            w={`${width}px`}
            minH="75px"
            bg={background}
            zIndex={1}
            ratio={3.5 / 3}
            marginY={"4px"}
            borderRadius={"8px"}
            overflow={"hidden"}
        >
            <Box>
                <Image
                    w="40%"
                    opacity="0.4"
                    src={BlankAvatarWhite}
                    alt="Example user avatar"
                />
                <Flex
                    borderRadius="4px"
                    bg="rgba(0,0,0,.5)"
                    paddingX="5px"
                    color="white"
                    fontSize="sm"
                    left="4px"
                    bottom="4px"
                    position="absolute"
                    zIndex={10}
                >
                    <Text>{firstName}</Text>
                </Flex>
            </Box>
        </AspectRatio>
    );
};
