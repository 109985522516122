import { useStepContent } from "../hooks/useSessionContent";
import { RichtextResource } from "./resources/RichtextResource";

export const RichtextBox = () => {
    const { resources } = useStepContent();
    const resource1 = resources?.resource1?.[0];
    const altText = resource1?.altText ?? "";

    return (
        <RichtextResource altText={altText} fullscreen />
    );
};
