import { AudioTrack as TwilioAudioTrack } from "twilio-video";
import { useEffect } from "react";

export const AudioTrack = ({
    track,
    setAudioOn,
}: {
    track: TwilioAudioTrack;
    setAudioOn: (audio: boolean) => void;
}) => {
    useEffect(() => {
        setAudioOn(track.isEnabled);
        const audioBaseElement = document.getElementById(
            "twilio-audio-elements",
        );
        if (audioBaseElement) {
            // check if the track is already there, then remove it
            audioBaseElement.querySelectorAll('audio[id="'+track.name+'"').forEach((x) => {x.remove()});
            const audioElement = track.attach();
            audioElement.id = track.name;
            audioBaseElement.appendChild(audioElement);
        }
    }, [track, setAudioOn]);

    useEffect(() => {
        const audioOn = () => setAudioOn(true);
        const audioOff = () => setAudioOn(false);
        track.on("enabled", audioOn);
        track.on("disabled", audioOff);
        return () => {
            track.off("enabled", audioOn);
            track.off("disabled", audioOff);
        };
    }, [track, setAudioOn]);
    return null;
};
