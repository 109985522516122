import {
    Box,
    Button,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import LockUrlBar from "design/assets/LockUrlBar.png";
import FirefoxUrlBar from "design/assets/FirefoxUrlBar.png";
import FirefoxIcons from "design/assets/FirefoxIcons.svg";
import SafariPermissions from "design/assets/SafariPermissions.png";
import { ConnectByPhoneModal } from "./ConnectByPhoneModal";

export type ErrorModalData = {
    title: string;
    message: string[];
    permissionsError: boolean;
};

const CHROME_ERROR_DIV = (
    <Grid
        templateRows="repeat(3)"
        templateColumns="repeat(8, 1fr)"
        gap="20px 10px"
    >
        <GridItem>
            <b>Step 1</b>
        </GridItem>
        <GridItem colSpan={7}>
            Click the lock icon on the left side of your browser's address bar.
            <Image
                h="30px"
                marginTop="7px"
                src={LockUrlBar}
                alt={"A lock icon next to the website address"}
            />
        </GridItem>
        <GridItem>
            <b>Step 2</b>
        </GridItem>
        <GridItem colSpan={7}>
            In the menu that pops up, set both <b>Camera</b> and{" "}
            <b>Microphone</b> to "<b>Allow</b>."
        </GridItem>
        <GridItem>
            <b>Step 3</b>
        </GridItem>
        <GridItem colSpan={7}>Refresh this page.</GridItem>
    </Grid>
);
const FIREFOX_ERROR_DIV = (
    <Grid
        templateRows="repeat(3)"
        templateColumns="repeat(8, 1fr)"
        gap="20px 10px"
    >
        <GridItem>
            <b>Step 1</b>
        </GridItem>
        <GridItem colSpan={7}>
            <Text display="inline-block" verticalAlign="top">
                In your browser's address bar, click on the{" "}
            </Text>
            <Image display="inline-block" h="20px" src={FirefoxIcons} />
            <Text display="inline" verticalAlign="top">
                {" "}
                icon to the left of the app.sparkwise.co web address.
            </Text>
            <Image
                borderRadius="8px"
                marginTop="7px"
                h="150px"
                src={FirefoxUrlBar}
            />
        </GridItem>
        <GridItem>
            <b>Step 2</b>
        </GridItem>
        <GridItem colSpan={7}>
            Click the <b>block temporarily</b> tag for both microphone and
            camera to remove it.
        </GridItem>
        <GridItem>
            <b>Step 3</b>
        </GridItem>
        <GridItem colSpan={7}>
            Refresh this page and click "<b>Allow</b>" when prompted.
        </GridItem>
    </Grid>
);
const SAFARI_ERROR_DIV = (
    <Grid templateColumns="repeat(8, 1fr)" gap="20px 10px">
        <GridItem>
            <b>Step 1</b>
        </GridItem>
        <GridItem colSpan={7}>
            In the menu bar at the top of the screen, click{" "}
            <Text display="inline-block" fontWeight={"bold"}>
                Safari -&gt; Settings...
            </Text>{" "}
        </GridItem>
        <GridItem>
            <b>Step 2</b>
        </GridItem>
        <GridItem colSpan={7}>
            In the menu that pops up, click on <b>Websites</b>. Then click on{" "}
            <b>Camera</b> (or microphone) to find app.sparkwise.co in the list
            of websites.
            <Image marginTop="7px" height="150px" src={SafariPermissions} />
        </GridItem>
        <GridItem>
            <b>Step 3</b>
        </GridItem>
        <GridItem colSpan={7}>
            Select "<b>Allow</b>" for app.sparkwise.co for both Camera and
            Microphone.
        </GridItem>
        <GridItem>
            <b>Step 4</b>
        </GridItem>
        <GridItem colSpan={7}>Refresh this page.</GridItem>
    </Grid>
);

export const ConnectionErrorModal = ({
    isOpen,
    onClose,
    error,
    isChime,
}: {
    isOpen: boolean;
    onClose: () => void;
    error: ErrorModalData | null;
    isChime?: boolean;
}) => {
    const {
        isOpen: isPhoneModalOpen,
        onOpen: onPhoneModalOpen,
        onClose: onPhoneModalClose,
    } = useDisclosure();

    const extraHelpText = (
        <Text marginTop="20px" fontSize="sm" color="dark.400">
            If you need more help,
            <Link
                color="blue.500"
                // @ts-ignore
                onClick={() => window.$chatwoot.toggle()}
            >
                {" "}
                request live support,
            </Link>{" "}
            or{" "}
            <Link
                color="blue.500"
                onClick={() => {
                    onClose();
                    onPhoneModalOpen();
                }}
            >
                dial in
            </Link>{" "}
            from a phone.
        </Text>
    );

    const errorModal = (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
            <ModalOverlay></ModalOverlay>
            <ModalContent>
                <ModalHeader>
                    <Heading fontWeight="bold" fontSize="2xl">
                        {error?.title}
                    </Heading>
                </ModalHeader>
                <ModalCloseButton></ModalCloseButton>
                <ModalBody>
                    {error?.message.map((message) => (
                        <Text fontSize="sm" marginBottom="10px">
                            {message}
                        </Text>
                    ))}
                    {extraHelpText}
                </ModalBody>
                <ModalFooter>
                    <Flex gap={"10px"} flexDirection="row">
                        <Button
                            onClick={() => {
                                window.location.reload();
                            }}
                            variant={"black"}
                            fontSize="sm"
                        >
                            Reload Page
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
    const permissionsErrorModal = (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="xl"
            closeOnOverlayClick={false}
        >
            <ModalOverlay></ModalOverlay>
            <ModalContent>
                <ModalHeader>
                    <Heading
                        fontWeight="bold"
                        fontSize="22px"
                        textAlign="center"
                    >
                        Your browser needs access to your camera and microphone
                    </Heading>
                </ModalHeader>
                <ModalBody>
                    <Text fontSize="14px" marginBottom="10px">
                        Follow these steps to allow access to your camera and
                        microphone:
                    </Text>
                    <Box
                        bg="blue.50"
                        borderRadius="20px"
                        padding="20px"
                        fontSize="14px"
                    >
                        {isFirefox()
                            ? FIREFOX_ERROR_DIV
                            : isSafari()
                              ? SAFARI_ERROR_DIV
                              : CHROME_ERROR_DIV}
                        {extraHelpText}
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Flex gap={"10px"} flexDirection="row">
                        <Button
                            onClick={() => {
                                window.location.reload();
                            }}
                            variant={"black"}
                            fontSize="sm"
                        >
                            Reload Page
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
    return (
        <>
            <ConnectByPhoneModal
                isOpen={isPhoneModalOpen}
                onClose={onPhoneModalClose}
                isChime={isChime || false}
                onSuccess={() => {}}
            />
            {error?.permissionsError ? permissionsErrorModal : errorModal}
        </>
    );
};

function isFirefox() {
    return navigator.userAgent.indexOf("Firefox") != -1;
}

function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
