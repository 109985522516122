import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStepContent } from "./useSessionContent";

export enum TimerState {
    Counting,
    AtTime,
    OverTime,
}

export const useTimer = () => {
    const { id, stepNumber } = useParams();
    const storageTimer =
        Number(localStorage.getItem(`Timer-${id}-${stepNumber}`)) || 0;
    const [timer, setTimer] = useState(storageTimer);

    useEffect(() => {
        setTimer(
            Number(localStorage.getItem(`Timer-${id}-${stepNumber}`)) || 0,
        );
    }, [stepNumber, id]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setTimer(timer + 1);
            localStorage.setItem(
                `Timer-${id}-${stepNumber}`,
                String(timer + 1),
            );
        }, 1000);

        return () => window.clearTimeout(timeoutId);
    }, [timer, setTimer]);
    return timer;
};

export const useTimerState = () => {
    const stepContent = useStepContent();
    const timer = useTimer();
    let timerState = TimerState.Counting;
    if (stepContent.duration) {
        timerState =
            timer > stepContent.duration + 30
                ? TimerState.OverTime
                : timer >= stepContent.duration
                ? TimerState.AtTime
                : TimerState.Counting;
    }
    return timerState;
};
