import { Button, Flex, Image, ToastId, useToast } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { videoRoom } from "../immersionState";
import { IMMERSION_SIZES } from "../Immersion";
import BadInternet from "design/assets/BadInternet.svg";
import { usePostHog } from "posthog-js/react";
import { throttle } from "../../utils";

export const NetworkQualityWarning = () => {
    const twilioRoom = useRecoilValue(videoRoom);
    const selfOnTwilio = twilioRoom?.localParticipant;
    const [networkQuality, setNetworkQuality] = useState(5);
    const toast = useToast();
    const toastIdRef = React.useRef<ToastId>();
    const [suppressWarning, setSuppressWarning] = useState(false);
    const posthog = usePostHog();

    useEffect(() => {
        selfOnTwilio?.on("networkQualityLevelChanged", setNetworkQuality);
        return () => {
            selfOnTwilio?.off("networkQualityLevelChanged", setNetworkQuality);
        };
    }, [selfOnTwilio, setNetworkQuality]);

    const handleSuppressWarning = () => {
        if(toastIdRef.current){
            toast.close(toastIdRef.current);
        }
        setSuppressWarning(true);
    
    };

    useEffect(throttle(() => {
        if (!suppressWarning && selfOnTwilio && networkQuality <= 1) {
            posthog.capture('network_quality_error', {
                "networkQuality": networkQuality
            });
            toastIdRef.current = toast({
                position: "top",
                render: () => (
                    <Flex
                        flexDir="row"
                        width="max-content"
                        background="rgba(60, 62, 68, .7)"
                        top={`calc(${IMMERSION_SIZES.HEADER_HEIGHT} + 4px)`}
                        position="relative"
                        padding="12px 8px"
                        borderRadius="4px"
                        color="white"
                        gap={2}
                    >
                        <Image src={BadInternet} mr="8px" />
                        <span className="popupText">
                            Your internet connection is unstable 
                        </span>
                        <Button onClick={handleSuppressWarning} variant='link' colorScheme='white' textDecoration='underline' fontWeight='normal'>Don't show again</Button>
                    </Flex>
                ),
            });
        }
    }, 600000), [networkQuality, selfOnTwilio, suppressWarning, setSuppressWarning, toastIdRef]);

    return null;
};
