import { MenuItemOption, MenuOptionGroup, Tooltip } from "@chakra-ui/react";
import { menuStyles } from "../JoinVideoModal";

export const SpeakerSelectorMenu = () => {
    return (
        <MenuOptionGroup
            title="Speaker"
            type="radio"
            value="default"
            {...menuStyles.menuOptionGroup}
        >
            <Tooltip
                key="system_default_speaker"
                label="To change your speaker, go to your computer's sound settings."
                hasArrow
                placement="top"
            >
                <MenuItemOption isDisabled {...menuStyles.menuItem}>
                    System Default Speaker Device
                </MenuItemOption>
            </Tooltip>
        </MenuOptionGroup>
    );
};
