import {
    useStepContent,
    useOwnResources,
    useStepType,
} from "../hooks/useSessionContent";
import { useParticipants, useSelfData } from "../hooks/useImmersion";
import { Flex, Text } from "@chakra-ui/react";
import { IFrameResource } from "./resources/IFrameResource";
import { ImageResource } from "./resources/ImageResource";
import { useEffect, useState } from "react";
import { HeaderType } from "./resources/ResourceHeader";
import { useParams } from "react-router-dom";

/*
  Used for step types:
    - Etherpad (editable) + Etherpad (editable)
    - Image (optional) + Etherpad (editable) + Etherpad (read-only)
    - Image (optional) + Nth participant Etherpad + viewer's Etherpad
*/

export const SplitEtherpadsOptionalImage = () => {
    const stepType = useStepType();
    const { resources, variables } = useStepContent();
    const ownResources = useOwnResources();
    const self = useSelfData();
    const participants = useParticipants();

    let optionalImage, leftEtherpad, rightEtherpad, optionalHeaderText;
    let leftName, leftUsageInSession, leftBaseUrl, leftSystemId;
    let rightName, rightUsageInSession, rightBaseUrl, rightSystemId;
    // headerType needs initial value, will be reset below based on step type
    let leftHeaderType: HeaderType = "solo";
    let rightHeaderType: HeaderType = "solo";

    const stepNumber = parseInt(
        useParams<{ stepNumber: string }>().stepNumber || "0",
    );
    const nthParticipantIndex =
        parseInt(variables?.variable1 ?? "0") % (participants?.length ?? 0);
    const [selectedParticipantIndex, setSelectedParticipantIndex] =
        useState(nthParticipantIndex);
    const selectedParticipant = participants[selectedParticipantIndex];

    // reset state between steps or when nth participant changes
    useEffect(() => {
        setSelectedParticipantIndex(nthParticipantIndex);
    }, [nthParticipantIndex, participants, stepNumber]);

    const updateSelectedParticipant = (participantId: string) => {
        const index =
            participants?.findIndex((participant) => {
                return participant?.id === participantId;
            }) ?? 0;
        setSelectedParticipantIndex(index);
    };

    const participantName = self?.firstName ?? "";
    const currentlyViewingSelf =
        selectedParticipant?.id === self?.sessionParticipantUuid;

    const extractEtherpadData = (etherpad: any, side: "left" | "right") => {
        if (side === "left") {
            leftName = etherpad?.name ?? "";
            leftUsageInSession = etherpad?.usageInSession ?? "";
            leftBaseUrl = etherpad?.url ?? "";
        } else {
            rightName = etherpad?.name ?? "";
            rightUsageInSession = etherpad?.usageInSession ?? "";
            rightBaseUrl = etherpad?.url ?? "";
        }
    };

    switch (stepType) {
        case "Etherpad (editable) + Etherpad (editable)":
            optionalImage = null;
            leftEtherpad = ownResources?.resource1;
            rightEtherpad = ownResources?.resource2;
            extractEtherpadData(leftEtherpad, "left");
            extractEtherpadData(rightEtherpad, "right");
            leftSystemId = leftEtherpad?.systemId;
            rightSystemId = rightEtherpad?.systemId;
            leftHeaderType =
                leftUsageInSession === "individualized" ? "solo" : "group";
            rightHeaderType =
                rightUsageInSession === "individualized" ? "solo" : "group";
            optionalHeaderText = variables?.variable1 ?? null;
            break;
        case "Image (optional) + Etherpad (editable) + Etherpad (read-only)":
            optionalImage = resources?.resource1?.[0] ?? null;
            leftEtherpad = ownResources?.resource2;
            rightEtherpad = ownResources?.resource3;
            extractEtherpadData(leftEtherpad, "left");
            extractEtherpadData(rightEtherpad, "right");
            leftSystemId = leftEtherpad?.systemId;
            rightSystemId = rightEtherpad?.readOnlySystemId;
            leftHeaderType =
                leftUsageInSession === "individualized" ? "solo" : "group";
            rightHeaderType =
                rightUsageInSession === "individualized"
                    ? "readonly solo"
                    : "readonly group";
            optionalHeaderText = null;
            break;
        case "Image (optional) + Nth participant Etherpad + viewer's Etherpad":
            optionalImage = resources?.resource2?.[0] ?? null;
            leftEtherpad = resources?.resource1?.[selectedParticipantIndex];
            rightEtherpad = ownResources?.resource1;
            extractEtherpadData(leftEtherpad, "left");
            extractEtherpadData(rightEtherpad, "right");
            leftSystemId = currentlyViewingSelf
                ? leftEtherpad?.systemId
                : leftEtherpad?.readOnlySystemId;
            rightSystemId = rightEtherpad?.systemId;
            leftHeaderType = "readonly nth dropdown";
            rightHeaderType =
                rightUsageInSession === "individualized" ? "solo" : "group";
            optionalHeaderText = null;
            break;
    }

    // Construct etherpad URLs
    const leftEtherpadUrl = `${leftBaseUrl}/${leftSystemId}?showControls=true&showChat=false&showLineNumbers=true&useMonospaceFont=false&userName=${participantName}&theme=default`;
    const rightEtherpadUrl = `${rightBaseUrl}/${rightSystemId}?showControls=true&showChat=false&showLineNumbers=true&useMonospaceFont=false&userName=${participantName}&theme=default`;

    // TODO: what if the readonly etherpad is a shared one instead of self?
    const currentlyViewing =
        leftHeaderType === "readonly nth dropdown"
            ? {
                  displayName: currentlyViewingSelf
                      ? "You"
                      : selectedParticipant?.user?.firstName,
                  avatarName: `${selectedParticipant?.user?.firstName} ${selectedParticipant?.user?.lastName}`,
                  avatarUrl: selectedParticipant?.user?.avatarUrl,
                  selectedParticipantIndex,
                  updateSelectedParticipant,
              }
            : {
                  displayName: "You",
                  avatarName: `${self?.firstName} ${self?.lastName}`,
                  avatarUrl: self?.avatarUrl,
              };

    const onNthViewingSelf =
        stepType ===
            "Image (optional) + Nth participant Etherpad + viewer's Etherpad" &&
        currentlyViewingSelf;
    return (
        <Flex w="100%" direction="column" alignContent="center">
            {optionalHeaderText && (
                <Text
                    w="100%"
                    padding="12px 0px"
                    mb="32px"
                    textAlign="center"
                    fontSize="24px"
                    fontWeight="semibold"
                    color="dark.800"
                    lineHeight="30px"
                    fontFamily="Proxima Nova"
                >
                    {optionalHeaderText}
                </Text>
            )}
            <Flex flex="1" gap="8px" justifyContent="center">
                {optionalImage && (
                    <ImageResource
                        url={optionalImage.url}
                        altText={optionalImage.name}
                    />
                )}
                <Flex flex={onNthViewingSelf ? "2" : "1"}>
                    <IFrameResource
                        url={leftEtherpadUrl}
                        name={leftName ?? ""}
                        headerType={leftHeaderType}
                        resourceType="etherpad"
                        currentlyViewing={currentlyViewing}
                    />
                </Flex>
                {!onNthViewingSelf && (
                    <IFrameResource
                        url={rightEtherpadUrl}
                        name={rightName ?? ""}
                        headerType={rightHeaderType}
                        resourceType="etherpad"
                        currentlyViewing={currentlyViewing}
                    />
                )}
            </Flex>
        </Flex>
    );
};
