import { Flex } from "@chakra-ui/react";

interface Props {
    url: string;
    name: string;
}

export const MementoResource = (props: Props) => {
    const { url, name } = props;

    return (
        <Flex flex="1" justifyContent="center" overflowY="auto">
            <Flex h="100%" w="100%">
                <iframe title={name} src={url} height="100%" width="100%" />
            </Flex>
        </Flex>
    );
};
