import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { RecoilRoot } from "recoil";
import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraBaseProvider, extendTheme } from "@chakra-ui/react";
import { App } from "./App";

import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
// `@chakra-ui/theme` is a part of the base install with `@chakra-ui/react`
import * as Sentry from "@sentry/react";
import PostHogProviderWrapper from "./posthog";

const posthogOptions = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    session_recording: {
        recordCrossOriginIframes: true,
    },
};

Sentry.init({
    dsn: "https://6b13378ad04049fba82e972bcfba2d48@o1112051.ingest.sentry.io/4505370207911936",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.25,
    environment: process.env.REACT_APP_STAGE,
});

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(parts.keys);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

export const theme = extendTheme({
    components: {
        Modal: defineMultiStyleConfig({
            baseStyle: definePartsStyle({
                dialog: {
                    borderRadius: "20px",
                    padding: "20px",
                },
            }),
        }),
        Button: {
            variants: {
                black: {
                    color: "white",
                    background: "black",
                },
            },
        },
        Progress: {
            variants: {
                stepInProgress: {
                    filledTrack: {
                        bg: "linear-gradient( to right, transparent 0%, #1A6CE5 50%, transparent 100% );",
                    },
                },
                stepDone: {
                    filledTrack: {
                        bg: "#1A6CE5",
                    },
                },
                groupingDone: {
                    filledTrack: {
                        bg: "#3AAA59",
                    },
                },
                rescheduled: {
                    filledTrack: {
                        bg: "#D8DADF",
                    },
                },
            },
        },
    },
    colors: {
        black: {
            default: "#000000",
            50: "#AAAAAA",
            500: "#000000",
            900: "#0F0F0F",
        },
        dark: {
            200: "#D7D9DC",
            300: "#B9BBBF",
            400: "#999CA3",
            500: "#787A80",
            600: "#5B5D61",
            700: "#3A3B3D",
            800: "#1D1E1E",
            900: "#0F0F0F",
        },
        blue: {
            background: "#FBFDFF",
            50: "#EDF5FF",
            100: "#D3E5FF",
            200: "#B3D2FF",
            300: "#78ACF9",
            400: "#4185EC",
            500: "#1A6CE5",
            600: "#025ACF",
            700: "#124999",
            800: "#OD3673",
            900: "#082045",
            950: "040D1C",
        },
        purple: {
            "-5": "#F0EEFD",
            "-4": "#E2DDFA",
            "-3": "#C5BBF6",
            "-2": "#A798F1",
            "-1": "#8A76ED",
            0: "#6D54E8",
            1: "#5A45C4",
            2: "#4735A1",
            3: "#35267D",
            4: "#1F1452",
            5: "#0D062D",
        },
        danger: {
            "-5": "#FBEBEA",
            "-4": "#F8D6D5",
            "-3": "#F0ADAA",
            "-2": "#E98580",
            "-1": "#E15C55",
            0: "#DA332B",
            1: "#AE2922",
            2: "#831F1A",
            3: "#571411",
            4: "#330C0A",
            5: "#230806",
        },
        warning: {
            "-5": "#FDE9E0",
            "-4": "#FAD2BE",
            "-3": "#F8BA9C",
            "-2": "#F6A37A",
            "-1": "#F38B59",
            0: "#F17437",
            1: "#C8602E",
            2: "#A04D24",
            3: "#77391B",
            4: "#4E2612",
            5: "#261209",
            400: "#F6A37A",
            500: "#F38B59",
            600: "#F17437",
            700: "#C8602E",
        },
        info: {
            "-5": "#FFFAEB",
            "-4": "#FFF6D6",
            "-3": "#FFEDAD",
            "-2": "#FFE485",
            "-1": "#FFDB5C",
            0: "#FFD233",
            1: "#CEAA29",
            2: "#9D811F",
            3: "#6C5914",
            4: "#3B300A",
            5: "#231C05",
        },
        positive: {
            "-5": "#EDF9F0",
            "-4": "#DAF3E2",
            "-3": "#B5E7C4",
            "-2": "#91DAA7",
            "-1": "#6CCE89",
            0: "#47C26C",
            1: "#399B56",
            2: "#2B7441",
            3: "#1C4E2B",
            4: "#153A20",
            5: "#0E2716",
            500: "#46CC6B",
        },
        gray: {
            white: "#FFFFFF",
            background: "#FBFCFD",
            25: "#FBFCFD",
            50: "#F3F4F7",
            100: "#E8EAEE",
            200: "#D8DADF",
            300: "#C2C5CC",
            400: "#999EA8",
            500: "#787C87",
            600: "#5B5E67",
            700: "#3B3E44",
            800: "#2B2D31",
            900: "#18191B",
            950: "#0E0F10",
        },
    },
    styles: {
        global: {
            ".exclude-from-reset > *": {
                all: "revert",
            },
            ".richtext *": {
                fontFamily: "Proxima Nova",
            },
            "#recap-markdown .richtext *": {
                fontFamily: "Inter",
            },
            ".richtext a": {
                color: "blue.500",
                textDecoration: "underline",
            },
        },
    },
});

root.render(
    <React.StrictMode>
        <RecoilRoot>
            <Auth0Provider
                domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
                clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
                authorizationParams={{
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    redirect_uri: window.location.origin,
                }}
            >
                <ChakraBaseProvider theme={theme}>
                    <PostHogProviderWrapper
                        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
                        options={posthogOptions}
                    >
                        <App></App>
                    </PostHogProviderWrapper>
                </ChakraBaseProvider>
            </Auth0Provider>
        </RecoilRoot>
    </React.StrictMode>,
);
