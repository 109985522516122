import { Avatar, Flex, Text } from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";

const Facepile = ({
    users,
}: {
    users: {
        avatarUrl: string | null;
        firstName: string | null;
        lastName: string | null;
    }[];
}) => (
    <Flex alignItems="center" gap="-1">
        {users
            .sort(
                (a, b) => a.firstName?.localeCompare(b?.firstName ?? "") ?? -1,
            )
            .map(({ firstName, lastName, avatarUrl }, i) => (
                <Tooltip
                    hasArrow
                    key={`${firstName} ${lastName}`}
                    label={`${firstName} ${lastName}`}
                >
                    <Avatar
                        zIndex={i}
                        borderColor="white"
                        borderStyle="solid"
                        borderWidth="2px"
                        marginLeft={i && "-1"}
                        name={`${firstName} ${lastName}`}
                        src={avatarUrl || undefined}
                        size="xs"
                    />
                </Tooltip>
            ))}
    </Flex>
);

export default Facepile;
