import { useRecoilValue, useSetRecoilState } from "recoil";
import { newTabHelpUrl, videoRoom } from "../immersionState";
import { useStepContent, useOwnResources } from "../hooks/useSessionContent";
import { useParticipants, useSelfData } from "../hooks/useImmersion";
import { Flex, Text, keyframes } from "@chakra-ui/react";
import { HeaderType, ResourceType } from "./resources/ResourceHeader";
import { IFrameResource } from "./resources/IFrameResource";
import { ChatbotResource } from "./resources/ChatbotResource";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PARTICIPANT_BACKGROUNDS } from "../../Constants";
import { mapColorNameToHex } from "../../utils";

type Props = {
    resourceType: ResourceType;
    nthParticipantStep?: boolean;
};

const genieEffectInWithBounce = keyframes`
0% {
    transform: scale(0.6) translateY(100%);
    opacity: 1;
}
37% {
    transform: scale(1.05) translateY(0%);
    opacity: 1;
}
65% {
    transform: scale(0.99);
    opacity: 1;
}
100% {
    transform: scale(1);
    opacity: 1;
}
`;

export const FullScreenIFrame = (props: Props) => {
    const { resourceType, nthParticipantStep } = props;
    const { resources, variables } = useStepContent();
    const ownResources = useOwnResources();
    const self = useSelfData();
    const participants = useParticipants();
    const setNewTabHelpUrl = useSetRecoilState(newTabHelpUrl);
    const room = useRecoilValue(videoRoom);
    const selfOnTwilio = participants.find(
        (p) => p.user.id === room?.localParticipant?.identity,
    );

    const stepNumber = parseInt(
        useParams<{ stepNumber: string }>().stepNumber || "0",
    );
    const nthParticipantIndex =
        (nthParticipantStep ? parseInt(variables?.variable1 ?? "0") : 0) %
        (participants?.length ?? 0);
    const [selectedParticipantIndex, setSelectedParticipantIndex] =
        useState(nthParticipantIndex);
    const selectedParticipant = participants[selectedParticipantIndex];

    // reset state between steps or when nth participant changes
    useEffect(() => {
        setSelectedParticipantIndex(nthParticipantIndex);
    }, [nthParticipantIndex, participants, stepNumber]);

    const updateSelectedParticipant = (participantId: string) => {
        const index =
            participants?.findIndex((participant) => {
                return participant?.id === participantId;
            }) ?? 0;
        setSelectedParticipantIndex(index);
    };

    let currentResource = null;
    if (nthParticipantStep) {
        currentResource = resources?.resource1?.[selectedParticipantIndex];
    } else {
        currentResource = ownResources?.resource1;
    }

    const participantName = self?.firstName ?? "";
    const participantColor = selfOnTwilio
        ? mapColorNameToHex(PARTICIPANT_BACKGROUNDS[selfOnTwilio.index])
        : null;
    const currentlyViewingSelf =
        selectedParticipant?.id === self?.sessionParticipantUuid;

    const baseUrl = currentResource?.url ?? "";
    const name = currentResource?.name ?? "";
    const usageInSession = currentResource?.usageInSession ?? "";
    const systemId = currentResource?.systemId ?? "";

    let url = "";
    let optionalHeaderText = null;
    switch (resourceType) {
        case "etherpad":
            url = `${baseUrl}/${systemId}?showControls=true&showChat=false&showLineNumbers=true&useMonospaceFont=false&userName=${participantName}&theme=sparkwise`;
            break;
        case "excalidraw":
            let viewport, mode;
            if (nthParticipantStep) {
                optionalHeaderText = variables?.variable2 ?? null;
                viewport = null;
                mode = "none";
            } else {
                optionalHeaderText = null;
                viewport = variables?.variable1 ?? null;
                mode = variables?.variable2 ?? "minimal";
            }

            const optionalViewport = viewport ? `&viewport=${viewport}` : "";
            const optionalColor = participantColor
                ? `&color=${encodeURIComponent(participantColor)}`
                : "";
            const excalidrawUrl = process.env.REACT_APP_EXCALIDRAW_URL;
            url = `${excalidrawUrl}?mode=${mode}&name=${participantName}${optionalViewport}${optionalColor}&cache=${systemId}#room=${systemId}`;

            break;
        case "gsheet":
            let tab, viewMode;
            if (nthParticipantStep) {
                optionalHeaderText = variables?.variable2 ?? null;
                tab = null;
                viewMode = currentlyViewingSelf ? "edit" : "preview";
            } else {
                optionalHeaderText = null;
                tab = variables?.variable1 ?? null;
                viewMode = "edit";
            }
            url = `${baseUrl}/${viewMode}?rm=minimal&embedded=true${
                tab ? `#gid=${tab}` : ""
            }`;
            setNewTabHelpUrl(url);
            break;
        case "chatbot":
            optionalHeaderText = variables?.variable2 ?? null;
            break;
    }

    let headerType: HeaderType = nthParticipantStep
        ? `readonly nth ${
              resourceType === "chatbot" ? "chatbot" : "fullscreen"
          }`
        : usageInSession === "individualized"
          ? "solo"
          : "group";

    const currentlyViewing = nthParticipantStep
        ? {
              displayName: currentlyViewingSelf
                  ? "You"
                  : selectedParticipant?.user?.firstName,
              avatarName: `${selectedParticipant?.user?.firstName} ${selectedParticipant?.user?.lastName}`,
              avatarUrl: selectedParticipant?.user?.avatarUrl,
              selectedParticipantIndex,
              updateSelectedParticipant,
          }
        : null;

    return (
        <Flex w="100%" direction="column" alignContent="center">
            {optionalHeaderText && (
                <Flex
                    zIndex="0"
                    w="fit-content"
                    p="10px 16px"
                    ml="24px"
                    opacity={0}
                    bgColor="gray.900"
                    borderRadius="12px 12px 0px 0px"
                    animation={`${genieEffectInWithBounce} 0.65s ease-in-out forwards 1s`}
                    transformOrigin="bottom left"
                >
                    <Text
                        padding="0px"
                        textAlign="center"
                        fontSize="15px"
                        fontWeight="400"
                        color="white"
                        lineHeight="17px"
                        fontFamily="Inter"
                    >
                        {optionalHeaderText}
                    </Text>
                </Flex>
            )}
            <Flex flex="1" gap="8px" justifyContent="center" zIndex="1">
                {resourceType === "chatbot" ? (
                    <ChatbotResource
                        systemId={systemId}
                        headerType={headerType}
                        currentlyViewing={currentlyViewing}
                    />
                ) : (
                    <IFrameResource
                        url={url}
                        name={name}
                        headerType={headerType}
                        resourceType={resourceType}
                        currentlyViewing={currentlyViewing}
                    />
                )}
            </Flex>
        </Flex>
    );
};
