import { Button, Flex, Text } from "@chakra-ui/react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useGroupId, useSelfData } from "../hooks/useImmersion";
import { useJoinVideoStepNumber } from "../hooks/useSessionContent";
import { useEffect, useState } from "react";

type MatchingFooterProps = {
    matchingStatus: "grouped" | "waiting" | "rescheduled" | "groupedOnLoad";
    restartMatching: () => void;
};

export const MatchingFooter = (props: MatchingFooterProps) => {
    const { matchingStatus, restartMatching } = props;
    const { id: timeslotId } = useParams();
    const navigate = useNavigate();
    const participantId = useSelfData().sessionParticipantUuid;
    const groupId = useGroupId();
    const joinVideoStepNumber = useJoinVideoStepNumber();
    const [isVideoButtonLoading, setIsVideoButtonLoading] =
        useState<boolean>(true);
    const [isRescheduleButtonLoading, setIsRescheduleButtonLoading] =
        useState<boolean>(true);
    const pathname = useLocation().pathname

    useEffect(() => {
        if (timeslotId && groupId && joinVideoStepNumber) {
            setIsVideoButtonLoading(false);
        }
    }, [timeslotId, groupId, joinVideoStepNumber]);

    useEffect(() => {
        if (participantId) {
            setIsRescheduleButtonLoading(false);
        }
    }, [participantId]);

    if (matchingStatus === "waiting") return null;

    return (
        <Flex
            position="absolute"
            bottom="0"
            w="100%"
            pt="32px"
            pb="32px"
            justifyContent="center"
            borderTop="1px"
            borderColor="gray.200"
        >
            {matchingStatus.includes("grouped") ? (
                <Button
                    backgroundColor="blue.500"
                    borderRadius="8px"
                    p="16px 20px"
                    color="white"
                    fontFamily="Inter"
                    fontSize="15px"
                    isLoading={isVideoButtonLoading}
                    _loading={{
                        cursor: "not-allowed",
                        _hover: {
                            bg: "blue.500",
                        },
                    }}
                    _hover={{ bg: "blue.600" }}
                    onClick={() =>
                        navigate(
                            `/immersion/${timeslotId}/group/${groupId}/${joinVideoStepNumber}`,
                        )
                    }
                >
                    Connect to video
                </Button>
            ) : (
                <Flex flex="1" direction="column" alignItems="center">
                    <Text
                        w="460px"
                        color="gray.800"
                        fontFamily="Inter"
                        fontSize="15px"
                        textAlign="center"
                    >
                        Unfortunately, there are no open groups to join at this
                        point.
                    </Text>
                    <Flex
                        flex="1"
                        justifyContent="center"
                        gap="12px"
                        mt="40px"
                        flexDir="column"
                    >
                        <Button
                            backgroundColor="gray.800"
                            borderRadius="8px"
                            p="16px 20px"
                            color="white"
                            fontFamily="Inter"
                            fontSize="15px"
                            fontWeight="600"
                            isLoading={isRescheduleButtonLoading}
                            _loading={{
                                cursor: "not-allowed",
                                _hover: {
                                    bg: "gray.800",
                                },
                            }}
                            _hover={{
                                bg: "gray.600",
                                border: "gray.950",
                            }}
                            onClick={() => {
                                navigate(
                                    `/rescheduleoptions/${participantId}?ref=${pathname}`,
                                );
                            }}
                        >
                            Show more options
                        </Button>
                        <Button
                            backgroundColor="white"
                            borderRadius="8px"
                            p="16px 20px"
                            color="gray.800"
                            fontFamily="Inter"
                            fontSize="15px"
                            fontWeight="500"
                            _hover={{
                                bg: "gray.background",
                                border: "gray.200",
                                color: "gray.950",
                            }}
                            onClick={restartMatching}
                        >
                            Continue waiting
                        </Button>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};
