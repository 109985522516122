import {
    ConsoleLogger,
    Device,
    LogLevel,
    VoiceFocusDeviceTransformer,
    VoiceFocusTransformDevice,
} from "amazon-chime-sdk-js";
import React, { PropsWithChildren } from "react";
import {
    currentAudioInputDeviceId,
    isNoiseCancellingEnabledState,
} from "../../immersionState";
import { useRecoilState, useRecoilValue } from "recoil";

export interface LocalNoiseCancellingContext {
    isNoiseCancelling: boolean;
    toggleNoiseCancelling: () => void;
    getNoiseCancellingDevice: () => Promise<
        VoiceFocusTransformDevice | Device | string | null
    >;
}

export const LocalNoiseCancellingContext =
    React.createContext<LocalNoiseCancellingContext>(
        null as unknown as LocalNoiseCancellingContext,
    );

export const LocalNoiseCancellingContextProvider: React.FC<
    PropsWithChildren
> = ({ children }) => {
    const logger = new ConsoleLogger("LocalNoiseCancelling", LogLevel.INFO);
    const [isNoiseCancelling, setIsNoiseCancelling] = useRecoilState(
        isNoiseCancellingEnabledState,
    );

    const deviceId = useRecoilValue(currentAudioInputDeviceId);
    const [device, setDevice] = React.useState<
        VoiceFocusTransformDevice | string | null
    >(null);

    React.useEffect(() => {
        if (deviceId === null) {
            return;
        }
        logger.info(`Updating settings for device ${deviceId}`);
        let deviceInstance: VoiceFocusTransformDevice | null = null;
        let transformInstance: VoiceFocusDeviceTransformer | null = null;
        (async () => {
            transformInstance = (await initializeNoiseCancelling()) || null;
            const isVoiceFocusSupported = transformInstance?.isSupported();
            if (!isVoiceFocusSupported) {
                setDevice(deviceId);
            } else if (transformInstance) {
                const myDevice =
                    await transformInstance.createTransformDevice(deviceId);
                setDevice(myDevice || null);
                deviceInstance = myDevice || null;
            }
        })();
        return () => {
            (async () => {
                if (transformInstance) {
                    logger.info(`Destroying Noise cancelling transform`);
                    await VoiceFocusDeviceTransformer.destroyVoiceFocus(
                        transformInstance,
                    );
                }
                if (deviceInstance) {
                    logger.info(`Destroying Noise cancelling device`);
                    await deviceInstance.stop();
                }
            })();
        };
    }, [deviceId]);

    const initializeNoiseCancelling = async (): Promise<
        VoiceFocusDeviceTransformer | undefined
    > => {
        try {
            logger.info(`Creating noise cancellation transformer`);
            return await VoiceFocusDeviceTransformer.create();
        } catch (error) {
            logger.error(
                `Error creating voice focus device transformer ${error}`,
            );
            return undefined;
        }
    };

    const getNoiseCancellingDevice = React.useCallback(async () => {
        if (!device) {
            return null;
        }
        if (isNoiseCancelling) {
            return device;
        }
        if (typeof device === "string") {
            return device;
        }
        return device.intrinsicDevice();
    }, [device, isNoiseCancelling]);

    const toggleNoiseCancelling = React.useCallback(async (): Promise<void> => {
        setIsNoiseCancelling(!isNoiseCancelling);
    }, [isNoiseCancelling, setIsNoiseCancelling]);

    const context: LocalNoiseCancellingContext = {
        isNoiseCancelling,
        toggleNoiseCancelling,
        getNoiseCancellingDevice,
    };
    return (
        <LocalNoiseCancellingContext.Provider value={context}>
            {children}
        </LocalNoiseCancellingContext.Provider>
    );
};
