import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { trpc } from "../hooks/useTRPC";
import { Button, Center, Image, Stack, Text } from "@chakra-ui/react";
import RescheduleImage from "design/assets/Reschedule.svg";
import { useAccount } from "../hooks/useAccount";
import { Loading } from "../Loading";
import * as Sentry from "@sentry/react";
import { Error } from "../Error";
import { RescheduleOptions as RescheduleOptionsEnum } from "@prisma/client";

export const RescheduleOptions = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const account = useAccount();
    const [isSoloGroupLoading, setIsSoloGroupLoading] =
        useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const isFromMatching = searchParams.get("ref")?.includes("match") || false;
    const isFromRapidSharing =
        searchParams.get("ref")?.includes("rapidsharing") || false;

    const { data, isLoading, error } = trpc.immersion.getImmersion.useQuery({
        sessionParticipantId: id || "",
    });
    const { mutateAsync: reschedule } =
        trpc.immersion.rescheduleImmersion.useMutation();
    const { mutateAsync: assignToSoloGroup } =
        trpc.matching.assignToSoloGroup.useMutation();

    if (isLoading || account.isLoading) {
        return <Loading></Loading>;
    }
    if (!id || !data || !data.time) {
        Sentry.captureException(error);
        return <Error text={error?.message}></Error>;
    }
    if (!account.data || account.isError) {
        Sentry.captureException(account.error);
        return <Error text={account.error?.message} />;
    }

    return (
        <Center h="100vh" w="100vw">
            <Stack bg="white" width="500px" gap="56px">
                <Stack alignItems="center">
                    <Image h="159px" src={RescheduleImage} />
                    <Text textAlign="center" fontWeight="bold" fontSize="4xl">
                        Here are your options
                    </Text>
                    <Text fontSize="16px" fontWeight="400" textAlign="center">
                        We're sorry you're missing this session. You can still
                        access the content on your own to catch up.
                    </Text>
                </Stack>

                <Stack gap="24px">
                    {!isFromRapidSharing && (
                        <Button
                            colorScheme="black"
                            isLoading={isSoloGroupLoading}
                            onClick={async () => {
                                if (isFromMatching) {
                                    setIsSoloGroupLoading(true);
                                    const {
                                        timeslotId,
                                        groupId,
                                        joinVideoStepNumber,
                                    } =
                                        (await assignToSoloGroup({
                                            participantId: id,
                                        })) || ({} as any);
                                    if (
                                        timeslotId &&
                                        groupId &&
                                        joinVideoStepNumber
                                    ) {
                                        navigate(
                                            `/immersion/${timeslotId}/group/${groupId}/${joinVideoStepNumber}`,
                                        );
                                    } else {
                                        setIsSoloGroupLoading(false);
                                    }
                                } else {
                                    await reschedule({
                                        sessionParticipantId: id,
                                        requestingSoloCatchup: true,
                                    });
                                    navigate(`/reschedule/confirm`);
                                }
                            }}
                        >
                            {isFromMatching
                                ? "Explore solo instead"
                                : "Request solo access"}
                        </Button>
                    )}
                    <Button
                        onClick={async () => {
                            await reschedule({
                                sessionParticipantId: id,
                                cancellingAttendance: true,
                            });
                            navigate(`/cancellation/confirm`);
                        }}
                    >
                        I'll skip this session
                    </Button>
                    {account.data.organization.rescheduleType ===
                    RescheduleOptionsEnum.DEFAULT ? (
                        <Button
                            colorScheme="white"
                            variant="ghost"
                            onClick={async () => {
                                await reschedule({
                                    sessionParticipantId: id,
                                    sameTimeNextWeekChecked: false,
                                    message: "",
                                });
                                navigate(`/reschedule/confirm`);
                            }}
                        >
                            I want to join a future group
                        </Button>
                    ) : account.data.organization.rescheduleType ===
                      RescheduleOptionsEnum.CUSTOM_TEXT ? (
                        <Text
                            textAlign={"center"}
                        >{`Want to reschedule? ${account.data.organization.rescheduleText}`}</Text>
                    ) : undefined}
                </Stack>
            </Stack>
        </Center>
    );
};
