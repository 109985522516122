import { Image } from "@chakra-ui/react";
import MicButtonOff from "design/assets/MicButtonOff.svg";
import { useEffect, useCallback, useState, useRef } from "react";

const MicLevelButton = ({
    micEnabled,
    localAudioTrack,
}: {
    micEnabled: boolean;
    localAudioTrack: MediaStreamTrack | undefined | null;
}) => {
    const [audioLevel, setAudioLevel] = useState<number>(0);
    const audioContextRef = useRef<AudioContext | null>(null);
    const analyserRef = useRef<AnalyserNode | null>(null);
    const animationFrameRef = useRef<number | null>(null);

    const measureAudioLevel = useCallback(() => {
        const analyser = analyserRef.current;
        if (!analyser) return;

        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(dataArray);
        let sum = dataArray.reduce((a, b) => a + b, 0);
        let average = sum / dataArray.length;
        setAudioLevel(average / 10);
        animationFrameRef.current = requestAnimationFrame(measureAudioLevel);
    }, []);

    const setupAudioAnalyser = useCallback(
        (audioTrack: MediaStreamTrack) => {
            const audioContext = new window.AudioContext();
            const analyser = audioContext.createAnalyser();
            const source = audioContext.createMediaStreamSource(
                new MediaStream([audioTrack]),
            );
            source.connect(analyser);
            analyser.fftSize = 256;
            audioContextRef.current = audioContext;
            analyserRef.current = analyser;
            measureAudioLevel();
        },
        [measureAudioLevel],
    );

    useEffect(() => {
        if (localAudioTrack) {
            setupAudioAnalyser(localAudioTrack);
        }

        return () => {
            if (
                audioContextRef.current &&
                audioContextRef.current.state !== "closed"
            ) {
                audioContextRef.current.close();
            }
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [localAudioTrack, setupAudioAnalyser]);

    return micEnabled ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
        >
            <g clipPath="url(#clip0_807_1163)">
                <rect
                    x="7.00195"
                    y="1.24841"
                    width="6.25"
                    height="12.5016"
                    rx="3.125"
                    fill="#C2C5CC"
                />
                <rect
                    x="7.00195"
                    y={`${13.5 - audioLevel * 1.35}`}
                    width="6.25"
                    height="12.93571"
                    fill="#47C26C"
                />
            </g>
            <path
                d="M5.12695 8.75C5.29271 8.75 5.45168 8.81585 5.56889 8.93306C5.68611 9.05027 5.75195 9.20924 5.75195 9.375V10.625C5.75195 11.7853 6.21289 12.8981 7.03336 13.7186C7.85383 14.5391 8.96663 15 10.127 15C11.2873 15 12.4001 14.5391 13.2205 13.7186C14.041 12.8981 14.502 11.7853 14.502 10.625V9.375C14.502 9.20924 14.5678 9.05027 14.685 8.93306C14.8022 8.81585 14.9612 8.75 15.127 8.75C15.2927 8.75 15.4517 8.81585 15.5689 8.93306C15.6861 9.05027 15.752 9.20924 15.752 9.375V10.625C15.7519 12.0087 15.242 13.3438 14.3196 14.3752C13.3972 15.4066 12.1271 16.0619 10.752 16.2158V18.125H13.252C13.4177 18.125 13.5767 18.1908 13.6939 18.3081C13.8111 18.4253 13.877 18.5842 13.877 18.75C13.877 18.9158 13.8111 19.0747 13.6939 19.1919C13.5767 19.3092 13.4177 19.375 13.252 19.375H7.00195C6.83619 19.375 6.67722 19.3092 6.56001 19.1919C6.4428 19.0747 6.37695 18.9158 6.37695 18.75C6.37695 18.5842 6.4428 18.4253 6.56001 18.3081C6.67722 18.1908 6.83619 18.125 7.00195 18.125H9.50195V16.2158C8.12686 16.0619 6.85671 15.4066 5.93432 14.3752C5.01192 13.3438 4.50197 12.0087 4.50195 10.625V9.375C4.50195 9.20924 4.5678 9.05027 4.68501 8.93306C4.80222 8.81585 4.96119 8.75 5.12695 8.75Z"
                fill="#C2C5CC"
            />
            <defs>
                <clipPath id="clip0_807_1163">
                    <rect
                        x="7.00195"
                        y="1.24841"
                        width="6.25"
                        height="12.5016"
                        rx="3.125"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    ) : (
        <Image h="20px" src={MicButtonOff} alt={"Microphone muted"} />
    );
};

export default MicLevelButton;
