import {
    Image,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    useDisclosure,
} from "@chakra-ui/react";
import { IMMERSION_SIZES } from "../../Immersion";

import { ArrowsPointingOutIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

interface Props {
    url: string;
    altText: string;
    border?: boolean;
    fade?: boolean;
    fullscreen?: boolean;
}

export const ImageResource = (props: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [hoverImage, setHoverImage] = useState(false);

    const { url, altText, border, fade, fullscreen } = props;
    const {
        STEP_MARGIN_TOP,
        STEP_MARGIN_BOTTOM,
        HEADER_HEIGHT,
        FOOTER_HEIGHT,
    } = IMMERSION_SIZES;
    const maxH = `calc(100vh - ${STEP_MARGIN_TOP} - ${STEP_MARGIN_BOTTOM} - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`;
    const borderWidth = border ? "1px" : "0px";
    const fadeStyles = fade
        ? {
              filter: "auto",
              blur: "10px",
              transition: "none",
          }
        : {
              filter: "none",
              blur: "0px",
              transition: "all 0.2s ease-out",
          };
    return (
        <Flex flex="1" direction={fullscreen ? "column" : "row"}>
            {fullscreen && <Flex flex="1 1 30%" />}
            <Flex
                flex="1"
                h="fit-content"
                justifyContent="center"
                onMouseEnter={() => setHoverImage(true)}
                onMouseLeave={() => setHoverImage(false)}
            >
                <Image
                    maxH={maxH}
                    objectFit="contain"
                    borderRadius="12px"
                    sx={fadeStyles}
                    src={url}
                    alt={altText}
                />
                <Flex
                    opacity={hoverImage ? 0.7 : 0.3}
                    cursor="zoom-in"
                    left="-40px"
                    top="10px"
                    position="relative"
                    width="0px"
                >
                    <Flex position="absolute">
                        <ArrowsPointingOutIcon height="30px" onClick={onOpen} />
                    </Flex>
                </Flex>
            </Flex>
            {fullscreen && <Flex flex="1 1 70%" />}
            <Modal isOpen={isOpen} onClose={onClose} size="full">
                <ModalOverlay />
                <ModalContent
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            onClose();
                        }
                    }}
                    backgroundColor="transparent"
                    flexDir="row"
                    justifyContent="center"
                    padding="10px"
                >
                    <Image
                        height="fit-content"
                        maxH="95vh"
                        objectFit="contain"
                        borderRadius="12px"
                        sx={fadeStyles}
                        src={url}
                        alt={altText}
                    />
                    <Flex
                        cursor="pointer"
                        left="-40px"
                        top="10px"
                        position="relative"
                        width="0px"
                        alignSelf="flex-start"
                    >
                        <Flex position="absolute">
                            <XMarkIcon height="30px" onClick={onClose} />
                        </Flex>
                    </Flex>
                </ModalContent>
            </Modal>
        </Flex>
    );
};
