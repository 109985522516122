import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { VideoControlButton } from "./AudioVideoControls/VideoControlButton";
import { NoCameraAlert } from "./AudioVideoControls/NoCameraAlert";
import { AudioControlButton } from "./AudioVideoControls/AudioControlButton";

export const AudioVideoControls: React.FC<{
    audioTrack?: MediaStreamTrack | null;
}> = ({ audioTrack }) => {
    return (
        <Box position={"absolute"} bottom={0} w={"100%"} h={"100%"}>
            <NoCameraAlert />
            <Flex
                position={"absolute"}
                w={"100%"}
                bottom={0}
                direction={"column"}
                alignItems={"center"}
                mb={2}
            >
                <Flex direction={"row"}>
                    <AudioControlButton audioTrack={audioTrack} />
                    <VideoControlButton />
                </Flex>
            </Flex>
        </Box>
    );
};
