import { EventAttributes, EventName } from "amazon-chime-sdk-js";

class ErrorMessage {
    constructor(
        readonly messages: string[],
        readonly rawError: object,
        readonly isUserFacing: boolean = true,
    ) {}
}

export function mediaErrorHelpMessage(error: Error) {
    if (error.name === "NotFoundError") {
        return [
            "You may have disabled camera/microphone for this browser in your system settings or have no camera/microphone connected.",
            "Please ensure that you have at least one input device connected and enabled in your system settings. If that doesn't work, try quitting and restarting your browser.",
        ];
    } else if (error.name === "NotAllowedError") {
        return [
            "You previously denied access to your camera/microphone or dismissed the permission dialog.",
            "Please allow app.sparkwise.co to access your camera/microphone in your browser settings.",
        ];
    } else if (error.name === "TypeError") {
        return [
            "Please contact us at support@sparkwise.co and mention 'Media Error: TypeError'.",
        ];
    } else if (error.name === "NotReadableError") {
        return [
            "Another application or browser tab might already be using your webcam. Please shut down any other app that might be interacting with your camera (Zoom, Meet, Teams) and try again.",
        ];
    } else if (error.name === "OverconstrainedError") {
        return [
            "Your camera/microphone could not satisfy the requested media constraints.",
            "The Sparkwise app may be trying to find a camera/microphone that is no longer connected to your machine.",
        ];
    } else {
        return ["Media error:" + error.name + ", " + error.message];
    }
}

export function meetingErrorHelpMessage(
    name: EventName,
    attributes: EventAttributes,
): ErrorMessage | null {
    switch (name) {
        case "meetingStartFailed":
            switch (attributes.meetingStatus) {
                case "AudioCallAtCapacity":
                    return new ErrorMessage(
                        [
                            "The attendee couldn't join because the meeting was at capacity.",
                            "Ensure that the number of attendees does not exceed the maximum limit (250).",
                        ],
                        attributes,
                    );

                case "MeetingEnded":
                    return new ErrorMessage(
                        [
                            "The meeting already ended.",
                            "Ensure that you or someone else have not deleted a meeting using the DeleteMeeting API action in your server application. A meeting also automatically ends after a period of inactivity. ",
                        ],
                        attributes,
                    );
                case "SignalingBadRequest":
                    return new ErrorMessage(
                        [
                            "The meeting already ended.",
                            "Ensure that you or someone else have not deleted a meeting using the DeleteMeeting API action in your server application. A meeting also automatically ends after a period of inactivity. ",
                        ],
                        attributes,
                    );
                default:
                    return new ErrorMessage(
                        [
                            "Unknown error",
                            "Please reconnect to video after reloading your browser window.",
                        ],
                        attributes,
                    );
            }
        case "meetingFailed":
            switch (attributes.meetingStatus) {
                case "AudioJoinedFromAnotherDevice":
                    return new ErrorMessage(
                        [
                            "The attendee couldn't join because the attendee joined from another device.",
                            "Ensure that you do not use the same attendee response from the CreateAttendee, BatchCreateAttendee, or CreateMeetingWithAttendees API action in two or more meetings simultaneously.",
                        ],
                        attributes,
                    );
                case "RealtimeApiFailed":
                    return new ErrorMessage(
                        [
                            "The failing part is audioVideo's realtime API",
                            "Ensure that the callback you passed to the real-time API, such as meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator, does not throw an exception.",
                        ],
                        attributes,
                    );
                case "TaskFailed":
                    return new ErrorMessage(
                        [
                            "Either WebSocket connection failed or OpenSignalingConnectionTask got canceled while waiting to open signaling connection",
                            "Ensure that you have a stable internet connection. ",
                        ],
                        attributes,
                    );
                default:
                    return new ErrorMessage(
                        [
                            "Unknown error",
                            "Please reconnect to video after reloading your browser window.",
                        ],
                        attributes,
                    );
            }
        case "meetingEnded":
            switch (attributes.meetingStatus) {
                case "AudioJoinedFromAnotherDevice":
                    return new ErrorMessage(
                        [
                            "The attendee is dropped because the same attendee joined from another device.",
                            "Ensure that you do not use the same attendee response from the CreateAttendee, BatchCreateAttendee, or CreateMeetingWithAttendees API action in two or more meetings simultaneously.",
                        ],
                        attributes,
                    );
                default:
                    return null;
            }
        case "signalingDropped":
            return new ErrorMessage(
                ["The WebSocket failed or closed with an error."],
                attributes,
                false,
            );
        case "receivingAudioDropped":
            return new ErrorMessage(
                ["A significant number of receive-audio packets dropped."],
                attributes,
                false,
            );
        case "sendingAudioFailed":
            return new ErrorMessage(
                [
                    `Sending audio packets failed: Check audio input device and connectivity`,
                ],
                attributes,
                false,
            );
        default:
            return null;
    }
}

export function handleConnectionError(error: Error | TypeError | RangeError) {
    if (error instanceof TypeError) {
        return [
            "Please contact us at support@sparkwise.co and mention 'Connection Error: TypeError'.",
        ];
    } else if (error instanceof RangeError) {
        return [
            "Please contact us at support@sparkwise.co and mention 'Connection Error: RangeError'.",
        ];
    } else {
        return ["Connection error:" + error.name + ", " + error.message];
    }
}
