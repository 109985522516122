import {
    Box,
    Button,
    Flex,
    Image,
    SimpleGrid,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useTRPC } from "../../hooks/useTRPC";
import {
    SESSION_DEFAULT_BACKGROUND_COLOR,
    extractImmersionTitle,
} from "../utils";
import FallbackSessionImage from "../../assets/default_immersion_square.png";

const SoloCatchups = () => {
    const trpc = useTRPC();
    const { data: soloCatchups } = trpc.sessions.soloCatchups.useQuery();

    if (!soloCatchups || soloCatchups.length === 0) {
        return null;
    }

    return (
        <Flex direction="column" width="full" gap="4">
            <Text fontSize="md" fontWeight="semibold" mb="2">
                Solo Catch-ups
            </Text>
            <SimpleGrid columns={[1, null, 2, null, 3]} spacing="16px">
                {soloCatchups?.map((session: any) =>
                    !session.time ? null : (
                        <VStack
                            key={session.id}
                            p="2"
                            pb="12px"
                            justifyContent="space-between"
                            height="336px"
                            width="full"
                            borderRadius="12px"
                            borderColor="gray.100"
                            borderWidth="thin"
                            shadow="sm"
                            _hover={{
                                shadow: "lg",
                            }}
                        >
                            <Box>
                                <Flex
                                    height="135px"
                                    width="full"
                                    justifyContent="center"
                                    alignItems="center"
                                    borderRadius="8px"
                                    bg={
                                        session.immersion
                                            .thumbnailBackgroundColor ||
                                        SESSION_DEFAULT_BACKGROUND_COLOR
                                    }
                                >
                                    <Image
                                        maxHeight="88px"
                                        src={
                                            session.immersion.templateUrl ||
                                            FallbackSessionImage
                                        }
                                        alt={""}
                                    />
                                </Flex>

                                <Flex
                                    direction="column"
                                    mt="16px"
                                    px="12px"
                                    gap="8px"
                                >
                                    <Text fontSize="sm" color="dark.400" mr="2">
                                        Solo catch-up
                                    </Text>
                                    <Text fontSize="sm" fontWeight="semibold">
                                        {extractImmersionTitle(
                                            session.immersion,
                                        )}
                                    </Text>
                                </Flex>
                            </Box>

                            <Box width="full">
                                <Button
                                    data-testid="catchup-start-button"
                                    bg="white"
                                    border="gray.100"
                                    borderWidth="thin"
                                    color="dark.600"
                                    fontSize="sm"
                                    as="a"
                                    href={`/immersion/${session.id}/intro/0`}
                                    width="full"
                                    _hover={{
                                        bg: "gray.background",
                                        border: "gray.200",
                                        color: "gray.950",
                                    }}
                                >
                                    Start
                                </Button>
                            </Box>
                        </VStack>
                    ),
                )}
            </SimpleGrid>
        </Flex>
    );
};

export default SoloCatchups;
