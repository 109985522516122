import { useEffect, useState } from "react";
import { RemoteParticipant, Room } from "twilio-video";


// Get all current participants in a room.
export default function useParticipants(room: Room) {
    const [participants, setParticipants] = useState(
        Array.from(room?.participants.values() ?? []),
    );

    useEffect(() => {
        if (room) {
            const participantConnected = (participant: RemoteParticipant) =>
                setParticipants((participants) => [
                    ...participants,
                    participant,
                ]);

            const participantDisconnected = (participant: RemoteParticipant) =>
                setParticipants((participants) =>
                    participants.filter((p) => p !== participant),
                );

            room.on("participantConnected", participantConnected);
            room.on("participantDisconnected", participantDisconnected);
            return () => {
                room.off("participantConnected", participantConnected);
                room.off("participantDisconnected", participantDisconnected);
            };
        }
    }, [room]);

    return participants;
}
