import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { IMMERSION_SIZES } from "../../Immersion";

interface Props {
    content: string[];
    title?: string;
    subtitle?: string;
    note?: JSX.Element;
}

export const RichtextCardResource = (props: Props) => {
    const { content, title, subtitle, note } = props;
    const [textSize, setTextSize] = useState(0);

    const textRef = useRef<HTMLDivElement>(null);
    const {
        STEP_MARGIN_TOP,
        STEP_MARGIN_BOTTOM,
        HEADER_HEIGHT,
        FOOTER_HEIGHT,
    } = IMMERSION_SIZES;
    const maxH = `calc(100vh - ${STEP_MARGIN_TOP} - ${STEP_MARGIN_BOTTOM} - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT} - ${
        textSize ?? 0
    }px)`;

    useEffect(() => {
        const handleResize = () => {
            setTextSize(textRef.current?.parentElement?.clientHeight || 0);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [textRef, note]);

    return (
        <Flex flex="1" direction="column" align="center">
            <Flex flex="1 1 40%" />
            <Flex h="fit-content" maxW="568px">
                <Flex
                    flex="1"
                    direction="column"
                    maxH={maxH}
                    bgColor="blue.background"
                    boxShadow="0px 9px 30px 0px rgba(0, 0, 0, 0.12)"
                    borderWidth="12px"
                    borderStyle="solid"
                    borderColor="white"
                    borderRadius="20px"
                    p="60px 68px 48px 68px"
                    overflowY="auto"
                >
                    {title && (
                        <Text
                            fontFamily="Proxima Nova"
                            fontWeight="bold"
                            fontSize="36px"
                            lineHeight="40px"
                            letterSpacing="0em"
                            textAlign="center"
                            color="dark.700"
                            mb="4px"
                        >
                            {title}
                        </Text>
                    )}
                    {subtitle && (
                        <Text
                            fontFamily="Proxima Nova"
                            fontSize="20px"
                            fontWeight="400"
                            lineHeight="28px"
                            textAlign="center"
                            color="dark.500"
                            mb="32px"
                        >
                            {subtitle}
                        </Text>
                    )}
                    {content.map((item, index) => (
                        <Flex key={index} alignItems="flex-start">
                            {content.length > 1 && (
                                <Flex
                                    flexShrink="0"
                                    h="24px"
                                    w="24px"
                                    mt="16px"
                                    mr="12px"
                                    bg="blue.50"
                                    borderRadius="4px"
                                    alignContent="center"
                                    justifyContent="center"
                                >
                                    <Text
                                        fontFamily="Proxima Nova"
                                        fontWeight="bold"
                                        fontSize="16px"
                                        color="blue.500"
                                    >
                                        {index + 1}
                                    </Text>
                                </Flex>
                            )}
                            <ReactMarkdown
                                className="exclude-from-reset richtext"
                                remarkPlugins={[remarkGfm]}
                            >
                                {item}
                            </ReactMarkdown>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
            <Flex flex="1 1 60%" alignItems="center">
                {note && <Flex ref={textRef}>{note}</Flex>}
            </Flex>
        </Flex>
    );
};
