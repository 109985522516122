import { useState } from "react";
import { useRecoilState } from "recoil";
import { streamSource, newTabHelpUrl } from "./immersionState";
import { Box, Flex, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import {
    useIsStepVideo,
    useIsStepGSheet,
    useIsStepMemento,
} from "./hooks/useSessionContent";

export const StepHelp = () => {
    const isVideo = useIsStepVideo();
    const isGSheet = useIsStepGSheet();
    const isMemento = useIsStepMemento();

    const [showHelp, setShowHelp] = useState(false);
    const [currentStreamSource, setStreamSource] = useRecoilState(streamSource);
    const [newTabUrl] = useRecoilState(newTabHelpUrl);

    let outerText = "";
    let innerText = "";
    let innerLinkText = "";
    let innerLinkAction = () => {};

    if (isVideo) {
        outerText = "Having trouble playing the video?";
        innerText = "Please try the ";
        innerLinkText = "alternative video player";
        innerLinkAction = () => {
            setStreamSource(
                currentStreamSource === "sparkwise"
                    ? "cloudflare"
                    : "sparkwise",
            );
        };
    } else if (isGSheet) {
        outerText = "Having trouble viewing the sheet?";
        innerText = "Please try ";
        innerLinkText = "opening this link in a new tab";
        innerLinkAction = () => {
            if (newTabUrl) window.open(newTabUrl, "_blank");
        };
    } else if (isMemento) {
        outerText = "Having trouble viewing the form?";
        innerText = "Please try ";
        innerLinkText = "opening the form in a new tab";
        innerLinkAction = () => {
            if (newTabUrl) window.open(newTabUrl, "_blank");
        };
    }

    return (
        <Box>
            <Flex
                alignItems="center"
                _hover={{
                    cursor: "pointer",
                }}
                onClick={() => setShowHelp(!showHelp)}
            >
                {showHelp ? (
                    <ChevronUpIcon height="10px"></ChevronUpIcon>
                ) : (
                    <ChevronDownIcon height="10px"></ChevronDownIcon>
                )}
                <Text fontSize="xs" marginLeft="10px" fontWeight="semibold">
                    {outerText}
                </Text>
            </Flex>
            <Text
                fontSize="xs"
                mt="5px"
                display={showHelp ? "inherit" : "none"}
            >
                {innerText}
                <Text
                    as="span"
                    color="blue.500"
                    fontWeight="semibold"
                    _hover={{
                        textDecoration: "underline",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        innerLinkAction();
                        setShowHelp(false);
                    }}
                >
                    {innerLinkText}
                </Text>
                .
            </Text>
        </Box>
    );
};
