import NotFound404 from "design/assets/NotFound404.svg";
import { Center, Image, Stack, Text } from "@chakra-ui/react";

export const NotFound = () => {
    return (
        <Center
            w="100vw"
            h="100vh"
            position="fixed"
            top="0"
            left="0"
            bg="white"
        >
            <Stack w="665px">
                <Image h="245px" src={NotFound404} />
                <Text
                    fontFamily="Proxima Nova"
                    fontSize="40px"
                    fontWeight="semibold"
                    lineHeight="60px"
                    color="blue.500"
                    textAlign="center"
                >
                    Learning is a quest into the Unknown
                </Text>
                <Text
                    fontFamily="Proxima Nova"
                    fontSize="24px"
                    lineHeight="36px"
                    color="dark.700"
                    textAlign="center"
                >
                    Sometimes we may take a detour, but that's ok. Embrace the
                    unexpected, as you navigate back to your learning journey.
                </Text>
            </Stack>
        </Center>
    );
};
