import {
    Button,
    Flex,
    Heading,
    Image,
    Link,
    ListItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    UnorderedList,
} from "@chakra-ui/react";
import PhoneIcon from "design/assets/Phone.svg";
import Checkmark from "design/assets/Checkmark.svg";
import { useDialCode } from "../hooks/useImmersion";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

export const ConnectByPhoneModal = ({
    isOpen,
    onClose,
    isChime,
    onSuccess,
}: {
    isOpen: boolean;
    onClose: () => void;
    isChime: boolean;
    onSuccess: () => void;
}) => {
    const { id } = useParams();
    const dialCode = useDialCode();
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
            <ModalOverlay>
                <ModalContent>
                    <ModalHeader>
                        <Flex gap="10px" justifyContent="center">
                            <Image src={PhoneIcon} alt={""} />
                            <Heading fontSize="2xl">Connect by phone</Heading>
                        </Flex>
                    </ModalHeader>
                    <ModalBody>
                        <Text fontWeight="bold" fontSize="md">
                            Call any number below to join your group (audio
                            only):
                        </Text>
                        {isChime ? (
                            <UnorderedList spacing="3" marginY="30px">
                                <ListItem>
                                    <b>US</b> (Toll-Free):{" "}
                                    <Text display="inline" color="blue.500">
                                        {
                                            process.env
                                                .REACT_APP_CHIME_PHONE_NUMBER_US
                                        }
                                    </Text>{" "}
                                </ListItem>
                                {process.env.REACT_APP_CHIME_PHONE_NUMBER_UK ? (
                                    <ListItem>
                                        <b>UK</b> (Local):{" "}
                                        <Text display="inline" color="blue.500">
                                            {
                                                process.env
                                                    .REACT_APP_CHIME_PHONE_NUMBER_UK
                                            }
                                        </Text>{" "}
                                    </ListItem>
                                ) : null}
                            </UnorderedList>
                        ) : (
                            <UnorderedList spacing="3" marginY="30px">
                                <ListItem>
                                    <b>US</b> (Toll-Free):{" "}
                                    <Text display="inline" color="blue.500">
                                        (877) 275-2899
                                    </Text>{" "}
                                </ListItem>
                                <ListItem>
                                    <b>Canada</b> (Local):{" "}
                                    <Text display="inline" color="blue.500">
                                        +1 (825) 435-1028
                                    </Text>{" "}
                                </ListItem>
                                <ListItem>
                                    <b>UK</b> (Local):{" "}
                                    <Text display="inline" color="blue.500">
                                        +44 7445 875505
                                    </Text>{" "}
                                </ListItem>
                            </UnorderedList>
                        )}
                        <Flex alignItems="center" marginBottom="20px">
                            <Text fontWeight="bold">Access Code: </Text>
                            <Text
                                marginX="10px"
                                padding="10px 12px"
                                color="blue.500"
                                fontWeight="semibold"
                                borderWidth="1px"
                                borderColor="blue.100"
                                borderRadius="8px"
                                bg={"blue.50"}
                            >
                                {dialCode}
                            </Text>
                        </Flex>
                        <Flex direction="row" gap={1}>
                            <Text
                                fontFamily="Inter"
                                fontSize="16px"
                                fontWeight="400"
                                lineHeight="22px"
                                color="dark.600"
                            >
                                Having trouble connecting?
                            </Text>
                            <Text
                                fontFamily="Inter"
                                fontSize="16px"
                                fontWeight="400"
                                lineHeight="22px"
                                color="dark.600"
                            >
                                <Link
                                    color="blue.500"
                                    textDecoration="underline"
                                    fontWeight="600"
                                    onClick={() =>
                                        // @ts-ignore
                                        window.$chatwoot.toggle()
                                    }
                                >
                                    request live support.
                                </Link>
                            </Text>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                Sentry.captureMessage("dial-in-close");
                                onClose();
                            }}
                            variant="outline"
                            colorScheme="gray"
                            background="white"
                            marginRight="10px"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                Sentry.captureMessage("dial-in-connect");
                                localStorage.setItem(`NoVideo-${id}`, "true");
                                onClose();
                                onSuccess();
                            }}
                            colorScheme="dark"
                            background="black"
                            gap="10px"
                        >
                            <Image src={Checkmark} alt={""} />I successfully
                            connected!
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </ModalOverlay>
        </Modal>
    );
};
