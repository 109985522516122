import { Button, Flex, Text, keyframes } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { meetingSession, videoRoom } from "../immersionState";
import React, { memo, useEffect } from "react";
import { useAccount } from "../../hooks/useAccount";
import * as Sentry from "@sentry/react";
import { leaveMeeting } from "../video_chime/utils";

const genieEffectInWithBounce = keyframes`
    0% {
        bottom: -128px;
    }
    55% {
        bottom: 0.5%;
    }
    75% {
        bottom: -0.4%;
    }
    100% {
        bottom: 0%;
    }
`;

export const LeaveVideoModal = memo(
    ({
        isOpen,
        onClose,
        nextStepUrl,
    }: {
        isOpen: boolean;
        onClose: () => void;
        nextStepUrl: string;
    }) => {
        const [room] = useRecoilState(videoRoom);
        const [meeting, setMeeting] = useRecoilState(meetingSession);
        const account = useAccount();
        const navigate = useNavigate();

        const buttonRef = React.createRef<HTMLButtonElement>();

        // When the "Leave video" button loads, set it as focus (in case user hits "Enter")
        useEffect(() => {
            buttonRef?.current?.focus();
        }, [buttonRef]);

        const leaveVideoRoom = async () => {
            try {
                room?.disconnect();
                if(meeting){
                    leaveMeeting(meeting);
                    setMeeting(null);
                }

            } catch (e: any) {
                Sentry.captureException(e);
            }
            onClose();
        };

        if (!isOpen) {
            return null;
        }

        return (
            <Flex
                w="100%"
                h="128px"
                position="fixed"
                left="0"
                zIndex={100}
                boxShadow="0px -8px 24px 0px rgba(0, 0, 0, 0.04)"
                animation={`${genieEffectInWithBounce} 0.85s ease-in-out 0s forwards`}
            >
                <Flex
                    position="absolute"
                    w="100%"
                    h="100%"
                    background="rgba(255, 255, 255, 0.8)"
                    backdropFilter="blur(5.050000190734863px)"
                />
                <Flex
                    zIndex="101"
                    w="100%"
                    alignItems="center"
                    padding="8px 20px 16px 20px"
                >
                    <Flex w="100%" justifyContent="center">
                        <Flex direction="column" gap="16px">
                            <Text
                                fontSize="24px"
                                lineHeight="29px"
                                fontWeight="600"
                                color="dark.800"
                            >
                                Say goodbye! 👋
                            </Text>
                            <Text
                                fontSize="16px"
                                lineHeight="19px"
                                fontWeight="400"
                                color="dark.500"
                            >
                                You are about to go off video and continue on
                                your own.
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex
                        flexDirection="row"
                        marginTop="10px"
                        gap="8px"
                        alignSelf="flex-end"
                        justifyContent="flex-end"
                    >
                        <Button
                            onClick={() => {
                                onClose();
                            }}
                            variant="outline"
                            fontSize="sm"
                            fontWeight="medium"
                            colorScheme="gray"
                            background="white"
                        >
                            Cancel
                        </Button>
                        <Button
                            ref={buttonRef}
                            colorScheme="dark"
                            marginBottom="8px"
                            background="black"
                            isDisabled={!account.data}
                            fontSize="sm"
                            onClick={() => {
                                navigate(nextStepUrl);
                                leaveVideoRoom();
                            }}
                        >
                            Continue
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        );
    },
);
