import { Center, Stack, Text } from "@chakra-ui/react";
import Lottie from "react-lottie";
import LoadingAnimation from "design/assets/LoadingSquares.json";

export const Loading = () => {
    return (
        <Center
            w="100vw"
            h="100vh"
            position="fixed"
            top="0"
            left="0"
            bg="white"
        >
            <Stack>
                <Lottie
                    height="50px"
                    options={{ animationData: LoadingAnimation }}
                ></Lottie>
                <Text fontFamily="griff" fontWeight="extrabold">
                    sparkwise
                </Text>
            </Stack>
        </Center>
    );
};
