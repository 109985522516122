import { Box, Button, Flex, keyframes } from "@chakra-ui/react";

type Props = {
    onClick: () => void;
    delay?: number;
};

const broadcasting = keyframes`
0% { transform: scale(1); opacity: 0.5; }
50% { transform: scale(2); opacity: 0.25; }
100% { transform: scale(3); opacity: 0; }
`;

const appear = keyframes`
0% { opacity: 0; }
100% { opacity: 1; }
`;

const size = "20px";

export const Hotspot = (props: Props) => {
    const { onClick, delay } = props;

    return (
        <Flex
            opacity={0}
            justify="center"
            align="center"
            animation={`${appear} 0.5s linear forwards ${delay ?? 1}s`}
        >
            <Box
                position="absolute"
                h={size}
                w={size}
                p="0px"
                bgColor="#F17437"
                borderRadius={size}
                animation={`${broadcasting} 3s linear infinite 0s`}
                _hover={{ transform: "scale(1.2)" }}
            />
            <Box
                position="absolute"
                h={size}
                w={size}
                p="0px"
                bgColor="#F17437"
                borderRadius={size}
                animation={`${broadcasting} 3s linear infinite 1s`}
                _hover={{ transform: "scale(1.2)" }}
            />
            <Button
                title="Reveal next step in product tour"
                h={size}
                w={size}
                minW={size}
                p="0px"
                bgColor="#F17437"
                borderRadius={size}
                onClick={onClick}
                _hover={{ bgColor: "#F17437", transform: "scale(1.2)" }}
            />
        </Flex>
    );
};
