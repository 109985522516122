import { PostHogProvider } from "posthog-js/react";

interface PostHogProviderWrapperProps {
    apiKey?: string;
    options: {
        api_host?: string;
    };
    children: React.ReactNode;
}

const PostHogProviderWrapper: React.FC<PostHogProviderWrapperProps> = ({
    apiKey,
    options,
    children,
}: PostHogProviderWrapperProps) => {
    if (!apiKey || !options.api_host || process.env.NODE_ENV !== "production") {
        return <>{children}</>;
    }
    return (
        <PostHogProvider apiKey={apiKey} options={options}>
            {children}
        </PostHogProvider>
    );
};

export default PostHogProviderWrapper;
