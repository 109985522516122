import React from "react";
import { menuStyles } from "../JoinVideoModal";
import { MenuItem } from "@chakra-ui/react";
import { SpeakerWaveIcon } from "@heroicons/react/24/outline";

export const TestSoundMenu: React.FC<{
    testSoundPlaying: boolean;
    testSound: HTMLAudioElement;
}> = ({ testSound, testSoundPlaying }) => {
    return (
        <MenuItem
            icon={<SpeakerWaveIcon height="16px" />}
            onClick={() => {
                testSound.play();
            }}
            {...menuStyles.menuItem}
        >
            {testSoundPlaying ? "Playing..." : "Play a test sound"}
        </MenuItem>
    );
};
