import { TwilioError } from "twilio-video";

export function mediaErrorHelpMessage(error: Error) {
    if (error.name === "NotFoundError") {
        return [
            "You may have disabled camera/microphone for this browser in your system settings or have no camera/microphone connected.",
            "Please ensure that you have at least one input device connected and enabled in your system settings. If that does not work, try quitting and restarting your browser.",
        ];
    } else if (error.name === "NotAllowedError") {
        return [
            "You previously denied access to your camera/microphone or dismissed the permission dialog.",
            "Please allow app.sparkwise.co to access your camera/microphone in your browser settings.",
        ];
    } else if (error.name === "TypeError") {
        return [
            "Please contact us at support@sparkwise.co and mention 'Media Error: TypeError'.",
        ];
    } else if (error.name === "NotReadableError") {
        return [
            "Another application or browser tab might already be using your webcam. Please shut down any other app that might be interacting with your camera (Zoom, Meet, Teams) and try again.",
        ];
    } else if (error.name === "OverconstrainedError") {
        return [
            "Your camera/microphone could not satisfy the requested media constraints.",
            "The Sparkwise app may be trying to find a camera/microphone that is no longer connected to your machine.",
        ];
    } else {
        return ["Media error:" + error.name + ", " + error.message];
    }
}

export function disconnectionErrorHelpMessage(error: TwilioError) {
    if (error.code === 53001) {
        // SignalingConnectionDisconnectedError
        return [
            "Your browser failed to reconnect to the video signaling server after a network disruption.",
            "Please ensure you have a stable internet connection.",
        ];
    } else if (error.code === 53002) {
        // SignalingConnectionTimeoutError
        return [
            "The checking the video signaling server failed or the current session expired.",
            "Please reconnect to video after reloading your browser window.",
        ];
    } else if (error.code === 53205) {
        // ParticipantDuplicateIdentityError
        return [
            "Another browser joined this video connection using your identity.",
            "Please only join the video from one browser window at a time.",
        ];
    } else if (error.code === 53405) {
        // MediaConnectionError
        return [
            "Your browser failed to re-establish its media connection to the video call after a network disruption or handoff.",
            "Please ensure that you have a stable internet connection. (If your computer is behind a restrictive corporate firewall, please let us know so we can contact your IT department.",
        ];
    } else {
        return ["Disconnection error:" + error.name + ", " + error.message];
    }
}

export function handleConnectionError(
    error: TwilioError | TypeError | RangeError,
) {
    if (error instanceof TypeError) {
        return [
            "Please contact us at support@sparkwise.co and mention 'Connection Error: TypeError'.",
        ];
    } else if (error instanceof RangeError) {
        return [
            "Please contact us at support@sparkwise.co and mention 'Connection Error: RangeError'.",
        ];
    } else if (error.code === 53000) {
        // SignalingConnectionError
        return [
            "Your browser could not establish a connection to the video signaling server.",
            "Please ensure that you have a stable internet connection.",
        ];
    } else if (error.code === 53006) {
        // SignalingServerBusyError
        return [
            "The video signaling server is too busy to accept new connections.",
            "Please try joining video again in a moment.",
        ];
    } else if (error.code === 53105) {
        // RoomMaxParticipantsExceededError
        return [
            "Too many participants have joined video for this session.",
            "Please try joining video again in a moment.",
        ];
    } else if (error.code === 53106) {
        // RoomNotFoundError
        return [
            "A problem occurred when attempting to connect to video for this session.",
            'Please use the "Need Help?" menu item in the right-hand corner to let us know.',
        ];
    } else if (error.code === 53405) {
        // MediaConnectionError
        return [
            "Your browser failed to establish a media connection with the video server.",
            "Please ensure that you have a stable internet connection. (If your computer is behind a restrictive corporate firewall, please let us know so we can contact your IT department.",
        ];
    } else {
        return ["Connection error:" + error.name + ", " + error.message];
    }
}
