import { VideoTileState } from "amazon-chime-sdk-js";
import { VideoTrack } from "./VideoTrack";
import { AudioTrack } from "./AudioTrack";

export const Publication = ({
    publication,
    local,
}: {
    publication: VideoTileState;
    local: boolean;
}) => {
   
    return <VideoTrack publication={publication} local={local}></VideoTrack>;
};
