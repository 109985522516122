import { MenuItemOption, MenuOptionGroup } from "@chakra-ui/react";
import { menuStyles } from "../JoinVideoModal";
import { useLocalVideoBlur } from "../hooks/useLocalVideoBlur";

export const BackgroundBlurSelector = () => {
    const { isBackgroundBlur, toggleBackgroundBlur, canBlur } =
        useLocalVideoBlur();
    if (!canBlur) {
        return null;
    }
    return (
        <MenuOptionGroup
            title="Effects"
            type="checkbox"
            defaultChecked={isBackgroundBlur}
            {...menuStyles.menuOptionGroup}
        >
            <MenuItemOption
                key={"BackgroundBlur"}
                isChecked={isBackgroundBlur}
                onClick={() => toggleBackgroundBlur()}
                {...menuStyles.menuItem}
                type={"checkbox"}
            >
                Blur my background
            </MenuItemOption>
        </MenuOptionGroup>
    );
};
