import { Button, Flex, Image, ToastId, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { meetingSession } from "../immersionState";
import { IMMERSION_SIZES } from "../Immersion";
import BadInternet from "design/assets/BadInternet.svg";
import { usePostHog } from "posthog-js/react";
import { throttle } from "../../utils";
import {
    AudioVideoObserver,
    MeetingSessionStatusCode,
} from "amazon-chime-sdk-js";

export const NetworkQualityWarningChime = () => {
    const meetingManager = useRecoilValue(meetingSession);
    const audioVideo = meetingManager?.audioVideo;
    const [networkQuality, setNetworkQuality] = useState(5);
    const toast = useToast();
    const toastIdRef = React.useRef<ToastId>();
    const [suppressWarning, setSuppressWarning] = useState(false);
    const posthog = usePostHog();

    useEffect(() => {
        if (!audioVideo) {
            return;
        }

        const observer: AudioVideoObserver = {
            connectionDidBecomePoor: () => {
                setNetworkQuality(1);
            },
            connectionDidSuggestStopVideo: () => {
                setNetworkQuality(3);
            },
            videoSendDidBecomeUnavailable: () => {
                setNetworkQuality(2);
            },
            connectionDidBecomeGood: () => {
                setNetworkQuality(5);
            },
            audioVideoDidStop: (sessionStatus: any) => {
                if (
                    sessionStatus.statusCode() === MeetingSessionStatusCode.Left
                ) {
                    setNetworkQuality(0);
                }
            },
        };

        const onReload = () => {
            audioVideo.removeObserver(observer);
        };

        window.addEventListener("beforeunload", onReload);
        audioVideo.addObserver(observer);

        return () => {
            audioVideo.removeObserver(observer);
            window.removeEventListener("beforeunload", onReload);
        };
    }, [audioVideo]);

    const handleSuppressWarning = () => {
        if (toastIdRef.current) {
            toast.close(toastIdRef.current);
        }
        setSuppressWarning(true);
    };

    useEffect(
        throttle(() => {
            if (!suppressWarning && networkQuality <= 3) {
                posthog.capture("network_quality_error", {
                    networkQuality: networkQuality,
                });
                let networkMessage = "";

                switch (networkQuality) {
                    case 0:
                        networkMessage =
                            "Connection lost, please check your network";
                        break;
                    case 1:
                        networkMessage = "Your internet connection is unstable";
                        break;
                    case 2:
                        networkMessage =
                            "Video stopped due to unstable internet connection";
                        break;
                    case 3:
                        networkMessage =
                            "Your internet connection is unstable, you may stop your video until the connection is stable";
                        break;
                }
                toastIdRef.current = toast({
                    position: "top",
                    render: () => (
                        <Flex
                            flexDir="row"
                            width="max-content"
                            background="rgba(60, 62, 68, .7)"
                            top={`calc(${IMMERSION_SIZES.HEADER_HEIGHT} + 4px)`}
                            position="relative"
                            padding="12px 8px"
                            borderRadius="4px"
                            color="white"
                            gap={2}
                        >
                            <Image src={BadInternet} mr="8px" />
                            <span className="popupText">
                                Your internet connection is unstable
                            </span>
                            <Button
                                onClick={handleSuppressWarning}
                                variant="link"
                                colorScheme="white"
                                textDecoration="underline"
                                fontWeight="normal"
                            >
                                Don't show again
                            </Button>
                        </Flex>
                    ),
                });
            }
        }, 600000),
        [networkQuality, suppressWarning, toastIdRef, posthog, toast],
    );

    return null;
};
