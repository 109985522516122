import React from "react";

export const useAudioLevel = (audioTrack?: MediaStreamTrack | null) => {
    const audioContextRef = React.useRef<AudioContext | null>(null);
    const analyserRef = React.useRef<AnalyserNode | null>(null);
    const animationFrameRef = React.useRef<number | null>(null);

    const [audioLevel, setAudioLevel] = React.useState<number>(0);

    const measureAudioLevel = React.useCallback(() => {
        const analyser = analyserRef.current;
        if (!analyser) return;

        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(dataArray);
        let sum = dataArray.reduce((a, b) => a + b, 0);
        let average = sum / dataArray.length;
        setAudioLevel(average / 10);

        // Continue the animation loop
        animationFrameRef.current = requestAnimationFrame(measureAudioLevel);
    }, []);
    const setupAudioAnalyser = React.useCallback(
        (audioTrack: MediaStreamTrack) => {
            const audioContext = new window.AudioContext();
            const analyser = audioContext.createAnalyser();
            const source = audioContext.createMediaStreamSource(
                new MediaStream([audioTrack]),
            );
            source.connect(analyser);
            analyser.fftSize = 256;
            audioContextRef.current = audioContext;
            analyserRef.current = analyser;
            measureAudioLevel();
        },
        [measureAudioLevel],
    );
    // useEffect for audio level
    React.useEffect(() => {
        if (audioTrack) {
            setupAudioAnalyser(audioTrack);
        } else {
            setAudioLevel(0);
        }

        return () => {
            if (audioContextRef.current) {
                // audioContextRef.current.close();
            }
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [audioTrack, setupAudioAnalyser]);
    return audioLevel;
};
