import { useAuth0 } from "@auth0/auth0-react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { WrenchScrewdriverIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { maintenanceWarningDismissed as maintenanceWarningDismissedState } from "../appState";
import { AppVersion } from "../components/AppVersion";
import { useAccount } from "../hooks/useAccount";
import { useAppReady, useIsMaintenanceWindow } from "../hooks/useAppReady";
import { trpc } from "../hooks/useTRPC";
import { Header, Tabs } from "./Header";
import AvailableSessions from "./home/AvailableSessions";
import RecentSessions from "./home/RecentSessions";
import SoloCatchups from "./home/SoloCatchups";
import UpcomingSessions from "./home/UpcomingSessions";

export default function Home() {
    const guard = useAppReady();
    const { user } = useAuth0();
    const isMaintenanceWindow = useIsMaintenanceWindow();
    const [maintenanceWarningDismissed, setMaintenanceWarningDismissed] =
        useRecoilState(maintenanceWarningDismissedState);
    const showMaintenanceWindow =
        isMaintenanceWindow && !maintenanceWarningDismissed;

    const { mutateAsync: storeAvatar } =
        trpc.account.saveAvatarUrl.useMutation();

    useEffect(() => {
        if (user && user.picture) {
            storeAvatar({ url: user.picture });
        }
    }, [user]);

    return (
        guard || (
            <Flex flexDir="column">
                <Header page={Tabs.HomeTab} />
                {showMaintenanceWindow && (
                    <Flex
                        width="fit-content"
                        height="64px"
                        margin="auto"
                        bg="info.-5"
                        justifyContent="center"
                        alignItems="center"
                        padding="20px"
                        borderRadius="12px"
                    >
                        <WrenchScrewdriverIcon
                            height="24px"
                            width="24px"
                            color="#FFDB5C"
                        />
                        <Flex marginLeft="16px" marginRight="28px">
                            <Text
                                fontFamily="Proxima Nova"
                                fontSize="18px"
                                lineHeight="24px"
                            >
                                Sparkwise is scheduled for weekly system
                                maintenance from&nbsp;
                                <strong style={{ fontFamily: "Proxima Nova" }}>
                                    3:00-4:30pm US/Pacific
                                </strong>
                                .
                            </Text>
                        </Flex>
                        <Button
                            type="button"
                            variant="unstyled"
                            color="gray.900"
                            fontFamily="Proxima Nova"
                            fontSize="14px"
                            lineHeight="22px"
                            fontWeight="600"
                            onClick={() => setMaintenanceWarningDismissed(true)}
                        >
                            Got it
                        </Button>
                    </Flex>
                )}
                <Flex
                    flexDir="column"
                    maxW="6xl"
                    margin="auto"
                    p="10"
                    width="100%"
                >
                    <Greeting />
                </Flex>
                <Flex
                    flexDir="column"
                    margin="auto"
                    p="10"
                    maxWidth="5xl"
                    width="100%"
                    gap="10"
                >
                    <AvailableSessions />
                    <SoloCatchups />
                    <UpcomingSessions />
                    <RecentSessions />
                </Flex>
                <AppVersion />
            </Flex>
        )
    );
}

const Greeting = () => {
    const account = useAccount();
    return (
        <Flex
            flexDirection="row"
            marginTop="32px"
            justifyContent="space-between"
        >
            <Text fontSize="2xl" fontWeight="semibold" alignSelf="flex-start">
                Welcome, {account.data?.firstName} 👋
            </Text>
        </Flex>
    );
};
