import { Box, Flex, HStack, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { useTRPC } from "../../hooks/useTRPC";

import { DateTime } from "luxon";
import { extractImmersionTitle } from "../utils";
import {
    CalendarIcon,
    ChevronUpIcon,
    ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";

const UpcomingSessions = () => {
    const trpc = useTRPC();
    const [take, setTake] = useState(3);
    const { data: upcomingSessions, isLoading } =
        trpc.sessions.upcomingSessions.useQuery();
    const [expanded, setExpanded] = useState(false);

    if (isLoading) {
        return (
            <VStack width="full" alignItems="start">
                <Text fontSize="md" fontWeight="semibold">
                    Upcoming Sessions
                </Text>
                <Flex
                    bg="gray.50"
                    width="full"
                    height="40"
                    borderRadius="12px"
                />
            </VStack>
        );
    }

    const handleShowHideClick = () => {
        const willBeExpanded = !expanded;
        setExpanded(willBeExpanded);
        setTake(willBeExpanded ? 1000 : 3);
    };

    return (
        <Flex direction="column" width="full" gap="4">
            <HStack justifyContent="space-between" width="full" mb="2">
                <Text fontSize="md" fontWeight="semibold">
                    Upcoming Sessions
                </Text>
                <HStack
                    display={
                        (upcomingSessions?.length ?? 0) > 3 ? "flex" : "none"
                    }
                    color="gray.600"
                    padding="8px 12px"
                    borderRadius="8px"
                    _hover={{
                        cursor: "pointer",
                        color: "gray.900",
                        bg: "gray.background",
                    }}
                    onClick={handleShowHideClick}
                >
                    <Text fontSize="sm">{expanded ? "Hide" : "Show"} all</Text>
                    {expanded ? (
                        <ChevronUpIcon width="14px" />
                    ) : (
                        <ChevronDownIcon width="14px" />
                    )}
                </HStack>
            </HStack>
            {(upcomingSessions?.length ?? 0) === 0 && (
                <Flex
                    bg="gray.50"
                    width="full"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="12px"
                    direction="column"
                    color="gray.700"
                    py="6"
                >
                    <Text fontSize="lg" fontWeight="semibold" color="gray.500">
                        You don't have any upcoming sessions.
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                        This is where you will find all your upcoming immersions,
                        so you don't miss any!
                    </Text>
                </Flex>
            )}

            <SimpleGrid columns={[1, null, 2, null, 3]} spacing="16px">
                {upcomingSessions?.slice(0, take)?.map((session) =>
                    !session.time ? null : (
                        <HStack
                            key={session.id}
                            p="16px 20px"
                            height="100px"
                            width="full"
                            alignItems="flex-start"
                            borderRadius="12px"
                            borderColor="gray.100"
                            borderWidth="thin"
                            shadow="sm"
                            _hover={{
                                shadow: "lg",
                            }}
                        >
                            <Flex direction="column">
                                <Flex gap="2" mb="1.5" alignItems="center">
                                    <CalendarIcon height="16px" />
                                    <Text fontSize="14px" fontWeight="semibold">
                                        {DateTime.fromISO(
                                            session.time,
                                        ).toLocaleString({
                                            weekday: "long",
                                            month: "long",
                                            day: "numeric",
                                            hour: "numeric",
                                            minute: "2-digit",
                                        })}
                                    </Text>
                                </Flex>
                                <Text fontSize="14px" color="dark.500">
                                    {extractImmersionTitle(session.immersion)}
                                </Text>
                            </Flex>
                        </HStack>
                    ),
                )}
            </SimpleGrid>
        </Flex>
    );
};

export default UpcomingSessions;
