import {
    Button,
    Center,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Stack,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Rocket from "design/assets/Rocket.svg";
import { memo, useEffect } from "react";

export const SuccessfulCompletionModal = memo(() => {
    const { user } = useAuth0();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        onOpen();
    }, []);

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={true}
                size="sm"
            >
                <ModalOverlay></ModalOverlay>
                <ModalContent maxWidth="600px" p={0}>
                    <ModalBody pt={"40px"} pl={"40px"} pr={"40px"} pb={"32px"}>
                        <Flex flexDirection="column" alignItems="center">
                            <Center>
                                <Stack w="500px" alignItems="center">
                                    <Image
                                        h="185px"
                                        w="194px"
                                        src={Rocket}
                                    ></Image>
                                    <Text
                                        fontSize="48px"
                                        fontWeight="600"
                                        fontFamily="Griff"
                                        color="#FAB400"
                                        mb={"40px"}
                                        lineHeight={"48px"}
                                    >
                                        Congrats, {user?.given_name}!
                                    </Text>
                                    <Text
                                        textAlign="center"
                                        fontWeight="400"
                                        fontSize="18px"
                                        color="gray.500"
                                        marginBottom="80px"
                                        lineHeight={"24px"}
                                    >
                                        You have successfully completed your
                                        session.
                                    </Text>
                                    <Flex
                                        flexDirection="column"
                                        width="100%"
                                        gap="8px"
                                        alignSelf="flex-end"
                                        alignItems={"center"}
                                    >
                                        <Button
                                            borderRadius="8px"
                                            w="165px"
                                            size="lg"
                                            fontSize="14px"
                                            fontWeight={"600"}
                                            tabIndex={0}
                                            onClick={() => {
                                                onClose();
                                            }}
                                            bg={"gray.900"}
                                            color={"white"}
                                            _hover={{
                                                background: "gray.700",
                                                color: "gray.0",
                                            }}
                                        >
                                            View Session Recap
                                        </Button>
                                        <Button
                                            variant="outline"
                                            borderRadius="8px"
                                            w="165px"
                                            size="lg"
                                            fontSize="14px"
                                            fontWeight={"600"}
                                            marginTop="2"
                                            colorScheme="gray"
                                            tabIndex={-1}
                                            background="white"
                                            onClick={() =>
                                                navigate(`/dashboard`)
                                            }
                                        >
                                            Go to dashboard
                                        </Button>
                                    </Flex>
                                </Stack>
                            </Center>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
});
