import { Flex } from "@chakra-ui/react";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import * as Sentry from "@sentry/react";
import { DeepChat } from "deep-chat-react";
import LoadingAnimation from "design/assets/LoadingSquares.json";
import { useEffect, useMemo, useRef, useState } from "react";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";
import chatbotAvatarUrl from "../../../assets/chatbot_avatar.svg";
import { CHATBOT_STYLES } from "../../../Constants";
import { useAccount } from "../../../hooks/useAccount";
import { getCookie } from "../../../utils";
import { useStepContent } from "../../hooks/useSessionContent";
import { IMMERSION_SIZES } from "../../Immersion";
import { HeaderType, ResourceHeader } from "./ResourceHeader";

interface Props {
    systemId: string;
    headerType: HeaderType;
    currentlyViewing?: any;
}

type MessageContent = {
    role?: string;
    text?: string;
    html?: string;
};

const loadingPanel = (
    <Flex
        h="100%"
        w="100%"
        justifyContent="center"
        alignItems="center"
        bg="linear-gradient(92.4deg, rgba(140, 119, 182, 0.06) 3.6%, rgba(89, 120, 166, 0.06) 93.77%), #FFFFFF"
    >
        <Lottie height="50px" options={{ animationData: LoadingAnimation }} />
    </Flex>
);

export const ChatbotResource = (props: Props) => {
    // -----
    // Step content data
    // -----
    const { systemId, headerType, currentlyViewing } = props;
    const { variables } = useStepContent();
    const hasOptionalHeader = !!variables?.variable2;
    const stepNumber = parseInt(
        useParams<{ stepNumber: string }>().stepNumber || "0",
    );
    const account = useAccount();
    const {
        STEP_MARGIN_TOP,
        STEP_MARGIN_BOTTOM,
        HEADER_HEIGHT,
        FOOTER_HEIGHT,
    } = IMMERSION_SIZES;
    const RESOURCE_HEADER_HEIGHT = "48px";
    const VERTICAL_BORDER_HEIGHTS = "6px";
    const OPTIONAL_HEADER_HEIGHT = "37px";
    const maxH = `calc(100vh - ${STEP_MARGIN_TOP} - ${STEP_MARGIN_BOTTOM} - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT} - ${RESOURCE_HEADER_HEIGHT} + ${VERTICAL_BORDER_HEIGHTS} - ${
        hasOptionalHeader ? OPTIONAL_HEADER_HEIGHT : "0px"
    })`;
    const avatarUrl =
        currentlyViewing?.avatarUrl || account?.data?.avatarUrl || undefined;

    // -----
    // DeepChat data
    // -----
    const abortController = useRef<AbortController | null>(null);
    const deepChatSignalsRef = useRef<any>(null);
    const [messages, setMessages] = useState<MessageContent[]>([]);
    const [dataIsLoading, setDataIsLoading] = useState(true);

    // Set different styles for solo vs nth participant chatbot
    const isNthParticipantStep = headerType === "readonly nth chatbot";
    const borderStyles = {
        border: isNthParticipantStep
            ? "1px solid #E8EAEE"
            : "2px solid transparent",
        borderRadius: "12px",
        background: isNthParticipantStep
            ? "none"
            : "linear-gradient(#FFF, #FFF) padding-box, linear-gradient(32deg, rgba(234,243,255,1) 0%, rgba(234,243,255,1) 32%, rgba(221,204,255,1) 48%, rgba(234,243,255,1) 64%, rgba(234,243,255,1) 100%), border-box",
    };

    /*
        DeepChat component must be memoized to prevent a last message loading bug
        when navigating between chatbots after interrupting the message stream.

        When the DeepChat component is memoized, the request handler remains attached
        to the component as long as the dependencies remain the same. This is important
        in order to keep the abort controller and signal handlers carried over while navigating,
        as long as it is indeed the same chatbot.
    */
    const deepChatComponent = useMemo(
        () => (
            <DeepChat
                // key is REQUIRED to ensure the correct systemId is used for API calls
                key={systemId}
                style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: maxH,
                    border: "none",
                    background:
                        "linear-gradient(92.4deg, rgba(140, 119, 182, 0.06) 3.6%, rgba(89, 120, 166, 0.06) 93.77%), #FFFFFF",
                    scrollbarColor: "#D9D2F9 transparent",
                    scrollbarWidth: "thin",
                }}
                messageStyles={CHATBOT_STYLES.getMessageStyles({
                    isNthParticipantStep,
                })}
                avatars={CHATBOT_STYLES.getAvatarStyles({
                    chatbotAvatarUrl,
                    userAvatarUrl: avatarUrl,
                })}
                names={CHATBOT_STYLES.getNameStyles({
                    currentlyViewingName: isNthParticipantStep
                        ? currentlyViewing?.displayName
                        : "You",
                })}
                submitButtonStyles={CHATBOT_STYLES.getSubmitButtonStyles({
                    isNthParticipantStep,
                })}
                textInput={CHATBOT_STYLES.getTextInputStyles({
                    isNthParticipantStep,
                })}
                history={messages} // initialMessages is used on first load / re-render
                connect={{
                    stream: true,
                    // request is used for all subsequent messaging (user added messages, ai responses)
                    handler: (body, signals) => {
                        // body is the user messages, signals is the DeepChat signals object
                        try {
                            // Each DeepChat instance will have it's own AbortController and Signals object,
                            // which are kept in refs so we can use them in effects.
                            if (abortController.current === null) {
                                abortController.current = new AbortController();
                            }
                            if (deepChatSignalsRef.current === null) {
                                deepChatSignalsRef.current = signals;
                            }
                            fetchEventSource(
                                `${process.env
                                    .REACT_APP_API_ROOT!}/app/chatbot/run`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${getCookie(
                                            document.cookie,
                                            "auth0Token",
                                        )}`,
                                        "Content-Type": "application/json",
                                    },
                                    method: "POST",
                                    body: JSON.stringify({
                                        threadId: systemId,
                                        ...body,
                                    }),
                                    // Attaching the component-instance-specific abort controller here
                                    // allows us to cancel the stream server-side as well when it's
                                    // aborted on the client side.
                                    signal: abortController.current.signal,
                                    async onopen(response) {
                                        // triggered when the stream is opened, server is sending events
                                        if (response.ok) {
                                            signals.onOpen(); // stops the loading bubble in DeepChat
                                        } else {
                                            Sentry.captureException(
                                                `Error streaming chatbot message for thread ${systemId}`,
                                            );
                                            signals.onResponse({
                                                error: "error",
                                            }); // displays an error message in DeepChat
                                        }
                                    },
                                    onmessage(message) {
                                        const messageJson = JSON.parse(
                                            message.data,
                                        );
                                        signals.onResponse(messageJson); // adds text into the message bubble in DeepChat
                                    },
                                    onerror(message) {
                                        Sentry.captureException(
                                            `Error streaming chatbot message for thread ${systemId}: ${message}`,
                                        );
                                        signals.onResponse({
                                            error: message,
                                        }); // displays an error message in DeepChat
                                    },
                                    onclose() {
                                        signals.onClose(); // The stop button will be changed back to submit button
                                    },
                                },
                            );
                            // triggered when the user clicks the stop button
                            signals.stopClicked.listener = () => {
                                abortController.current?.abort(); // abort the stream both client and server side
                            };
                        } catch (e) {
                            signals.onResponse({ error: "error" }); // displays an error message in DeepChat
                        }
                    },
                }}
                requestBodyLimits={{
                    totalMessagesMaxCharLength: 5000,
                }}
                // demo={true} // Use for testing to save excessive API calls (and comment out the `request` prop above)
            />
        ),
        [systemId, messages, avatarUrl, isNthParticipantStep, maxH],
    );

    // Used to fetch initial messages (will reset messages on systemId change)
    useEffect(() => {
        // reset messages before fetching to avoid flicker of old messages on initial load
        setMessages([]);
        let calledRetry = false;
        const fetchMessages = async (threadId: string) => {
            setDataIsLoading(true);
            const response = await fetch(
                process.env.REACT_APP_API_ROOT! +
                    `/app/chatbot/messages/${threadId}`,
                {
                    credentials: "include",
                    headers: {
                        Authorization: `Bearer ${getCookie(
                            document.cookie,
                            "auth0Token",
                        )}`,
                    },
                },
            );
            if (!response.ok) {
                Sentry.captureMessage(
                    `Failed to fetch messages for thread ${threadId}`,
                );
            }
            const data = await response.json();
            setTimeout(() => {
                setDataIsLoading(false);
                setMessages(data);
            }, 500);

            // Peek the last message. If the text is empty, that means we tried to fetch a message that has been created
            // but the run has not yet finished. In our case, this would happen because the stream was aborted and the run
            // cancelled, but the cancel has not completed at the time of fetching.
            // In that case, set the messages with the existing data for now, replacing the empty message with a loading state,
            // and trigger another fetch after a short wait. The run should be cancelled by then, and the empty message updated.
            const lastMessage = data[data.length - 1];
            if (lastMessage && !lastMessage.text && !calledRetry) {
                calledRetry = true;
                const loadingMessageHTML = `<div class="message-bubble ai-message ai-message-text loading-message-text" style="height: 20px; background-color: unset; margin-top: 4px; margin-bottom: 12px; display: flex; justify-content: flex-start; align-items: center; padding-left: 10px; --message-dots-color: #848484; --message-dots-color-fade: #55555533;"><div class="dots-flashing"></div></div>`; // this is DeepChat's loading message, with our custom styles applied (see: CHATBOT_STYLES.getMessageStyles().loading)
                setMessages([
                    ...data.slice(0, -1),
                    {
                        role: lastMessage.role,
                        html: loadingMessageHTML,
                    },
                ]);
                setTimeout(() => fetchMessages(threadId), 1000);
                return;
            }
        };

        if (!systemId) return;

        fetchMessages(systemId);
    }, [systemId]);

    // Abort any ongoing stream when the step number changes, or when the component unmounts
    useEffect(() => {
        return () => {
            if (
                abortController.current &&
                abortController.current.signal?.aborted === false &&
                deepChatSignalsRef.current
            ) {
                abortController.current.abort();
                deepChatSignalsRef.current.onClose();
            }
            abortController.current = null;
            deepChatSignalsRef.current = null;
        };
    }, [stepNumber]);

    return (
        <Flex flex="1" direction="column" overflow="hidden" {...borderStyles}>
            <ResourceHeader
                headerType={headerType}
                resourceType="chatbot"
                {...(currentlyViewing && { currentlyViewing })}
            />
            <Flex h="100%" w="100%" overflowY="hidden">
                {dataIsLoading ? loadingPanel : deepChatComponent}
            </Flex>
        </Flex>
    );
};
