import { Flex, Image } from "@chakra-ui/react";
import VideoButtonOff from "design/assets/VideoButtonOff.svg";
import VideoButtonOn from "design/assets/VideoButtonOn.svg";
import React from "react";

export const CameraButton: React.FC<{
    isActive: boolean;
}> = ({ isActive }) => {
    return (
        <Flex
            shrink={0}
            borderRadius="4px"
            overflowX="hidden"
            aria-label="Video Connect Options"
        >
            <Image
                h="20px"
                src={isActive ? VideoButtonOn : VideoButtonOff}
                alt={isActive ? "Disable camera" : "Enable camera"}
            />
        </Flex>
    );
};
