import { Center, Image, Stack, Text } from "@chakra-ui/react";
import Stars from "design/assets/Stars.png";

export const CancellationConfirmation = () => {
    return (
        <Center h="100vh" w="100vw">
            <Stack w="500px" alignItems="center">
                <Image h="120px" w="120px" src={Stars}></Image>
                <Text fontSize="60px" fontWeight="semibold" fontFamily="Griff">
                    See you later!
                </Text>
                <Text
                    textAlign="center"
                    fontWeight="400"
                    fontSize="18px"
                    color="gray.500"
                >
                    You may now close this tab.
                </Text>
            </Stack>
        </Center>
    );
};
