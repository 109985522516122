import {
    Avatar,
    Button,
    Flex,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
} from "@chakra-ui/react";
import {
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { useParticipants, useSelfData } from "../../hooks/useImmersion";
import { CurrentlyViewing } from "./ResourceHeader";

interface Props {
    currentlyViewing: CurrentlyViewing;
}

export const NthParticipantDropdown = (props: Props) => {
    const { currentlyViewing } = props;
    const participants = useParticipants();
    const self = useSelfData();

    return (
        <Menu>
            {({ isOpen }) => (
                <>
                    <MenuButton
                        as={Button}
                        h="28px"
                        p="4px"
                        bg="white"
                        borderWidth="1px"
                        borderColor="gray.100"
                    >
                        <Flex alignItems="center" gap="12px">
                            <Flex alignItems="center">
                                <Avatar
                                    src={
                                        currentlyViewing?.avatarUrl || undefined
                                    }
                                    name={`${currentlyViewing?.avatarName}`}
                                    size="xs"
                                    height="20px"
                                    width="20px"
                                    borderRadius="25%"
                                />
                                <Text
                                    fontSize="sm"
                                    fontWeight="normal"
                                    color="dark.800"
                                    ml={2}
                                >
                                    {currentlyViewing?.displayName}
                                </Text>
                            </Flex>
                            <Icon
                                as={isOpen ? ChevronUpIcon : ChevronDownIcon}
                                h="14px"
                                w="14px"
                                m="4px"
                                strokeWidth={2}
                                color="gray.500"
                            />
                        </Flex>
                    </MenuButton>
                    <MenuList borderColor="gray.100" p="0px" overflow="hidden">
                        {participants.map((participant, idx) => {
                            const isSelected =
                                currentlyViewing?.selectedParticipantIndex ===
                                idx;
                            const isSelf =
                                participant.id === self?.sessionParticipantUuid;
                            return (
                                <MenuItem
                                    key={participant.id}
                                    h="36px"
                                    p="8px 6px"
                                    onClick={(e) =>
                                        currentlyViewing?.updateSelectedParticipant?.(
                                            participant.id,
                                        )
                                    }
                                    _hover={{ background: "gray.50" }}
                                >
                                    <Icon
                                        as={CheckIcon}
                                        h="10px"
                                        w="10px"
                                        mr="6px"
                                        strokeWidth={2}
                                        color="blue.500"
                                        visibility={
                                            isSelected ? "visible" : "hidden"
                                        }
                                    />
                                    <Avatar
                                        src={
                                            participant?.user?.avatarUrl ||
                                            undefined
                                        }
                                        name={`${participant?.user?.firstName} ${participant?.user?.lastName}`}
                                        size="xs"
                                        height="20px"
                                        width="20px"
                                        borderRadius="25%"
                                    />
                                    <Text
                                        fontSize="sm"
                                        fontWeight={
                                            isSelected ? "semibold" : "normal"
                                        }
                                        color="dark.800"
                                        ml={2}
                                    >
                                        {isSelf
                                            ? "You"
                                            : participant?.user?.firstName}
                                    </Text>
                                </MenuItem>
                            );
                        })}
                    </MenuList>
                </>
            )}
        </Menu>
    );
};
