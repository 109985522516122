import { useRoom as useLiveblocksRoom } from "../liveblocks.config";
import { useSetRecoilState } from "recoil";
import { liveblocksRoom as liveblocksRoomState } from "./immersionState";
import { useEffect } from "react";

export const RoomSetter = (props: { children: React.ReactNode}) => {
    const { children } = props;
    const liveblocksRoom = useLiveblocksRoom();
    const setLiveblocksRoom = useSetRecoilState(liveblocksRoomState);

    useEffect(() => {
        if (!liveblocksRoom) return;

        setLiveblocksRoom(liveblocksRoom);

    }, [liveblocksRoom]);

    return children;
}