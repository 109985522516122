import { useStepContent } from "../hooks/useSessionContent";
import { RichtextCardResource } from "./resources/RichtextCardResource";

export const RichtextCard = () => {
    const { resources } = useStepContent();
    const content = resources?.resource1?.[0]?.altText ?? "";

    const items = content.split("\n").reduce((acc, cur) => {
        if (cur === "") {
            return acc;
        }
        const itemWithNumberRemoved = cur.replace(/^\d\.\s*/, "");
        acc.push(itemWithNumberRemoved);
        return acc;
    }, [] as string[]);


    return <RichtextCardResource content={items} />;
};
