import * as Sentry from "@sentry/react";
import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useParticipantsAsLoader } from "../hooks/useImmersion";
import {
    liveblocksRoom as liveblocksRoomState,
    meetingSession,
    videoRoom,
} from "../immersionState";
import { VideoRoom } from "../video/VideoRoom";
import { leaveMeeting } from "../video_chime/utils";
import { VideoRoomChime } from "../video_chime/VideoRoomChime";
import { Room } from "twilio-video";
import { DefaultMeetingSession } from "amazon-chime-sdk-js";

export const VideoSidebar = () => {
    const [room, setRoom] = useRecoilState(videoRoom);
    const [meeting, setMeeting] = useRecoilState(meetingSession);
    const liveblocksRoom = useRecoilValue(liveblocksRoomState);
    const sessionParticipants = useParticipantsAsLoader();

    /**
     * Disconnects from the current room.
     */
    const disconnectRoom = async (
        room: Room | null,
        meeting: DefaultMeetingSession | null,
    ): Promise<boolean> => {
        Sentry.captureMessage("Leaving room.");
        if (room) {
            room?.localParticipant.audioTracks.forEach((track) =>
                track.track.stop(),
            );
            room?.localParticipant.videoTracks.forEach((track) =>
                track.track.stop(),
            );
            room?.disconnect();
        }
        if (meeting) {
            await leaveMeeting(meeting);
        }
        return !!room || !!meeting;
    };

    /**
     * Clears the current room and meeting.
     */
    const clearMeeting = (
        room: Room | null,
        meeting: DefaultMeetingSession | null,
    ) => {
        if (room) {
            setRoom(null);
        }
        if (meeting) {
            setMeeting(null);
        }
        return !!room || !!meeting;
    };

    // Leave the room when the component unmounts
    useEffect(() => {
        return () => {
            console.debug(`Disconnecting room.`);
            disconnectRoom(room, meeting).then((disconnected) => {
                if (disconnected) {
                    console.debug(`Room disconnected. Will clear meeting`);
                }
                const cleared = clearMeeting(room, meeting);
                if (cleared) {
                    console.debug(`All cleared!`);
                }
            });
        };
    }, [room, meeting]);

    /**
     * Hook on beforeunload event to leave the room.
     */
    useEffect(() => {
        const fn = () => disconnectRoom(room, meeting);
        window.addEventListener("beforeunload", fn);
        return () => {
            window.removeEventListener("beforeunload", fn);
        };
    }, [room, meeting]);

    useEffect(() => {
        if (!liveblocksRoom || !sessionParticipants) return;

        return liveblocksRoom.subscribe("others", (others, event) => {
            if (event.type === "enter") {
                sessionParticipants.refetch();
            }
        });
    }, [liveblocksRoom]);

    if (room) {
        return <VideoRoom room={room}></VideoRoom>;
    } else if (meeting) {
        return <VideoRoomChime></VideoRoomChime>;
    }
    return null;
};
