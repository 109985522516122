import { Participant as TwilioParticipant } from "twilio-video";
import usePublications from "../hooks/usePublications";
import { AspectRatio, Box, Flex, Image, Text, Tooltip } from "@chakra-ui/react";
import { Publication } from "./Publication";
import BlankAvatarGrey from "design/assets/BlankAvatarGrey.svg";
import { useEffect, useState } from "react";
import MicOff from "design/assets/MicOff.svg";
import BadInternet from "design/assets/BadInternet.svg";
import * as Sentry from "@sentry/react";

export const Participant = ({
    local,
    participant,
    firstName,
    lastName,
    background,
    width,
}: {
    local: boolean;
    participant?: TwilioParticipant;
    firstName: string;
    lastName: string;
    background: string;
    width: number;
}) => {
    const publications = usePublications(participant);
    const [audioOn, setAudioOn] = useState(false);
    const [networkQuality, setNetworkQuality] = useState(5);

    useEffect(() => {
        participant?.on("networkQualityLevelChanged", setNetworkQuality);
        return () => {
            participant?.off("networkQualityLevelChanged", setNetworkQuality);
        };
    }, [participant, setNetworkQuality]);

    useEffect(() => {
        if (local) {
            Sentry.addBreadcrumb({
                message: `Network quality: ${networkQuality}`,
                level: "info",
            });
        }
    }, [local, networkQuality]);

    useEffect(() => {
        if (local) {
            const disabledSubscriptions: (() => void)[] = [];
            const warningSubscriptions: ((name: string) => void)[] = [];
            const warningsClearedSubscriptions: (() => void)[] = [];

            for (const publication of publications) {
                const trackDisabled = () => {
                    Sentry.captureMessage(
                        `track ${publication.track?.name} disabled`,
                    );
                };
                disabledSubscriptions.push(trackDisabled);
                publication.on("trackDisabled", trackDisabled);

                const trackWarning = (name: string) => {
                    if (name === "recording-media-lost") {
                        Sentry.captureMessage(
                            `track ${publication.track?.name} is not recording`,
                        );
                    }
                };
                warningSubscriptions.push(trackWarning);
                publication.on("warning", trackWarning);

                const trackWarningsCleared = () => {
                    Sentry.captureMessage(
                        `track ${publication.track?.name} warnings have cleared.`,
                    );
                };
                publication.on("warningsCleared", trackWarningsCleared);
                warningsClearedSubscriptions.push(trackWarningsCleared);
            }
            return () => {
                for (let i = 0; i < publications.length; i++) {
                    publications[i].off(
                        "trackDisabled",
                        disabledSubscriptions[i],
                    );
                    publications[i].off("warning", warningSubscriptions[i]);
                    publications[i].off(
                        "warningsCleared",
                        warningsClearedSubscriptions[i],
                    );
                }
            };
        }
    }, [publications, local]);

    let bg, border, fallback;
    if (participant) {
        bg = background;
        fallback = (
            <Text fontSize="6xl">{`${firstName[0]}${lastName[0]}`}</Text>
        );
    } else {
        bg = "white";
        border = "1px dashed rgba(0,0,0,.2)";
        fallback = <Image w="40%" src={BlankAvatarGrey} />;
    }

    return (
        <Tooltip
            hasArrow
            label={`${firstName} ${lastName} ${
                participant ? "" : "has not joined video"
            }`}
        >
            <AspectRatio
                w={`${width}px`}
                minH="75px"
                bg={bg}
                border={border}
                zIndex={1}
                ratio={3.5 / 3}
                marginY={"4px"}
                borderRadius={"8px"}
                overflow={"hidden"}
            >
                <Box>
                    {fallback}
                    {publications.map((publication) => {
                        return (
                            <Publication
                                key={publication.trackSid}
                                publication={publication}
                                local={local}
                                setAudioOn={setAudioOn}
                            ></Publication>
                        );
                    })}
                    <Flex
                        borderRadius="4px"
                        bg="rgba(0,0,0,.5)"
                        paddingX="5px"
                        color="white"
                        fontSize="sm"
                        left="4px"
                        bottom="4px"
                        position="absolute"
                        zIndex={10}
                    >
                        {participant && !audioOn ? (
                            <Image src={MicOff} />
                        ) : null}
                        <Text marginLeft="5px">{firstName}</Text>
                    </Flex>
                    {participant && networkQuality <= 1 ? (
                        <Image
                            right="4px"
                            bottom="4px"
                            position="absolute"
                            zIndex={10}
                            src={BadInternet}
                            borderRadius="10px"
                            backgroundColor="rgba(0,0,0,.5)"
                            padding="2px"
                        />
                    ) : null}
                </Box>
            </AspectRatio>
        </Tooltip>
    );
};
