import { Flex, HStack, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { useTRPC } from "../../hooks/useTRPC";

import { DateTime } from "luxon";
import { extractImmersionTitle } from "../utils";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

const RecentSessions = () => {
    const trpc = useTRPC();
    const { data: recentSessions, isLoading } =
        trpc.sessions.completedSessions.useQuery({
            take: 3,
        });

    if (isLoading) {
        return (
            <VStack width="full" alignItems="start">
                <Text fontSize="md" fontWeight="semibold">
                    Past Sessions
                </Text>
                <Flex
                    bg="gray.50"
                    width="full"
                    height="24"
                    borderRadius="12px"
                />
            </VStack>
        );
    } else if (!recentSessions || recentSessions?.length === 0) return null;

    return (
        <Flex direction="column" width="full" gap="4">
            <HStack justifyContent="space-between" width="full" mb="2">
                <Text fontSize="md" fontWeight="semibold">
                    Past Sessions
                </Text>
                <Link to="/reviews">
                    <HStack
                        color="gray.600"
                        padding="8px 12px"
                        borderRadius="8px"
                        _hover={{ color: "gray.900", bg: "gray.background" }}
                    >
                        <Text fontSize="sm">View all</Text>
                        <ArrowRightIcon width="12" display="inline" />
                    </HStack>
                </Link>
            </HStack>

            <SimpleGrid columns={[1, null, 2, null, 3]} spacing="16px">
                {recentSessions?.map((session: any) =>
                    !session.time ? null : (
                        <VStack
                            key={session.id}
                            p="20px 20px 16px 20px"
                            width="full"
                            height="144px"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            borderRadius="12px"
                            borderColor="gray.100"
                            borderWidth="thin"
                            shadow="sm"
                            _hover={{
                                shadow: "lg",
                            }}
                        >
                            <Text fontSize="14px" color="dark.400" mr="2">
                                <strong>Completed</strong>
                                &nbsp;&nbsp;
                                {DateTime.fromISO(
                                    session.time || new Date().toISOString(),
                                ).toLocaleString(DateTime.DATE_SHORT)}
                            </Text>
                            <Text
                                fontSize="14px"
                                fontWeight="semibold"
                                minHeight="36px"
                            >
                                {extractImmersionTitle(session.immersion)}
                            </Text>
                            <HStack
                                justifyContent="flex-end"
                                color="blue.500"
                                fontSize="15px"
                                fontWeight="semibold"
                                width="full"
                            >
                                <Link to={`/reviews/${session.id}`}>
                                    Read Recap
                                </Link>
                            </HStack>
                        </VStack>
                    ),
                )}
            </SimpleGrid>
        </Flex>
    );
};

export default RecentSessions;
