import { useCallback, useState } from "react";

export const useTimedValue = <T,>(startValue: T, time: number) => {
    const [value, setValue] = useState<T>(startValue);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const start = (setValueTo: T) => {
        console.debug(`[Timer] Started timer with time ${time}`);
        setTimer(
            setTimeout(() => {
                console.debug(`[Timer] Timed out at ${time}`);
                setValue(setValueTo);
            }, time),
        );
    };
    const cancel = useCallback(() => {
        if (timer) {
            console.debug(`[Timer] Cancelled timer`);
            clearTimeout(timer);
            setTimer(null);
        }
    }, [timer]);
    return {
        start,
        cancel,
        value,
    };
};
