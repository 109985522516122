import { useAuth0 } from "@auth0/auth0-react";
import {
    Avatar,
    Box,
    Button,
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
} from "@chakra-ui/react";
import {
    ClipboardDocumentCheckIcon,
    HomeIcon,
} from "@heroicons/react/24/outline";
import RightArrow from "design/assets/RightArrow.svg";
import Sliders from "design/assets/Sliders.svg";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "../hooks/useAccount";
import { Loading } from "../Loading";
import { Logo } from "../Logo";

export enum Tabs {
    None,
    HomeTab,
    ReviewsTab,
}
export const Header = React.memo(({ page }: { page: Tabs }) => {
    const account = useAccount();
    const navigate = useNavigate();
    const { logout } = useAuth0();
    const isAdmin =
        account.data?.isClientAdmin || account.data?.isSparkwiseAdmin;

    if (account.isLoading) return <Loading></Loading>;
    if (account.error || !account.data) return <div>Error...</div>;

    return (
        <Flex
            justifyContent="space-between"
            w="100%"
            p="5"
            maxW="6xl"
            margin="auto"
        >
            <Logo></Logo>
            <Box
                gap="30px"
                position={"absolute"}
                left={"50%"}
                transform={"translateX(-50%)"}
            >
                <Button
                    h="37px"
                    isActive={page === Tabs.HomeTab}
                    size="sm"
                    bg="background"
                    fontWeight="normal"
                    _hover={{
                        bg: "gray.50",
                    }}
                    _active={{ bg: "gray.50", fontWeight: "semibold" }}
                    onClick={() => {
                        navigate("/dashboard");
                    }}
                >
                    <HomeIcon height="16px" />
                    <Text ml="2">Home</Text>
                </Button>
                <Button
                    h="37px"
                    isActive={page === Tabs.ReviewsTab}
                    size="sm"
                    bg="background"
                    fontWeight="normal"
                    _hover={{
                        bg: "gray.50",
                    }}
                    _active={{ bg: "gray.50", fontWeight: "semibold" }}
                    onClick={() => {
                        navigate("/reviews");
                    }}
                >
                    <ClipboardDocumentCheckIcon height="16px" />
                    <Text ml="2">Past Sessions</Text>
                </Button>
            </Box>
            <Flex>
                {isAdmin && (
                    <Button
                        variant={"outline"}
                        color={"gray.500"}
                        onClick={() =>
                            window.open(process.env.REACT_APP_ADMIN_URL)
                        }
                        borderColor={"gray.100"}
                        mr={4}
                    >
                        <Image src={Sliders} alt={"Admin Console access"} />
                        <Text
                            fontSize={"sm"}
                            fontFamily="Inter"
                            marginLeft="10px"
                        >
                            Admin Console
                        </Text>
                    </Button>
                )}
                <Menu placement="bottom-end" autoSelect={false}>
                    <MenuButton
                        borderColor="white"
                        borderWidth="4px"
                        borderRadius="24px"
                        _hover={{
                            borderColor: "gray.background",
                            borderWidth: "4px",
                            _after: {
                                content: "''",
                                position: "absolute",
                                width: "24px",
                                height: "24px",
                                borderRadius: "24px",
                                backgroundColor: "black",
                                opacity: 0.2,
                            },
                        }}
                        _active={{
                            borderColor: "gray.50",
                        }}
                    >
                        <Avatar
                            name={`${account.data.firstName} ${account.data.lastName}`}
                            src={account.data.avatarUrl || undefined}
                            size="xs"
                            showBorder={true}
                            borderColor="gray.800"
                            borderWidth="1px"
                        />
                    </MenuButton>
                    <MenuList
                        p="0px"
                        overflow="clip"
                        borderColor="gray.100"
                        borderRadius="8px"
                    >
                        <Flex direction="column" p="20px" gap="4px">
                            <Text
                                fontWeight="semibold"
                                fontSize="16px"
                                color="dark.800"
                            >
                                {account.data.firstName} {account.data.lastName}
                            </Text>
                            <Text fontSize="12px" color="dark.300">
                                {account.data.emails?.[0]?.email}
                            </Text>
                        </Flex>
                        {isAdmin && <MenuDivider m="0px" />}
                        {isAdmin && (
                            <MenuItem
                                p="12px 20px"
                                onClick={() =>
                                    window.open(process.env.REACT_APP_ADMIN_URL)
                                }
                            >
                                <Image
                                    src={Sliders}
                                    alt={"Admin Console access"}
                                />
                                <Text
                                    fontFamily="Inter"
                                    fontSize="15px"
                                    color="dark.700"
                                    marginLeft="10px"
                                >
                                    Admin Console
                                </Text>
                            </MenuItem>
                        )}

                        <MenuDivider m="0px" />
                        <MenuItem
                            p="12px 20px"
                            onClick={() =>
                                logout({
                                    logoutParams: {
                                        returnTo: window.location.origin,
                                    },
                                })
                            }
                        >
                            <Image src={RightArrow} alt={"Log out"} />
                            <Text
                                marginLeft="10px"
                                fontFamily="Inter"
                                fontSize="15px"
                                color="dark.700"
                            >
                                Sign out
                            </Text>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        </Flex>
    );
});
