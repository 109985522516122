import { atom } from "recoil";

export const chatwootVisible = atom<boolean>({
    key: "chatwootVisible",
    default: true,
});

export const maintenanceWarningDismissed = atom<boolean>({
    key: "maintenanceWarningDismissed",
    default: false,
});
