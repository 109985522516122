import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { VideoTileState } from 'amazon-chime-sdk-js';
import { meetingSession } from '../../immersionState';

const usePublications = (participantId: string) => {
    const meeting = useRecoilValue(meetingSession);
    const [publications, setPublications] = useState<VideoTileState[]>([]);
    const audioVideo = meeting?.audioVideo;

    useEffect(() => {
        if (!audioVideo) {
            return;
        }

        const videoTileDidUpdate = (tileState: VideoTileState) => {
            if (tileState.boundExternalUserId === participantId) {
                setPublications(prev => {
                    const existingTile = prev.find(tile => tile.tileId === tileState.tileId);
                    if (existingTile && existingTile !== tileState) {
                        return prev.map(tile => tile.tileId === tileState.tileId ? tileState : tile);
                    } else if (!existingTile) {
                        return [...prev, tileState];
                    }
                    return prev;
                });
            }
        };

        audioVideo.addObserver({
            videoTileDidUpdate,
        });

        return () => {
            audioVideo.removeObserver({
                videoTileDidUpdate,
            });
        };
    }, [audioVideo, participantId]);

    return publications;
};

export default usePublications;
