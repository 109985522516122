import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    chimeMicTooltip,
    micEnabled as micEnabledAtom,
    micTooltip,
    streamSource,
    videoRoom,
} from "../../immersionState";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Button, Flex, HStack, Icon, Text } from "@chakra-ui/react";
import ReactPlayer from "react-player";
import ReactMarkdown from "react-markdown";
import { IMMERSION_SIZES } from "../../Immersion";
import { useLocalAudio } from "../../video_chime/hooks/useLocalAudio";
import { useIsChimeEnabled } from "../../hooks/useImmersion";

interface Props {
    systemId: string;
    captions: string;
    optionalHeaderText?: string;
    fullscreen?: boolean;
}

export const VideoResource = (props: Props) => {
    const { systemId, captions, optionalHeaderText, fullscreen } = props;
    const [showingTranscript, setShowingTranscript] = useState(false);
    const [streamSrc] = useRecoilState(streamSource);
    const room = useRecoilValue(videoRoom);
    const [micEnabled, setMicEnabled] = useRecoilState(micEnabledAtom);
    const [micMutedByApp, setMicMutedByApp] = useState(false);
    const setMicTooltipText = useSetRecoilState(micTooltip);
    const setMicTooltipTextChime = useSetRecoilState(chimeMicTooltip);
    const { isAudioEnabled, setMute: muteChime } = useLocalAudio();
    const isChimeEnabled = useIsChimeEnabled();

    const audioEnabled = isChimeEnabled ? isAudioEnabled : micEnabled;

    const {
        STEP_MARGIN_TOP,
        STEP_MARGIN_BOTTOM,
        HEADER_HEIGHT,
        FOOTER_HEIGHT,
    } = IMMERSION_SIZES;
    const maxH = `calc(100vh - ${STEP_MARGIN_TOP} - ${STEP_MARGIN_BOTTOM} - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`;
    const outsideMargin = fullscreen ? "0px 72px" : "0px";

    const url =
        streamSrc === "sparkwise"
            ? `${process.env.REACT_APP_API_ROOT}/app/public/stream/${systemId}/manifest/video.mpd`
            : `https://customer-xos73p96y4i4wugs.cloudflarestream.com/${systemId}/manifest/video.mpd`;
    const caption_url =
        streamSrc === "sparkwise"
            ? `${process.env.REACT_APP_API_ROOT}/app/public/stream/${systemId}/captions/en`
            : `https://customer-xos73p96y4i4wugs.cloudflarestream.com/${systemId}/captions/en`;

    const videoContainer = useRef<HTMLDivElement>(null);
    const transcriptContainer = useRef<HTMLDivElement>(null);
    const headerTextContainer = useRef<HTMLDivElement>(null);

    const toggleMicrophone = (enable: boolean) => {
        room?.localParticipant.audioTracks.forEach(
            (localAudioTrackPublication) => {
                if (enable) {
                    localAudioTrackPublication.track.enable();
                } else {
                    localAudioTrackPublication.track.disable();
                }
            },
        );
        muteChime(enable);
    };

    const handleMicTooltip = (message: string) => {
        setMicTooltipText(message);
        setMicTooltipTextChime(message);
        setTimeout(() => {
            setMicTooltipText(null);
            setMicTooltipTextChime(null);
        }, 3000);
    };

    // muteState true means it should run auto-mute logic
    // muteState false means it should run auto-unmute logic
    const automute = (muteState: boolean) => {
        if ((muteState && audioEnabled) || (!muteState && micMutedByApp)) {
            const message = muteState
                ? "Your microphone was muted during video playback."
                : "Your microphone is no longer muted.";
            handleMicTooltip(message);
            setMicMutedByApp(muteState);
            toggleMicrophone(!muteState);
            setMicEnabled(!muteState);
        }
    };

    // Run unmute logic when moving to next step
    useEffect(() => {
        return () => automute(false);
    }, [audioEnabled, micMutedByApp]);

    // Run unmute logic when switching video streams
    useEffect(() => {
        automute(false);
    }, [streamSrc]);

    // On screen resize, size the video container based on the container size
    // while keeping the video aspect ratio maintained at 16/9
    // and not exceeding the container size in a way that leaves a scroll bar

    useEffect(() => {
        const resizeVideoContainer = () => {
            const videoContainerElement = videoContainer.current;
            if (!videoContainerElement) return;

            const videoContainerWidth =
                videoContainerElement.parentElement?.clientWidth || 0;
            const videoContainerHeight =
                videoContainerElement.parentElement?.clientHeight || 0;
            const videoContainerAspectRatio =
                videoContainerWidth / videoContainerHeight;
            const videoAspectRatio = 16 / 9;
            videoContainerElement.style.height = "100%";
            videoContainerElement.style.width = "100%";

            if (videoContainerAspectRatio > videoAspectRatio) {
                // Manually set the width to keep the aspect ratio correct
                const computedWidth = Math.min(
                    videoContainerElement.clientHeight * videoAspectRatio,
                    videoContainerElement.parentElement?.clientWidth || 0,
                );
                videoContainerElement.style.width = `${computedWidth}px`;
                const computedHeight = computedWidth / videoAspectRatio;
                videoContainerElement.style.height = `${computedHeight}px`;
            } else {
                // Manually set the height to keep the aspect ratio correct
                const computedHeight = Math.min(
                    videoContainerElement.clientWidth / videoAspectRatio,
                    (videoContainerElement.parentElement?.clientHeight || 0) -
                        (transcriptContainer.current?.clientHeight || 0) -
                        (headerTextContainer.current?.clientHeight || 0) -
                        8,
                );
                videoContainerElement.style.height = `${computedHeight}px`;
                const computedWidth = computedHeight * videoAspectRatio;
                videoContainerElement.style.width = `${computedWidth}px`;
            }
        };
        resizeVideoContainer();
        window.addEventListener("resize", resizeVideoContainer);
        return () => {
            window.removeEventListener("resize", resizeVideoContainer);
        };
    }, [videoContainer, transcriptContainer, showingTranscript, headerTextContainer]);

    return (
        <Flex
            flex="1"
            direction="column"
            maxH={maxH}
            m={outsideMargin}
        >
            {optionalHeaderText && (
                <Text
                    ref={headerTextContainer}
                    w="100%"
                    mb="32px"
                    textAlign="center"
                    fontSize="24px"
                    fontWeight="bold"
                    color="dark.800"
                    lineHeight="30px"
                    fontFamily="Proxima Nova"
                >
                    {optionalHeaderText}
                </Text>
            )}
            <Flex
                flex="1"
                direction="column"
                boxSizing="content-box"
                maxW="100%"
                overflow="auto"
            >
                <Flex
                    alignSelf="center"
                    justifySelf="center"
                    borderRadius={20}
                    minW="300px"
                    minH="169px"
                    overflow="hidden"
                    position="relative"
                    ref={videoContainer}
                >
                    {/* 
                    ReactPlayer renders a container div with a video element inside
                    The "width" and "height" props are used on the video element)
                    The "style" prop is used on the container div
                */}
                    <ReactPlayer
                        url={url}
                        controls={true}
                        width="100%"
                        height="100%"
                        // the styles below make the player respect the size limitations
                        // of the Flex parent container and not flow outside the container
                        // to maintain the aspect ratio
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: "0",
                            left: "0",
                        }}
                        config={{
                            file: {
                                attributes: {
                                    disablePictureInPicture: true,
                                    crossOrigin: "anonymous",
                                },
                                tracks: [
                                    {
                                        kind: "subtitles",
                                        label: "English",
                                        src: caption_url,
                                        srcLang: "en",
                                        default: false,
                                    },
                                ],
                            },
                        }}
                        onPlay={() => automute(true)}
                        onPause={() => automute(false)}
                        onEnded={() => automute(false)}
                    />
                </Flex>

                <Flex
                    ref={transcriptContainer}
                    direction="column"
                    mt="8px"
                    borderRadius="12px"
                    bgColor={showingTranscript ? "gray.background" : "none"}
                >
                    <Flex flex="1" justifyContent="flex-end">
                        <Button
                            size="md"
                            m={showingTranscript ? "8px" : "0px"}
                            bgColor="gray.background"
                            color="blue.500"
                            borderRadius="12px"
                            onClick={() =>
                                setShowingTranscript(!showingTranscript)
                            }
                        >
                            <HStack>
                                <Icon
                                    as={
                                        showingTranscript
                                            ? EyeSlashIcon
                                            : EyeIcon
                                    }
                                />
                                <Text fontFamily="Proxima Nova">
                                    {showingTranscript
                                        ? "Hide Transcript"
                                        : "Show Transcript"}
                                </Text>
                            </HStack>
                        </Button>
                    </Flex>
                    {showingTranscript && (
                        <Flex
                            minH={12}
                            maxH={64}
                            pb="16px"
                            justifyContent="center"
                        >
                            <Flex
                                overflowY="auto"
                                p="0px 16px"
                                minW="300px"
                                maxW="700px"
                            >
                                <ReactMarkdown className="exclude-from-reset richtext">
                                    {captions}
                                </ReactMarkdown>
                            </Flex>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
