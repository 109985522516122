import { Flex } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { IMMERSION_SIZES } from "../../Immersion";

interface Props {
    altText: string;
    fullscreen?: boolean;
}

export const RichtextResource = (props: Props) => {
    const { altText, fullscreen } = props;
    const {
        STEP_MARGIN_TOP,
        STEP_MARGIN_BOTTOM,
        HEADER_HEIGHT,
        FOOTER_HEIGHT,
    } = IMMERSION_SIZES;
    const maxH = `calc(100vh - ${STEP_MARGIN_TOP} - ${STEP_MARGIN_BOTTOM} - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})`;

    return (
        <Flex flex="1" direction="column" align="center">
            {fullscreen && <Flex flex="1 1 30%" />}
            <Flex
                h="fit-content"
                {...(fullscreen ? { maxW: "720px" } : { w: "100%" })}
            >
                <Flex
                    flex="1"
                    maxH={maxH}
                    {...(fullscreen
                        ? {
                              p: "32px 20px",
                              borderWidth: "1px",
                              borderStyle: "solid",
                              borderColor: "gray.100",
                              borderRadius: "12px",
                              bgColor: "gray.background",
                          }
                        : {
                              p: "0px 0px",
                          })}
                >
                    <Flex overflowY="auto" p="0px 20px">
                        <ReactMarkdown
                            className="exclude-from-reset richtext"
                            remarkPlugins={[remarkGfm]}
                        >
                            {altText}
                        </ReactMarkdown>
                    </Flex>
                </Flex>
            </Flex>
            {fullscreen && <Flex flex="1 1 70%" />}
        </Flex>
    );
};
