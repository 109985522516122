import { keyframes } from "@chakra-ui/react";

export const broadcasting = keyframes`
    0% { transform: scale(0); opacity: 1; }
    50% { transform: scale(0.5); opacity: 1; }
    75% { transform: scale(0.7); opacity: 0.5; }
    100% { transform: scale(1); opacity: 0; }
`;
export const scaleFadeCircle = keyframes`
    0% { opacity: 0.1; }
    50% { transform: scale(1.5); opacity: 0.3; }
    75% { transform: scale(2); opacity: 0.2; }
    100% { transform: scale(3); opacity: 0; }
`;
export const scaleFadeOutline = keyframes`
    0% { transform: scale(0.5); opacity: 0.3; }
    50% { transform: scale(1); opacity: 1; }
    100% { transform: scale(1.5); opacity: 0; }
`;
export const scaleFadeSparkle = keyframes`
    0% { transform: scale(0.5); opacity: 0.3; }
    50% { transform: scale(1); opacity: 1; }
    100% { transform: scale(1.5); opacity: 0; }
`;
export const scaleFadeIn = keyframes`
    0% { transform: scale(0.5); opacity: 0.5; }
    50% { transform: scale(1); opacity: 1; }
    75% { transform: scale(1.05); opacity: 1; }
    100% { transform: scale(1); opacity: 1; }
`;
